// src/pages/Files.js
import React, { useEffect, useState } from "react";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem, useDisclosure } from "@nextui-org/react";
import TableList from "../components/files_layout/TableList";
import LayoutSwitcher from "../components/switchers/LayoutSwitcher";
import GridList from "../components/files_layout/GridList";
import NavBar from "src/components/header/NarBar";
import { useSidebarStore } from "src/store/layout/useSidebarStore";
import DownloadList from "src/components/common/DownloadList";
import useFolderStore from "src/store/api/useFolderStore";
import { useNavigate } from "react-router-dom";
import { useFolderContentStore } from "src/components/main_page/cards/FolderCard";
import useListFolderContents from "src/hooks/useListFolderContents";
import { useFolderContentStore as useFolderStoreId } from 'src/components/main_page/cards/FolderCard';
import { useSidebarStore as useMainSidebarStore } from "src/components/sidebar/MainSidebar";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import { Alert } from "antd";
import CustomAlert from "src/components/common/CustomAlert";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import useListAllTrash from "src/hooks/useListAllTrash";
import EmptyTrashModal from "src/components/modals/EmptyTrashModal";
import useTrashFilesStore from "src/store/layout/useTrashFilesGridListStoreLayout";

const Files = () => {
  const { t } = useTranslation();
  const { layout } = useSidebarStore();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { selectedFolderStack, popFolderByIndex, emptyFolderStack } = useFolderStore();
  const navigate = useNavigate();
  const { setFolderId, clearFolderId } = useFolderContentStore(); // Zustand store to manage selected folder id
  const { setTheSelectedFolderId, clearTheSelectedFolderId } = useListFolderContents();
  const activeTab = useMainSidebarStore.getState().activeTab; // Fetching latest folderId
  const { userResources, showStorageAlert } = useUserResources();
  const [errorExceelStorageMessage, setErrorExceelStorageMessage] = useState<string>("");
  const { uploadResponseStatus } = useUploadFilesHooks(); // Use the custom hook
  const { isOpen: isEmptyTrashOpen, onOpen: onOpenEmptyTrashOpen, onClose: onCloseEmptyTrashOpen } = useDisclosure();
   const { listAllTrashedItems, totalItems } = useListAllTrash();
   const { rowsPerPage, page} = useTrashFilesStore();

  // Retrieve the last folder name if the stack is not empty
  const folderName =
    selectedFolderStack && selectedFolderStack.length > 0
      ? selectedFolderStack[selectedFolderStack.length - 1].folder_name
      : "";

  const handleBreadcrumbClick = (folder_id: string, index: number) => {
    setFolderId(folder_id); // Set the folderId state
    setTheSelectedFolderId(folder_id);
    popFolderByIndex(index);
    navigate("/fileDetails");
  };

  const handleMainBreadcrumbClick = (link: string) => {
    clearFolderId(); // Set the folderId state
    clearTheSelectedFolderId();
    emptyFolderStack();
    navigate(link);
  };

  useEffect(() => {

    if (userResources?.can_upload ?? false)
      setErrorExceelStorageMessage(t('UsedStorage90Persent'));
    else
      setErrorExceelStorageMessage(t('ExceedAllowedStorage'));


    const folderId = useFolderStoreId.getState().folderId;
    const subfolderId = useFolderStore.getState().selectedFolderId;

    if (folderId === null && subfolderId === null) {
      navigate("/homepage");
    }
      listAllTrashedItems(page, rowsPerPage);

  }, [navigate, userResources, listAllTrashedItems]);

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 p-4 overflow-x-hidden">

          {/* <Breadcrumbs >
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("HomePage")}</BreadcrumbItem>
            {selectedFolderStack?.map((folder, index) => (
              <BreadcrumbItem key={index}>{folder.folder_name}</BreadcrumbItem>
            ))}
          </Breadcrumbs> */}

          <Breadcrumbs>
            <BreadcrumbItem>
              <button
                onClick={() =>
                  handleMainBreadcrumbClick(
                    activeTab === "Favorite" ? "/Favorite" : "/homepage"
                  )
                }
              >
                {t(activeTab === "Favorite" ? "Favorite" : "HomePage")}
              </button>
            </BreadcrumbItem>
            {selectedFolderStack?.map((folder, index) => (
              <BreadcrumbItem key={index}>
                <button onClick={() => handleBreadcrumbClick(folder.folder_id, index)}>
                  {folder.folder_name}
                </button>
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5 flex justify-between">
            <div className="font-bold capitalize hover:text-navy-700">
              {folderName || t("Files")}
            </div>
            <LayoutSwitcher />
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          {/* Alert Message */}
          {showStorageAlert && errorExceelStorageMessage && (
            <Alert
              className="bg-[#4b65bc2a] border-[#4b65bc2a] my-5 text-red-600 font-bold capitalize-first-letter"
              message={
                <span>
                  {errorExceelStorageMessage}{" "}
                  {totalItems > 0 && (
                    <a
                      href="#"
                      onClick={() => {
                        // Logic to open an empty modal or perform other actions
                        onOpenEmptyTrashOpen();
                      }}
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {t("EmptyTrash")}
                    </a>
                  )}
                </span>
              }
            />
          )}

          {uploadResponseStatus && (uploadResponseStatus === "error" || uploadResponseStatus === "success") && (
            // {uploadResponseStatus && uploadResponseStatus === "success" && (
            <CustomAlert />
          )}

          {layout === "grid" ? <GridList /> : <div className="">
            <TableList />
          </div>}

          <EmptyTrashModal
            isOpen={isEmptyTrashOpen}
            onClose={onCloseEmptyTrashOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Files;
