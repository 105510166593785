import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';
import { Item } from "src/types/api/ListFolderFiles";
import { sharedItem } from "src/types/api/Share";
import { useEditFile } from "src/hooks/useEditFile";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";
import useRefreshListAllSharedWithMe from "src/hooks/useRefreshListAllSharedWithMe";
import useRefreshListSharedByFolderContents from "src/hooks/useRefreshListSharedByFolderAllContents";
import useRefreshListSharedWithFolderContents from "src/hooks/useRefreshListSharedWithFolderAllContents";

interface EditFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  fileDetails: Item | sharedItem;
  type: string;

}

const EditFileModal: React.FC<EditFileModalProps> = ({
  isOpen,
  onClose,
  fileId,
  fileDetails,
  type
}) => {
  const { t } = useTranslation();
  const { updateFile, isUpdating, updateResponseMessage, updateResponseStatus } = useEditFile();
  const [fileName, setFileName] = useState(fileDetails.original_filename);
  const [fileDescription, setFileDescription] = useState(fileDetails.description);
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refeshListAllFoldersFiles, currentPage, totalItems: homeTotalItems } = useRefreshListAllFolders();
  const { refreshListSharedByFolderContents, folderCurrentPage: sharedByFolderCurrentPage } = useRefreshListSharedByFolderContents();
  const { refreshListAllShareWithUser, shareWithCurrentPage, totalSharedWithItems } = useRefreshListAllSharedWithMe();
  const { refreshListSharedWithFolderContents, folderCurrentPage: sharedWithFolderCurrentPage } = useRefreshListSharedWithFolderContents();

  const handleSave = async () => {

    await updateFile(fileId, { directory_id: fileDetails.directory_id, folder_id: fileDetails.parent_folder_id, original_filename: fileName, description: fileDescription });
    if (updateResponseStatus && updateResponseStatus === "success") {
      if (type !== 'sharedWith'){
        await refeshListAllFoldersFiles(currentPage, homeTotalItems);
        await refreshListFolderContents(folderCurrentPage);
        await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
        await refreshListSharedByFolderContents(sharedByFolderCurrentPage);
      }
      else
      {
        await refreshListAllShareWithUser(shareWithCurrentPage, totalSharedWithItems);
        await refreshListSharedWithFolderContents(sharedWithFolderCurrentPage);
      }

    }
    onClose(); // Close the modal on success
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("EditFile")}
        </ModalHeader>
        <ModalBody>

          {updateResponseStatus && updateResponseStatus === "error" && (
            <Alert message={updateResponseMessage} type="error" showIcon closable />
          )}

          <form className="flex flex-col gap-4">
            <Input
              type="text"
              label={t("Name")}
              labelPlacement="outside"
              value={fileName || ""} // Bind to state
              onChange={(e) => setFileName(e.target.value)} // Update state
            />

            <Textarea
              label={t("Description")}
              labelPlacement="outside"
              placeholder={t("EnterYourDescription")}
              value={fileDescription || ""} // Bind to state
              maxLength={200} // Limiting the text area to 200 characters
              onChange={(e) => setFileDescription(e.target.value)} // Update state
              className="my-4 w-full"
            />

          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave} isLoading={isUpdating}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditFileModal;
