import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from '@nextui-org/react';
// Icons
import { VerticalDotsIcon } from '../../../assets/icons/VerticalDotsIcon';
import { useTranslation } from 'react-i18next';

// File Type Icons
import AiIcon from "../../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../../assets/icons/Files Types/ZipIcon";
import { sharedItem } from 'src/types/api/Share';
import PublicFileDetailsModal from 'src/components/modals/PublicFileDetailsModal';
import { Item } from 'src/types/api/ListFolderFiles';

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={75} width={70} />,
  docx: <DocIcon height={100} width={100} />,
  png: <PngIcon height={20} width={20} />,
  pdf: <PdfIcon height={35} width={100} />,
  txt: <TxtIcon height={75} width={70} />,
  psd: <PsdIcon height={75} width={70} />,
  jpg: <JpgIcon height={20} width={20} />,
  jpeg: <JpegIcon height={20} width={20} />,
  psb: <PsbIcon height={75} width={70} />,
  svg: <SvgIcon height={90} width={110} />,
  mp4: <Mp4Icon height={100} width={100} />,
  avi: <AviIcon height={75} width={70} />,
  mov: <MovIcon height={75} width={70} />,
  webm: <WebmIcon height={75} width={70} />,
  xlsx: <XlsxIcon height={90} width={110} />,
  csv: <CsvIcon height={75} width={70} />,
  pptx: <PptIcon height={75} width={70} />,
  indd: <InddIcon height={75} width={70} />,
  zip: <ZipIcon height={75} width={70} />,
  rar: <RarIcon height={75} width={70} />,
  arj: <ArjIcon height={75} width={70} />,
};

interface FileCardProps {
  file: Item;
}

const PublicFileCard2: React.FC<FileCardProps> = ({ file }) => {
  const { t } = useTranslation();

  const fileIcon = typesIconMap[file.file_type?.toLocaleLowerCase() || ''];
 
  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();
   

  const handleView = () => onOpenFileDetails();
  const folderOwnerEn = file.owner ? file.owner.split(',')[0].trim() : '';
  const folderOwnerAr =file.owner ? file.owner.split(',')[1].trim() : '';
 
  const handleDownload = (s3_redirect_url: null | string) => {
    if (!s3_redirect_url) {
      console.error('No download URL available');
      return;
    }
    window.open(s3_redirect_url, '_blank');
  };
  const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);

    // Check for invalid date
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string provided');
    }

    // Get the year, month, and day
    const year: number = date.getFullYear();
    const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day: string = String(date.getDate()).padStart(2, '0');

    // Get the hours and minutes
    let hours: number = date.getHours();
    const minutes: string = String(date.getMinutes()).padStart(2, '0');

    // Determine AM/PM and format hours
    const ampm: string = hours >= 12 ? 'P.M' : 'A.M';
    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // Adjust for midnight

    return `${year}-${month}-${day} ${String(hours).padStart(2, '0')}:${minutes} ${t(ampm)}`;
  };

  return (
    <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={file.file_id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t('ViewDetails')}</DropdownItem>
            <DropdownItem onClick={() => handleDownload(file.s3_redirect_url || '')}>{t('View')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small text-center text-default-400">
        <div className="flex justify-center items-center">
          <div
            className="w-[200px] h-[120px] bg-cover bg-center rounded-xl"
            style={{
              backgroundImage: `url('${file.thumbnail_s3_url}')`,
              backgroundRepeat: "no-repeat",
              cursor: "pointer" // makes it clear the element is clickable
            }}
            onClick={() => handleDownload(file.s3_redirect_url || "")} // Trigger download on click
          ></div>
        </div>

        <span className="pt-2 capitalize text-gray-900 text-xs font-bold flex justify-center items-center">
          {fileIcon}
          {file.original_filename && file.original_filename.length >= 60
            ? `${file.original_filename.slice(0, 60)}.....`
            : file.original_filename || ""}
        </span>

        <span className="pt-2 capitalize">{file.created_at && formatDateTime(file.created_at)}</span>
        <span className="pt-2 capitalize">
          {/* {file.description && file.description.length >= 60
            ? `${file.description.slice(0, 60)}.....`
            : file.description || ""} */}
        </span>
      </CardBody>
      {/* <CardFooter className="gap-3 w-full">
          <div className='flex justify-between w-full text-gray-400'>
            <div className="pt-2 capitalize">
              <div className='flex justify-center items-start'>
              </div>
            </div>
            <div className="pt-2 capitalize">
              <div className='flex justify-center items-start'>
              </div>
            </div>
          </div>
        </CardFooter> */}
      <PublicFileDetailsModal isOpen={isFileDetailsOpen} onClose={onCloseFileDetails} fileType={file.file_type || ''} fileName={file.original_filename || ''} fileOwner={folderOwnerEn ?? folderOwnerAr} fileSize={file.file_size || 0} fileDescription={file.description || ''} />
    </Card>
  );
};

export default PublicFileCard2;
