import useFolderStore from 'src/store/api/useFolderStore';

export const useFolderHooks = () => {
  const {
    folderName,
    setFolderName,
    createNewFolder,
    uploadNewFolder,
    updateFolder,
    deleteFolder,
    listAllFolders,
    listAllFoldersFiles,
    copyFolder,
    // restoreFolder,
    // getFolderDetails,
    uploadFolder,
    downloadFolder,
    emptyTrashFolders,
    isCreating,
    isUpdating,
    isDeleting,
    isFetching,
    isCopying,
    isRestoring,
    isUploading,
    isDownloading,
    isEmptyingTrash,
    responseMessage,
    responseStatus,
    resetState,
    createdFolderId
  } = useFolderStore();

  const handleCreateFolder = async () => {
    await createNewFolder();
  };


  const handleCreateFolderWithFolderId = async () => {
    const folderId = await createNewFolder();
    if (folderId)
      return folderId;
  };

  const handleUploadNewFolder = async (parentFolderId: string | null) => {
    const folderId = await uploadNewFolder(parentFolderId);
    if (folderId)
      return folderId;
  };

  return {
    folderName,
    setFolderName,
    handleCreateFolder,
    handleCreateFolderWithFolderId,
    handleUploadNewFolder,
    handleUpdateFolder: updateFolder,
    handleDeleteFolder: deleteFolder,
    handleFetchFolders: listAllFolders,
    // handleFetchFolderDetails: getFolderDetails,
    // handleRestoreFolder: restoreFolder,
    handleEmptyTrashFolders: emptyTrashFolders,
    handleUploadFolder: uploadFolder,
    handleDownloadFolder: downloadFolder,
    handleListAllFoldersFiles: listAllFoldersFiles,
    handleCopyFolder: copyFolder,
    isCreating,
    isUpdating,
    isDeleting,
    isFetching,
    isCopying,
    isRestoring,
    isUploading,
    isDownloading,
    isEmptyingTrash,
    responseMessage,
    responseStatus,
    resetState,
    createdFolderId
  };
};
