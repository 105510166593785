import React, { useEffect, useState } from "react";
import NavBar from "../components/header/NarBar";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem, useDisclosure } from "@nextui-org/react";
import GridList from "../components/main_page/FilesGridList";
import { useFolderContentStore } from "src/components/main_page/cards/FolderCard";
import DownloadList from "src/components/common/DownloadList";
import useListFolderContents from "src/hooks/useListFolderContents";
import { Alert } from "antd";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import CustomAlert from "src/components/common/CustomAlert";
import EmptyTrashModal from "src/components/modals/EmptyTrashModal";
import useListAllTrash from "src/hooks/useListAllTrash";
import useTrashFilesStore from "src/store/layout/useTrashFilesGridListStoreLayout";

const Homepage: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const clearFolderId = useFolderContentStore((state) => state.clearFolderId);
  const folderId = useFolderContentStore.getState().folderId;
  const { setTheSelectedFolderId } = useListFolderContents();
  const { userResources, showStorageAlert } = useUserResources();
  const [errorExceelStorageMessage, setErrorExceelStorageMessage] = useState<string>("");
  const { uploadResponseStatus } = useUploadFilesHooks(); // Use the custom hook
  const { isOpen: isEmptyTrashOpen, onOpen: onOpenEmptyTrashOpen, onClose: onCloseEmptyTrashOpen } = useDisclosure();
   const { listAllTrashedItems, totalItems } = useListAllTrash();
  const { rowsPerPage,page} = useTrashFilesStore();

  useEffect(() => {
    // console.log("clearFolderId",folderId)
    // console.log("hissss",clearFolderId)
    // console.log("clearFolderId",folderId)
    clearFolderId(); // Clear the folder ID when the homepage is loaded
    setTheSelectedFolderId(null);

    if (userResources?.can_upload ?? false)
      setErrorExceelStorageMessage(t('UsedStorage90Persent'));
    else
      setErrorExceelStorageMessage(t('ExceedAllowedStorage'));

      listAllTrashedItems(page, rowsPerPage);

  }, [clearFolderId, setTheSelectedFolderId, userResources, listAllTrashedItems]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={handleSidebarToggle} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={handleSidebarToggle} />

        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("HomePage")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("HomePage")}
            </div>
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          {/* Alert Message */}
          {showStorageAlert && errorExceelStorageMessage && (
            <Alert
              className="bg-[#4b65bc2a] border-[#4b65bc2a] my-5 text-red-600 font-bold capitalize-first-letter"
              message={
                <span>
                  {errorExceelStorageMessage}{" "}
                  {totalItems > 0 && (
                    <a
                      href="#"
                      onClick={() => {
                        // Logic to open an empty modal or perform other actions
                        onOpenEmptyTrashOpen();
                      }}
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {t("EmptyTrash")}
                    </a>
                  )}
                </span>
              }
            />
          )}

          {uploadResponseStatus && (uploadResponseStatus === "error" || uploadResponseStatus === "success") && (
            // {uploadResponseStatus && uploadResponseStatus === "success" && (
            <CustomAlert />
          )}

          <GridList />

          <EmptyTrashModal
            isOpen={isEmptyTrashOpen}
            onClose={onCloseEmptyTrashOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
