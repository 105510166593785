import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// Components
import FileDetailsModal from "../modals/FileDetailsModal";
import CustomAvatar from "../../utils/partials/CustomFileType";

// Icons
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
 
// File Type Icons
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
 import { Pagination } from "antd";
import MainFileIcon from '../../assets/icons/MainFileIcon';
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
 import { useFolderContentStore } from "../main_page/cards/FolderCard";
import { Item } from "src/types/api/ListFolderFiles";
import { Link } from "react-router-dom";
import FolderDetailsModal from "../modals/FolderDetailsModal";
import useListPublicFolderContents from "src/hooks/useListPublicFolderContents";
import { usePublicFolderStore } from "../public_access_page/cards/PublicFolderCard";
import PublicFolderDetailsModal from "../modals/PublicFolderDetailsModal";
import PublicFileDetailsModal from "../modals/PublicFileDetailsModal";
import useFolderStore from "src/store/api/useFolderStore";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  rar: <RarIcon height={20} width={30} />,
  arj: <ArjIcon height={20} width={30} />,
  folder: <MainFileIcon height={23} width={30} />
};

const INITIAL_VISIBLE_COLUMNS = ["Name", "file_type", "actions"];

const PublicSharedTableList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
    selectedKeys,
    setSelectedKeys,
    setSortDescriptor,
  } = useFilesStore();


  const [selectedFolderName, setSelectedFolderName] = useState<string | null>(null);
  const [selectedFolderOwner, setSelectedFolderOwner] = useState<string | null>(null);
   const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
   const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
   const [selectedFileOwner, setSelectedFileOwner] = useState<string | null>(null);
   const [selectedSize, setSelectedFileSize] = useState<number | null>(null);
   const [selectedDescription, setSelectedFileDescription] = useState<string | null>(null);
   const {pushFolder} = useFolderStore();

 
  const {
    listPublicFolderContents,
    isFetchingPublicFolderContent: isLoading,
    publicFolderContents: folderContents,
    publicFolderCurrentPage: currentPage,
    publicFolderTotalPages: totalPage,
    publicFolderTotalItems: totalItems,
    publicFolderRowsPerPage: folderRowsPerPage,
  } = useListPublicFolderContents();
  
  const folderItems = folderContents || [];

  
  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();
   


  const handleViewDetails = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderName(item.name);
      const folderOwnerEn = item.owner ? item.owner.split(',')[0].trim() : '';
      const folderOwnerAr = item.owner ? item.owner.split(',')[1].trim() : '';
      setSelectedFolderOwner(folderOwnerEn ?? folderOwnerAr);
       onOpenFolderDetails();
    } else if (item.file_id) {
      const folderOwnerEn = item.owner ? item.owner.split(',')[0].trim() : '';
      const folderOwnerAr =item.owner ? item.owner.split(',')[1].trim() : '';
      setSelectedFileType(item.file_type);
      setSelectedFileName(item.original_filename);
      setSelectedFileOwner(folderOwnerEn ?? folderOwnerAr);
      setSelectedFileSize(item.file_size || 0);
      setSelectedFileDescription(item.description);
      onOpenFileDetails();
    }
  };

  const { setFolderId } = usePublicFolderStore(); // Zustand store to manage selected folder id
  const { setTheSelectedPublicFolderId } = useListPublicFolderContents();

  const handleRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1); // Reset to the first page when rows per page change
    },
    [setRowsPerPage, setPage]
  );
 
  useEffect(() => {
    setPage(1);
    listPublicFolderContents(1); // Refetch data for the first page
  }, [t, setPage, listPublicFolderContents]);


  const handleSearchChange = useCallback(
    (value?: string) => {
      setFilterValue(value || '');
    }, [setFilterValue]);


 
  const handleFolderClick = (folder: any) => {
    const id = folder.folder_id || '';
    setFolderId(id); // Set the folderId state
    setTheSelectedPublicFolderId(id);
    pushFolder(id, folder.name || "");
  };

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, "_blank");
    } else {
      // Handle cases where the file URL is not available
    }
  }, []);


  
  const renderTopContent = useMemo(
    () => (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper: "border-1",
            }}
            placeholder={t("SearchByFileName")}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={handleSearchChange}
          />

        </div>
        <div className="flex justify-between items-center ">
          <span className="text-default-400 text-small">
            {isLoading ? t('Loading') : `${t('TotalFiles')}: ${totalItems}`}
          </span>
        </div>
      </div>
    ),
    [
      t,
      filterValue,
      handleSearchChange,
      totalItems,
      rowsPerPage,
      handleRowsPerPageChange,
      setFilterValue,
    ]
  );

  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();


  const handlePageChange = useCallback((newPage: number) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
    listPublicFolderContents(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, listPublicFolderContents]);


  const renderBottomContent = useMemo(
    () => (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={currentPage}
          total={totalPage * folderRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={folderRowsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    ),
    [currentPage, totalPage, folderRowsPerPage, t, setPage, handlePageChange]
  );

  const renderCell = useCallback(
    (content: Item, columnKey: React.Key): React.ReactNode => {
      const cellValue = content[columnKey as keyof Item] || "N/A";

      switch (columnKey) {
        case "Name":
          const fileIcon = typesIconMap[content.file_type as keyof typeof typesIconMap] || typesIconMap[content.type as keyof typeof typesIconMap];
          return content.folder_id ? (
            <div className="flex items-start gap-2">
 
              {/* If the item is a folder, wrap with a Link component to navigate to folder details */}
              <Link to="/publicSharedFileList"  className="flex items-center gap-2" onClick={() => handleFolderClick(content || "")}>
                {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}
                <span className="text-lg">{content.original_filename || content.name}</span>
              </Link>
            </div>
          ) : (
            // If the item is a file, just display the name and other info without navigation
            <div className="flex items-start gap-2">
 
              {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}

              <span className="text-lg"
                style={{
                  cursor: "pointer" // makes it clear the element is clickable
                }}
                onClick={() => handleDownload(content.s3_redirect_url || "")} // Trigger download on click
              >
                {content.original_filename && content.original_filename.length >= 60
                  ? `${content.original_filename.slice(0, 60)}.....`
                  : content.original_filename || content.name}
              </span>
            </div>
          );

        case "file_type":
          return (
            <Chip className="capitalize border-none gap-1 text-default-600" size="sm" variant="flat">
              {content.file_type || content.type}
            </Chip>
          );

        case "actions":
          return content.folder_id ? (
            <div className="relative flex justify-end items-center gap-2">
              <Dropdown className="bg-background border-1 border-default-200">
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  {/* <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}>
                  {t('View')}
                </DropdownItem> */}
                  <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) :
            (
              <div className="relative flex justify-end items-center gap-2">
                <Dropdown className="bg-background border-1 border-default-200">
                  <DropdownTrigger>
                    <Button isIconOnly radius="full" size="sm" variant="light">
                      <VerticalDotsIcon className="text-default-400" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                    <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}> {t('View')}</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            );

        default:
          return String(cellValue);
      }
    },
    [
      t,
      handleViewDetails,
       handleDownload,
       handleFolderClick,
    ]
  );


  return (
    <div className="w-full bg-white p-4 rounded-lg shadow overflow-x-auto">

      <Table
        isCompact
        className="min-w-full"
        removeWrapper
        aria-label="Example table with custom cells, pagination and sorting"
        bottomContent={renderBottomContent}
        bottomContentPlacement="outside"
        checkboxesProps={{
          classNames: {
            wrapper: "after:bg-secondary after:text-background text-background",
          },
        }}
        selectedKeys={Array.from(
          selectedKeys
        ) as Iterable<import("@react-types/shared").Key>}
        selectionMode="none"
        onSelectionChange={(keys) =>
          setSelectedKeys(new Set(Array.from(keys, String)))
        }
        topContent={renderTopContent}
        topContentPlacement="outside"
      >
        <TableHeader className="bg-white" columns={INITIAL_VISIBLE_COLUMNS}>
          {INITIAL_VISIBLE_COLUMNS.map((column) => (
            <TableColumn
              key={column}
              align={column === "actions" ? "end" : "start"}
            >
              {t(column)}
            </TableColumn>
          ))}
        </TableHeader>

        <TableBody emptyContent={t("NoFilesFound")} items={folderItems}>
          {folderItems.map((item) => (
            <TableRow key={item.file_id || item.folder_id}>
              {INITIAL_VISIBLE_COLUMNS.map((columnKey) => (
                <TableCell key={columnKey}>
                  {renderCell(item, columnKey)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

      </Table>

      {selectedFileType && selectedFileName && selectedFileOwner && selectedSize &&  selectedDescription &&(
      <PublicFileDetailsModal isOpen={isFileDetailsOpen} onClose={onCloseFileDetails} fileType={selectedFileType} fileName={selectedFileName} fileOwner={selectedFileOwner} fileSize={selectedSize} fileDescription={selectedDescription} />
    )}


      {selectedFolderName && selectedFolderOwner && (
      <PublicFolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderName={selectedFolderName} folderOwner={selectedFolderOwner} />
       )}

    </div>
  );
};

export default PublicSharedTableList;