import React, { useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  Selection,
} from "@nextui-org/react";
// import { PlusIcon } from "../../assets/icons/PlusIcon";
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { ChevronDownIcon } from "../../assets/icons/ChevronDownIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { useTranslation } from "react-i18next";
import { columns4 } from 'src/utils/Constant';
import CustomAvatar from "../../utils/partials/CustomFileType";

// import { ReactComponent as PdfIcon } from "../../assets/svgs/pdf.svg";

// icons 
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import MainFileIcon from '../../assets/icons/MainFileIcon';
import useListAllTrash from "src/hooks/useListAllTrash";
import { TrashItem } from "src/types/api/Trash";
import useTrashFilesStore from "src/store/layout/useTrashFilesGridListStoreLayout";
import useRestoreFolder from "src/hooks/useRestoreFolder";
import useRestoreFile from "src/hooks/useRestoreFile";
import { Pagination } from "antd";
import useRefreshTrash from "src/hooks/useRefreshTrash";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import useFavoriteStore from "src/store/api/useFavoriteStore";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  RAR: <RarIcon height={20} width={30} />,
  ARJ: <ArjIcon height={20} width={30} />,
  folder: <MainFileIcon height={23} width={30} />
};

const RecoveryTable: React.FC = () => {
  const INITIAL_VISIBLE_COLUMNS = ["name", "type", 'Duration', "actions"];
  const { t } = useTranslation();
 
  const {
    filterValue,
    setFilterValue,
    selectedKeys,
    setSelectedKeys,
    setSortDescriptor,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    page,
    setPage,
  } = useTrashFilesStore();

  const { listAllTrashedItems, isLoading, totalPages, totalItems, items: trashedItems, clearHasFetched } = useListAllTrash();
  const trashList = useMemo(() => trashedItems || [], [trashedItems]);
  const { restoreFolder } = useRestoreFolder();
  const { restoreFile } = useRestoreFile();
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refeshListAllFoldersFiles, currentPage, totalItems: homeTotalItems } = useRefreshListAllFolders();
  const { fetchTrashedItems, currentPage: TrashCurrentPage, totalItems: TrashTotalItems } = useRefreshTrash();
  const { page: favoritePage, rowsPerPage: favoriterowsPerPage, reftreshFetchFavoritesWithoutDirectory } = useFavoriteStore();


  useEffect(() => {
    listAllTrashedItems(page, rowsPerPage);
  }, [listAllTrashedItems, page, rowsPerPage]);

  const [visibleColumns] = React.useState<Selection>(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") return columns4;
    return columns4.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const translatedHeaderColumns = useMemo(() => {
    return headerColumns.map(column => ({
      ...column,
      name: t(`tableHeaders.${column.uid}`)
    }));
  }, [headerColumns, t]);

  const filteredItems = useMemo(() => {
    let filteredTrash = trashList ? [...trashList] : [];
    if (hasSearchFilter) {
      filteredTrash = filteredTrash.filter((trash) =>
        trash?.name?.toLowerCase().includes(filterValue.toLowerCase())
      || trash?.original_filename?.toLowerCase().includes(filterValue.toLowerCase())

      );
    }
    return filteredTrash;
  }, [trashList, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => {
    return filteredItems; // The API already handles pagination, so no need to slice here
  }, [filteredItems]);

  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, "_blank");
    } else {
      // Handle cases where the file URL is not available
    }
  }, []);

  const handleRecoverItem = useCallback(async (itemType: string, itemId: string) => {
    try {
      if (itemType === "folder")
        await restoreFolder(itemId);
      else
        await restoreFile(itemId);

      await fetchTrashedItems(TrashCurrentPage, TrashTotalItems);
      await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
      await refeshListAllFoldersFiles(currentPage, homeTotalItems);
      await reftreshFetchFavoritesWithoutDirectory(favoritePage, favoriterowsPerPage);

    } catch (error) {
      console.error("Failed to recover item:", error);
    }
  }, [restoreFolder, restoreFile, fetchTrashedItems, TrashCurrentPage, TrashTotalItems, refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems, refeshListAllFoldersFiles, currentPage, homeTotalItems]);

  const renderCell = useCallback((trashedItems: TrashItem, columnKey: React.Key): React.ReactNode => {
    const cellValue = trashedItems[columnKey as keyof TrashItem] || "N/A";

    switch (columnKey) {
      case "name":
        const fileIcon = typesIconMap[trashedItems.file_type as keyof typeof typesIconMap] || null;
        return (
          <div className="flex items-start gap-2">
            {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}
            <span className="text-lg"
              style={{
                cursor: "pointer" // makes it clear the element is clickable
              }}
              onClick={() => handleDownload(trashedItems.s3_url || "")} // Trigger download on click
              >
                {trashedItems.original_filename && trashedItems.original_filename.length >= 60
                  ? `${trashedItems.original_filename.slice(0, 60)}.....`
                  : trashedItems.original_filename || trashedItems.name}
                
                </span>
          </div>
        );
      case "type":
        return (
          <Chip
            className="capitalize border-none gap-1 text-default-600"
            size="sm"
            variant="flat"
          >
            {trashedItems.file_type || trashedItems.type}
          </Chip>
        );
      case "Duration":
        return (
          <div className="flex items-start gap-2">
            {trashedItems.duration_left} {t('days')}
          </div>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown className="bg-background border-1 border-default-200">
              <DropdownTrigger>
                <Button isIconOnly radius="full" size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-400" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    if (trashedItems.type && trashedItems.id) {
                      handleRecoverItem(trashedItems.type, trashedItems.id);
                    }
                  }}>{t('Recover')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }, [handleRecoverItem, t]);

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
       listAllTrashedItems(page, rowsPerPage); // Ensure fetching happens after state update
    }, [setRowsPerPage, setPage, listAllTrashedItems, page, rowsPerPage]);

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
       listAllTrashedItems(page, rowsPerPage); // Ensure fetching happens after state update
    } else {
      setFilterValue("");
    }
  }, [listAllTrashedItems, page, rowsPerPage, setFilterValue, setPage]);

  const handleRecover = () => {
    // Implement your download logic here
  };

  const topContent = useMemo(() => (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          classNames={{
            base: "w-full sm:max-w-[44%]",
            inputWrapper: "border-1",
          }}
          placeholder={t('SearchByFileName')}
          startContent={<SearchIcon className="text-default-300" />}
          value={filterValue}
          variant="bordered"
          onClear={() => setFilterValue("")}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
        </div>
      </div>
      <div className="flex justify-between items-center ">
        <span className="text-default-400 text-small">
          {isLoading ? t('Loading') : `${t('TotalFiles')}: ${totalItems}`}
        </span>
        {(selectedKeys === "all" || (selectedKeys instanceof Set && selectedKeys.size >= 2)) && (
          <Dropdown className="ml-4">
            <DropdownTrigger>
              <Button
                className="bg-success text-background"
                size="sm"
                variant="flat"
              >
                {t('Actions')} <ChevronDownIcon className="text-small" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem onClick={handleRecover}>{t('Recover')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </div>
  ), [t, filterValue, onSearchChange, isLoading, totalItems, rowsPerPage, onRowsPerPageChange, selectedKeys, setFilterValue]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center ">
        <Pagination
          current={page}
          total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={rowsPerPage}
          onChange={(newPage) => {
            clearHasFetched();
            setPage(newPage);
        }}
          showSizeChanger={false}
        />
        {/* <span className="text-small text-default-400">
          {selectedKeys === "all"
            ? `${t('AllItemsSelected')}`
            : `${selectedKeys.size} ${t('of')} ${trashList.length} ${t('selected')}`}
        </span> */}
      </div>
    );
  }, [page, totalPages, rowsPerPage, selectedKeys, t, trashList.length, setPage]);

  const classNames = useMemo(
    () => ({
      wrapper: ["max-h-[382px]", "max-w-3xl"],
      th: ["bg-transparent", "text-default-500", "border-b", "border-divider"],
      td: [
        // changing the rows border radius
        // first
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        // middle
        "group-data-[middle=true]:before:rounded-none",
        // last
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
      ],
    }),
    []
  );

  return (
    <div className="w-full bg-white p-4 rounded-lg shadow overflow-x-auto">
      <Table
        isCompact
        className="min-w-full"
        removeWrapper
        aria-label="Example table with custom cells, pagination and sorting"
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        checkboxesProps={{
          classNames: {
            wrapper:
              "after:bg-secondary after:text-background text-background",
          },
        }}
        classNames={classNames}
        selectedKeys={selectedKeys}
        selectionMode="none"
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader className="bg-white" columns={translatedHeaderColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "end" : "start"}
              allowsSorting={column.sortable}
            >
              {t(column.name)}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={t("NoTrashFound")} items={trashedItems || []}>
          {(sortedItems || []).map((item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default RecoveryTable;