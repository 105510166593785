import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from '@nextui-org/react';
import { VerticalDotsIcon } from '../../../assets/icons/VerticalDotsIcon';
import MainFileIcon from '../../../assets/icons/MainFileIcon';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import ViewsIcon from '../../../assets/icons/ViewsIcon';
import HeartIcon from '../../../assets/icons/Hearticon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Item } from 'src/types/api/ListFolderFiles';
import AddNewFolderModal from 'src/components/modals/AddNewFolderModal';
import DeleteFolderModal from 'src/components/modals/DeleteFolderModal';
import EditFolderModal from 'src/components/modals/EditFolderModal';
import ShareFolderModal from 'src/components/modals/ShareFolderModal';
import { create } from 'zustand';
import { useToggleFolderFavorite } from 'src/hooks/useFavoriteHooks';
import FolderDetailsModal from 'src/components/modals/FolderDetailsModal';
import { useListAllDirectories } from 'src/hooks/useListAllDirectories';
import useListFolderContents from 'src/hooks/useListFolderContents';
import useFolderStore from 'src/store/api/useFolderStore';
import { useFolderHooks } from 'src/hooks/useFolderHooks';
  
interface FolderState {
  folderId: string | null;
  setFolderId: (id: string) => void;
  clearFolderId: () => void;  // Method to clear folderId
}

export const useFolderContentStore = create<FolderState>((set) => ({
  folderId: null,
  setFolderId: (id: string) => set({ folderId: id }),
  clearFolderId: () => set({ folderId: null }),  // Implementation to clear folderId
}));

interface FolderCardProps {
  folder: Item;
}

const FolderCard: React.FC<FolderCardProps> = ({ folder }) => {
  const [liked, setLiked] = useState(Number(folder.is_favorite) === 1);
  const { t } = useTranslation();
  const { setFolderId } = useFolderContentStore(); // Zustand store to manage selected folder id
  const toggleFolderFavorite = useToggleFolderFavorite(); // Hook to toggle favorite status
  const { directories } = useListAllDirectories();
  const directory = directories?.find((dir) => dir.directory_id === folder.directory_id);
  const {  setTheSelectedFolderId} = useListFolderContents();
  const {selectedFolderStack, pushFolder} = useFolderStore();
const {handleDownloadFolder} = useFolderHooks();

  useDisclosure();
  const { isOpen: isFolderDetailsOpen, onOpen: onOpenFolderDetails, onClose: onCloseFolderDetails } = useDisclosure();
  const { isOpen: isEditFolderOpen, onOpen: onOpenEditFolder, onClose: onCloseEditFolder } = useDisclosure();
  const { isOpen: isDeleteFolderOpen, onOpen: onOpenDeleteFolder, onClose: onCloseDeleteFolder } = useDisclosure();
  const { isOpen: isShareFolderOpen, onOpen: onOpenShareFolder, onClose: onCloseShareFolder } = useDisclosure();

  const {
    isOpen: isAddNewOpen,
    onClose: onCloseAddNew,
  } = useDisclosure();

  const [, setSelectedFolderId] = useState<string | null>(null);
 
  useEffect(() => {
    setLiked(Number(folder.is_favorite) === 1);
  }, [folder.is_favorite]);

  const handleView = () => {
    console.log('handleView');
    onOpenFolderDetails();
  };  
  const handleEdit = (folderId: string) => {
    setSelectedFolderId(folderId);
    onOpenEditFolder();
  };
  const handleDelete = () => {
    setFolderId(folder.folder_id || '');
    onOpenDeleteFolder();
  };
  const handleShare = () => onOpenShareFolder();
  const handleSave = () => {
  };
  const handleCopyLink = () => {};

  const handleToggleLiked = async () => {
    try {
      setLiked((prev) => !prev); // Optimistic UI update
      await toggleFolderFavorite(folder.folder_id || ''); // API call to toggle favorite status
     } catch (error) {
      console.error('Failed to toggle favorite:', error);
      setLiked((prev) => !prev); // Revert UI if the API call fails
    }
  };

  const handleFolderClick = () => {
    const id = folder.folder_id || '';
    setFolderId(id); // Set the folderId state
    setTheSelectedFolderId(id);
    pushFolder(id, folder.name || "");
    // console.log("selectedFolderStack: ",selectedFolderStack);
  };

  // current year
  const currentYear = new Date().getFullYear();
  
  // Extract year from directory name
  const directoryYear = directory?.name?.match(/\d{4}/)?.[0] ?? null;
  const isCurrentYear = directoryYear === currentYear.toString();
 
  const handleDownload = async () => {
    await handleDownloadFolder();
  };


  return (
    <Card className="lg:w-[15%] w-[20rem] hover:scale-105" key={folder.folder_id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t('ViewDetails')}</DropdownItem>
            <DropdownItem onClick={() => handleEdit(folder.folder_id || '')}>{t('EditFolder')}</DropdownItem>
            {/* <DropdownItem onClick={handleDownload}>{t('Download')}</DropdownItem> */}
            <DropdownItem onClick={handleShare}>{t('Share')}</DropdownItem>
            <DropdownItem color="danger" onClick={handleDelete}>{t('Delete')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Button
          isIconOnly
          className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
          radius="full"
          variant="light"
          onPress={handleToggleLiked}
        >
          <HeartIcon
            className={liked ? "[&>path]:stroke-transparent" : ""}
            fill={liked ? "red" : "none"}
          />
        </Button>
      </CardHeader>

      <Link to="/fileDetails" onClick={handleFolderClick}>
        <CardBody className="px-3 py-0 text-small text-center text-default-400">
          <div className="flex justify-center items-center">
            <MainFileIcon height={75} width={75} />
          </div>
          <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
            {folder.name && folder.name.length >= 40
                  ? `${folder.name.slice(0, 40)}.....`
                  : folder.name || ""}
          </span>
          <span className={`pt-2 capitalize ${isCurrentYear ? 'text-success' : ''}`}>
            {directory ? directory.name : folder.directory_id}
          </span>
        </CardBody>

        <CardFooter className="gap-3 w-full">
          <div className="flex justify-between w-full text-gray-400">
            <div className="pt-2 capitalize">
              <div className="flex justify-center items-start">
                <DownloadIcon color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.download_count || 0}</span>
              </div>
            </div>
            <div className="pt-2 capitalize">
              <div className="flex justify-center items-start">
                <ViewsIcon height="20" color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.view_count || 0}</span>
              </div>
            </div>
          </div>
        </CardFooter>

      </Link>
      
      <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />
      <FolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderDetails={folder} />
      <EditFolderModal folderId={folder.folder_id || ''} folderName={folder.name || ''} isOpen={isEditFolderOpen} onClose={onCloseEditFolder} />
      <DeleteFolderModal isOpen={isDeleteFolderOpen} onClose={onCloseDeleteFolder} folderId={folder.folder_id || ''} folderType="myFolder" />
      <ShareFolderModal onSave={handleSave} onCopyLink={handleCopyLink} isOpen={isShareFolderOpen} onClose={onCloseShareFolder} resourceId={folder.folder_id || ''} resourceName={folder.name || ''} resourceType="folder"/>
    
    </Card>
  );
};

export default FolderCard;
