import { useEffect } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';

const useListAllFoldersFiles = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));

  const { fetchFolders, isLoading, error, successMessage, items, currentPage, totalPages, totalItems } = useFolderStore((state) => ({
    fetchFolders: state.fetchFolders,
    isLoading: state.isFetching,
    error: state.errorFetching,
    successMessage: state.responseMessage,
    items: state.items,
    currentPage: state.currentPage, 
    totalPages: state.totalPages,
    totalItems: state.totalItems,
  }));

  useEffect(() => {
    if (selectedItemId) {
      fetchFolders(currentPage);  // Only fetch folders if selectedItemId is not null
    }
  }, [currentPage, fetchFolders, selectedItemId]);  // Add selectedItemId as a dependency
  
  return { fetchFolders, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, ID: selectedItemId };
};

export default useListAllFoldersFiles;
