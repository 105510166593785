import React, { useEffect, useMemo } from "react";
import NavBar from "../components/header/NarBar";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  BreadcrumbItem,
  Listbox,
  ListboxItem,
  Button,
} from "@nextui-org/react";
import EyeIcon from "../assets/icons/EyeIcon";
 import { useMarkNotificationAsRead } from 'src/hooks/useMarkNotificationAsRead';
import { useMarkAllNotificationsAsRead } from "src/hooks/useMarkAllNotificationsAsRead";
 import useNotificationStore from "src/store/api/useNotificationStore";
import useListAllNotifications from "src/hooks/useListAllNotifications";
import { Pagination } from "antd";
   
export default function Notifications() {

  const { t } = useTranslation();
  const {
    rowsPerPage,
    page,
    setPage,
  } = useNotificationStore();
  
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { listAllNotificationsItems, totalPages, items: allNotifications } = useListAllNotifications();
 
  const { markNotificationAsRead } = useMarkNotificationAsRead();
  const { markAllNotificationsAsRead } = useMarkAllNotificationsAsRead();
  const notificationsList =  allNotifications|| [];
 
  useEffect(() => {
    listAllNotificationsItems(page, rowsPerPage);
  }, [page, rowsPerPage, listAllNotificationsItems]);


  
  // const hasSearchFilter = Boolean(filterValue);




  const handleMarkAsRead = async (notificationId: string) => {
    try {
      await markNotificationAsRead(notificationId);
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  const handleMarkAllRead = async () => {
    try {
      await markAllNotificationsAsRead();
    } catch (error) {
      console.error("Failed to mark all notifications as read:", error);
    }
  };

  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);


  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
        current={page}
        total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
        pageSize={rowsPerPage}
        onChange={(newPage) => setPage(newPage)}
        showSizeChanger={false}
      />
        
      </div>
    );
  }, [page, totalPages, rowsPerPage, setPage]);


  // if (loading) {
  //   return 'looding';
  // }
  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Notifications")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("Notifications")}
            </div>
          </div>

          <div className="flex w-full justify-end mb-5">
            <Button
              className="bg-success text-background"
              size="md"
              onClick={handleMarkAllRead} // Trigger mark all as read on button click
            >
              {t("MarkAllAsRead")}
            </Button>
          </div>

          <div className="bg-white rounded-xl">
            <Listbox
              aria-label="Listbox Variants"
              color="success"
              variant="light"
            >
              {notificationsList.map((notification, index) => (
                <ListboxItem
                  key={notification.notification_id || `fallback-key-${index}`} // Fallback to index if notification_id is undefined
                  textValue={notification.message}
                  className={notification.is_read === 0 ? 'bg-gray-200' : ''}
                >
                  <div className="flex justify-between px-3 py-6 border-b-1">
                    <span>{notification.message}</span>
                    <EyeIcon
                      onClick={() => {
                        if (notification.notification_id) {
                          handleMarkAsRead(notification.notification_id);
                        }
                      }}                    
                       />
                  </div>
                </ListboxItem>
              ))}

            </Listbox>
          </div>

          <div className="py-2 px-2 flex justify-between items-center mt-4">
        
      </div>
      {bottomContent}

        </div>
      </div>
    </div>
  );
}
