//src/hooks/useListFolderContents.ts
import { useEffect } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';

const useRefreshListSharedWithFolderContents = () => {

  const { selectedFolderId, refreshListSharedWithFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, setTheSelectedFolderId } = useFolderStore((state) => ({
    selectedFolderId: state.selectedFolderId,
    refreshListSharedWithFolderContents: state.refreshListSharedWithFolderContents,
    isFetchingFolderContent: state.isFetchingFolderContent,
    errorFetchingFolderContent: state.errorFetchingFolderContent,
    responseMessageFolderContent: state.responseMessageFolderContent,
    responseStatusFolderContent: state.responseStatusFolderContent,
    folderContents: state.folderContents,
    folderCurrentPage: state.folderCurrentPage,
    folderTotalPages: state.folderTotalPages,
    folderTotalItems: state.folderTotalItems,
    setTheSelectedFolderId: state.setTheSelectedFolderId,
  }));

  return { refreshListSharedWithFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, selectedFolderId, setTheSelectedFolderId };
};

export default useRefreshListSharedWithFolderContents;
