import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../switchers/LanguageSwitcher";
import HomeIcon from "../../assets/icons/HomeIcon";
import { useSidebarStore } from "../../store/layout/useSidebarStore";
import useFolderStore from "src/store/api/useFolderStore";


import create from "zustand";

interface PublicSidebarState {
  shareId: string;
  setShareId: (shareId: string) => void;
}

export const usePublicSidebarStore = create<PublicSidebarState>((set) => ({
  shareId: localStorage.getItem("sidebarId") || "",
  setShareId: (shareId) => {
    localStorage.setItem("sidebarId", shareId);
    set({ shareId });
  },
}))


interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const PublicSidebar: React.FC<SidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
}) => {
  const { t } = useTranslation();
  const trigger = useRef<HTMLButtonElement | null>(null);
  const sidebar = useRef<HTMLElement | null>(null);
  const [isRTL, setIsRTL] = useState(document.documentElement.dir === "rtl");

  const { sidebarExpanded } = useSidebarStore();
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve ID from localStorage or default to an empty string
  const [id, setId] = useState<string>(localStorage.getItem('sidebarId') || '');
  const { shareId, setShareId } = usePublicSidebarStore();
  const {  emptyFolderStack } = useFolderStore();

  useEffect(() => {
    // Extract query parameter from URL
    const queryParams = new URLSearchParams(location.search);
    const urlId = queryParams.get('id');
    // If URL ID is present, update localStorage and URL
    if (urlId) {
      setId(urlId);
      setShareId(urlId);
      localStorage.setItem('sidebarId', urlId);

      navigate(`/PublicAccessPage?id=${urlId}`, { replace: true });
    } else if (!id) {
      // If no ID in URL and no ID in state, set a default ID
      const defaultId = "123"; // Replace with your fixed ID value or logic to generate it
      setId(defaultId);
      setShareId(defaultId);
      localStorage.setItem('sidebarId', defaultId);
      navigate(`/PublicAccessPage?id=${defaultId}`, { replace: true });
    }
  }, [location.search, navigate, id]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setIsRTL(document.documentElement.dir === "rtl");
    };

    handleLanguageChange();
    document.addEventListener("dirchange", handleLanguageChange);

    return () => {
      document.removeEventListener("dirchange", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [setSidebarOpen, sidebarOpen]);

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (!sidebarOpen || event.key !== "Escape") return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [setSidebarOpen, sidebarOpen]);

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <>
      <aside
        ref={sidebar}
        className={`fixed top-0 z-50 flex h-[90vh] max-sm:h-full w-72 max-sm:w-full bg-white flex-col duration-300 ease-linear dark:bg-boxdark ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isRTL ? "right-0 left-auto" : "left-0 right-auto"} lg:static lg:translate-x-0 lg:z-auto`}
      >
        <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
          <NavLink to="/">
            {/* <img
              className="lg:hidden"
              src={"https://my.saff.sa/data/logos/1b87946caf.png"}
              width={40}
              alt="Logo"
            /> */}
          </NavLink>

          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden mt-10"
          >
            <svg
              className="fill-current"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                fill=""
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col justify-between flex-1 overflow-y-auto">
          <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
            <div>
              <ul className="mb-6 flex flex-col gap-1.5">
                <li>
                  <NavLink
                    to={`/PublicAccessPage?id=${id}`} // Use the stored ID
                    onClick={() => {
                       emptyFolderStack();
                    }}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? "#F5F8FE" : "",
                      borderRadius: isActive ? "10px" : "",
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#034121" : "",
                    })}
                    className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                        hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""}`
                    }
                  >
                    {({ isActive }) => (
                      <div className="flex justify-center items-start gap-2">
                        <HomeIcon
                          height="20px"
                          width="20px"
                          color={isActive ? "#034121" : "#808080"}
                        />
                        <div>{t("HomePage")}</div>
                      </div>
                    )}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="max-lg:hidden lg:hidden md:visible visible">
              <LanguageSwitcher />
            </div>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default PublicSidebar;
