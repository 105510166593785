import { useMemo } from 'react';
import PublicFileCard from './cards/PublicFileCard';
import PublicFolderCard from './cards/PublicFolderCard';
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import { useTranslation } from 'react-i18next';

export default function FilesGridList({ sharedData }: { sharedData: any }) {
  const {
    filterValue,
    sortDescriptor,
  } = useFilesStore();
  const { t } = useTranslation();

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    let filteredFolders = sharedData ? [...sharedData] : [];
    if (hasSearchFilter) {
      filteredFolders = filteredFolders.filter((folder) =>
        folder?.name?.toLowerCase().includes(filterValue.toLowerCase())
      || folder?.original_filename?.toLowerCase().includes(filterValue.toLowerCase())

      );
    }
    return filteredFolders;
  }, [sharedData, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => {
    return filteredItems; // The API already handles pagination, so no need to slice here
  }, [filteredItems]);


  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  return (
    <div className="w-full p-4">
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">
        {sortedItems.length > 0 ? (
          sortedItems.map((item) =>
            item.type === 'folder' ? (
              <PublicFolderCard
                key={item.id}
                folder={item}
              />
            ) : (
              <PublicFileCard
                key={item.id}
                file={item}
              />
            )
          )
        ) : (
          <div>{t("NoSharedItem")}</div>
        )}
      </div>
    </div>
  );
}