import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Progress,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import FileLinkIcon from "../../assets/icons/FileLinkIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import { PlusIcon } from "../../assets/icons/PlusIcon";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import { useSidebarStore } from "../sidebar/MainSidebar";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import { Alert } from "antd";
import CustomAlert from "../common/CustomAlert";
import useListAllTrash from "src/hooks/useListAllTrash";
import EmptyTrashModal from "../modals/EmptyTrashModal";



const DnD: React.FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { t } = useTranslation();
  const activeTab = useSidebarStore.getState().activeTab; // Fetching latest folderId
  const { userResources, freeSpace, showStorageAlert } = useUserResources();
  // State for managing error message
  const [errorExceelStorageMessage, setErrorExceelStorageMessage] = useState<string>("");
  const { isOpen: isEmptyTrashOpen, onOpen: onOpenEmptyTrashOpen, onClose: onCloseEmptyTrashOpen } = useDisclosure();
  const { totalItems } = useListAllTrash();


  const {
    files,
    uploading,
    description,
    setDescription,
    successMessage,
    errorMessage,
    acceptedFileTypes,
    onDrop,
    handleUpload,
    handleSharedWithUpload,
    handleSharedByUpload,
    handleCancelUpload,
    uploadResponseStatus,
    errorNoFreeSpaceMessage,
  } = useUploadFilesHooks(); // Use the custom hook

  const { getRootProps, getInputProps } = useDropzone({
    onDrop, // This is the function that will be triggered when files are dropped
    accept: acceptedFileTypes,
  });


  // Custom handleUpload that includes the active tab
  const handleUploadWithTab = () => {

    if ((userResources?.can_upload ?? false) || (freeSpace && freeSpace > 0)) {
      /// calculate total file size 

      const pathname = window.location.pathname; // Get the path part of the URL
      const segments = pathname.split('/'); // Split the path into segments
      const lastSegment = segments[segments.length - 1]; // Get the last segment

      if (lastSegment === 'sharedByFileList' && activeTab === "SharedByMe")
        handleSharedByUpload();
      else
        if (lastSegment === 'sharedWithFileList' && activeTab === "SharedWithMe")
          handleSharedWithUpload();
        else
          handleUpload();
    }
    else
      setErrorExceelStorageMessage(t('CannotUploadMoreFile'));
  };

  useEffect(() => {

    if (userResources?.can_upload ?? false)
      setErrorExceelStorageMessage(t('UsedStorage90Persent'));
    else
      setErrorExceelStorageMessage(t('ExceedAllowedStorage'));

  }, [userResources]);


  return (
    <>
      <Button
        className="bg-success text-background"
        endContent={<PlusIcon />}
        size="sm"
        onPress={onOpen}
      >
        {t("UploadNew")}
      </Button>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior="inside">
        <ModalContent>
          {(onClose) => (
            <>

              <ModalHeader className="flex flex-col gap-1">
                {t("SelectFilesToUpload")}
              </ModalHeader>
              <ModalBody>
                {/* 
                {!userResources?.can_upload && (
                  <Alert message={t('ExceedAllowedStorage')} type="error" showIcon closable />
                )} */}

                {/* Alert Message */}
                {showStorageAlert && errorExceelStorageMessage && (
                  <Alert
                    className="bg-[#4b65bc2a] border-[#4b65bc2a] my-5 text-red-600 font-bold capitalize-first-letter"
                    message={
                      <span>
                        {errorExceelStorageMessage}{" "}
                        {totalItems > 0 && (
                          <a
                            href="#"
                            onClick={() => {
                              // Logic to open an empty modal or perform other actions
                              onOpenEmptyTrashOpen();
                            }}
                            className="text-blue-600 underline hover:text-blue-800"
                          >
                            {t("EmptyTrash")}
                          </a>
                        )}
                      </span>
                    }
                  />
                )}

                {uploadResponseStatus && (uploadResponseStatus === "error" || uploadResponseStatus === "success") && (
                  // {uploadResponseStatus && uploadResponseStatus === "success" && (
                  <CustomAlert />
                )}

                {/* {errorNoFreeSpaceMessage && (
                  <CustomAlert />
                )} */}


                <div
                  {...getRootProps()}
                  className="block w-full py-2 px-3 relative bg-[#F4F4F5] appearance-none border-2 border-[#d9d9d9] border-dashed rounded-md hover:shadow-outline-gray"
                >
                  <input {...getInputProps()}
                    className="absolute inset-0 z-50 m-0 p-0 w-full h-full outline-none opacity-0"
                  />
                  <div className="flex flex-col space-y-2 items-center justify-center p-9">
                    <UploadIcon fillColor="" width="40" height="50" />
                    <p className="text-gray-700">
                      {t('DragYourFilesHereOrClickInThisArea')}
                    </p>
                  </div>
                </div>
                <Textarea
                  label={t('Description')}
                  labelPlacement="outside"
                  placeholder={t('EnterYourDescription')}
                  value={description}
                  maxLength={200} // Limiting the text area to 200 characters
                  onChange={(e) => {
                    // Additional check to ensure text area does not exceed 200 characters
                    const inputValue = e.target.value;
                    if (inputValue.length <= 200) {
                      setDescription(inputValue);
                    }
                  }}
                  className="my-4 w-full"
                />
                {files.length > 0 &&
                  files.map((fileWithProgress) => (
                    <div key={fileWithProgress.id} className="flex items-center space-x-2 mb-2">
                      <div className="flex items-center">
                        <FileLinkIcon width="30" />
                        <span className="text-xs">{fileWithProgress.file.name}</span>
                      </div>
                      <Progress value={fileWithProgress.progress} />
                      <div onClick={() => handleCancelUpload(fileWithProgress.id)}>
                        <TrashIcon color="red" size={20}
                          style={{ cursor: "pointer" }} />
                      </div>
                    </div>
                  ))}
                {successMessage && (
                  <div className="text-green-500 text-sm mt-2">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500 text-sm mt-2">
                    {errorMessage}
                  </div>
                )}
                {/* Display the error message */}
                {/* {errorExceelStorageMessage && (
                  <div className="text-red-500 text-sm mt-2">
                    {errorExceelStorageMessage}
                  </div>
                )} */}

              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t("Close")}
                </Button>
                <Button color="primary" onPress={handleUploadWithTab} disabled={uploading}>
                  {t("Upload")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <EmptyTrashModal
        isOpen={isEmptyTrashOpen}
        onClose={onCloseEmptyTrashOpen}
      />
    </>
  );
};

export default DnD;
