import React from "react";
import NavBar from "src/components/header/NarBar";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import RecoveryTable from "../components/trash_page/RecoveryTable";
import { Breadcrumbs, BreadcrumbItem, Button, useDisclosure } from "@nextui-org/react";
import { Alert } from "antd";
 import EmptyTrashModal from "src/components/modals/EmptyTrashModal";
import useListAllTrash from "src/hooks/useListAllTrash";

const Recovery: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { isOpen: isEmptyTrashOpen, onOpen: onOpenEmptyTrashOpen, onClose: onCloseEmptyTrashOpen } = useDisclosure();
  const {  totalItems} = useListAllTrash();

  const handleEmptyTrash = () => {
    onOpenEmptyTrashOpen();
  };

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Recovery")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              <div className="flex justify-between">
                <span>{t("Recovery")}</span>

                <div>
                {totalItems > 0 && (
                <Button
                  className="bg-success text-background"
                  size="sm"
                  onClick={handleEmptyTrash}
                >
                  {t("EmptyTrash")}
                </Button>
              )}
                </div>
              </div>
            </div>
            <Alert
              message={t("ItemsWillBePermanentlyDeleted")}
              className="bg-[#4b65bc2a] border-[#4b65bc2a] my-5 text-red-600 font-bold capitalize-first-letter"
            />
          </div>

          <div>
            <RecoveryTable />
          </div>
        </div>
      </div>

      <EmptyTrashModal
        isOpen={isEmptyTrashOpen}
        onClose={onCloseEmptyTrashOpen}
       />

    </div>
  );
};

export default Recovery;
