//src/hooks/useListFolderContents.ts
import { useEffect } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';

const useListPublicFolderContents = () => {

  const { selectedPublicFolderId, listPublicFolderContents, isFetchingPublicFolderContent, errorPublicFetchingFolderContent, responseMessagePublicFolderContent, responseStatusPublicFolderContent, publicFolderContents, publicFolderCurrentPage, publicFolderTotalPages, publicFolderTotalItems, setTheSelectedPublicFolderId, publicFolderRowsPerPage, clearTheSelectedPublicFolderId } = useFolderStore((state) => ({
    selectedPublicFolderId: state.selectedPublicFolderId,
    listPublicFolderContents: state.listPublicFolderContents,
    isFetchingPublicFolderContent: state.isFetchingPublicFolderContent,
    errorPublicFetchingFolderContent: state.errorPublicFetchingFolderContent,
    responseMessagePublicFolderContent: state.responseMessagePublicFolderContent,
    responseStatusPublicFolderContent: state.responseStatusPublicFolderContent,
    publicFolderContents: state.publicFolderContents,
    publicFolderCurrentPage: state.publicFolderCurrentPage,
    publicFolderTotalPages: state.publicFolderTotalPages,
    publicFolderTotalItems: state.publicFolderTotalItems,
    publicFolderRowsPerPage: state.publicFolderRowsPerPage,
    setTheSelectedPublicFolderId: state.setTheSelectedPublicFolderId,
    clearTheSelectedPublicFolderId: state.clearTheSelectedPublicFolderId,
  }));

  useEffect(() => {
     if (selectedPublicFolderId) {
      listPublicFolderContents(publicFolderCurrentPage);  // Only fetch folders if selectedItemId is not null
   }
  }, [publicFolderCurrentPage, listPublicFolderContents, selectedPublicFolderId]);  // Add selectedItemId as a dependency

  return {listPublicFolderContents, isFetchingPublicFolderContent, errorPublicFetchingFolderContent, responseMessagePublicFolderContent, responseStatusPublicFolderContent, publicFolderContents, publicFolderCurrentPage, publicFolderTotalPages, publicFolderTotalItems, setTheSelectedPublicFolderId, publicFolderRowsPerPage, clearTheSelectedPublicFolderId };
};

export default useListPublicFolderContents;
