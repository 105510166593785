import { getUserResources } from 'src/services/api/API';
import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import { useAlertStore } from 'src/components/common/CustomAlert';

// Type for user resources API response
export interface UserResourceResponse {
  success: boolean;
  message: string;
  storage_limit: number;
  consumed_storage: number;
  trash_storage: number;
  can_upload: boolean;
}

// Type for Zustand store state
export interface UserResourceState {
  userResources: UserResourceResponse | null;
  isLoading: boolean;
  errorUserResourceState: string | null;
  hasFetched: boolean;
  alertLimit: number;
  showStorageAlert: boolean; // Track if storage alert should be shown

  getUserResources: () => Promise<void>;
  clearHasFetched: () => void;  // Method to clear folderId
}

// Create Zustand store
const useUserResourceStore = create<UserResourceState>((set, get) => ({
  userResources: null,
  isLoading: false,
  errorUserResourceState: null,
  hasFetched: false,
  alertLimit: 90.00,
  showStorageAlert: false, // Track if storage alert should be shown

  // Fetch user resources action
  getUserResources: async () => {
    try {
      const { hasFetched, userResources, alertLimit } = get();

      if (!hasFetched) {
        set({ isLoading: true, errorUserResourceState: null });

        const token = useAuthStore.getState().token;
        const data = await getUserResources(token || '');
        console.log("getUserResources");
        console.log(data);
        console.log("getUserResources");
        if (data) {
          // Check if the user can upload
          if (!data.can_upload) {
            set({ showStorageAlert: true });
          } else {
            const { storage_limit, consumed_storage, trash_storage } = data;

            const calculatePercentage = (value: number, total: number) =>
              total === 0 ? 0 : (value / total) * 100;
            const trashPercentage = calculatePercentage(trash_storage, storage_limit);
            const consumedPercentage = calculatePercentage(consumed_storage, storage_limit);
            const usedStorage = trashPercentage + consumedPercentage;

            // Set the showStorageAlert state based on used storage percentage
            set({ showStorageAlert: usedStorage > alertLimit });
          }

        }

        set({ userResources: data, isLoading: false, hasFetched: true });
      }
      else
        set({ userResources: userResources });

    }
    catch (errorUserResourceState) {
      if (errorUserResourceState instanceof Error) {
        set({ errorUserResourceState: errorUserResourceState.message, isLoading: false });
      } else {
        set({ errorUserResourceState: String(errorUserResourceState), isLoading: false });
      }
    }
  },
  // Clear the hasFetched state
  clearHasFetched: () => set({ hasFetched: false }),

}));

export default useUserResourceStore;
