import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFolderStore from "src/store/api/useFolderStore";
import { Alert } from "antd";
 import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
 import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';

interface EditFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  folderId: string;
  folderName: string;
}

const EditFolderModal: React.FC<EditFolderModalProps> = ({
  isOpen,
  onClose,
  folderId,
  folderName: initialFolderName,
}) => {
  const { t } = useTranslation();
  const { updateFolder, isCreating, updateResponseMessage, updateResponseStatus } = useFolderStore();
  const [folderName, setFolderName] = useState(initialFolderName);
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();

  useEffect(() => {
    if (isOpen) {
      setFolderName(initialFolderName);
    }
  }, [isOpen, initialFolderName]);

  const handleSave = async () => {
    await updateFolder(folderId, { name: folderName });
    await refreshListFolderContents(folderCurrentPage);
    if (updateResponseStatus === "success") {
      onClose(); // Close the modal on success
      await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
    }
  };

  // useEffect(() => {
  //   if (!isOpen) {
  //     // Reset state when the modal is closed
  //   }
  // }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("EditFolder")}
        </ModalHeader>
        <ModalBody>
          {updateResponseStatus === "error" && (
            <Alert message={updateResponseMessage} type="error" showIcon closable />
          )}
          {/* {updateResponseStatus === "success" && (
            <Alert message={updateResponseMessage} type="success" showIcon closable />
          )} */}
          <form className="flex flex-col gap-4">
            <Input
              type="text"
              label={t("FolderName")}
              labelPlacement="outside"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave} isLoading={isCreating}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditFolderModal;
