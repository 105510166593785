import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Input,
} from '@nextui-org/react';

// icons 
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { useTranslation } from 'react-i18next';

// modals
import useListAllSharedByMe from 'src/hooks/useListAllSharedByMe';
import SharedByMeFileCard from 'src/components/shared_files/cards/SharedByMeFileCard';
import SharedByMeFolderCard from 'src/components/shared_files/cards/SharedByMeFolderCard';
import useSharedByFilesStore from 'src/store/layout/useSharedByFilesGridListStoreLayout';
import { Pagination } from 'antd';

export default function SharedWithMeFolders() {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    selectedKeys,
    rowsPerPage: sharedByRowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    page: sharedByPage,
    setPage,
  } = useSharedByFilesStore();

  const { listAllShareByUser, isShareByLoading, sharedByUserItems, shareByTotalPages, totalSharedByItems } = useListAllSharedByMe();

  useEffect(() => {
    listAllShareByUser(sharedByPage, sharedByRowsPerPage);
  }, [sharedByPage, sharedByRowsPerPage, listAllShareByUser]);


  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    let filteredFolders = sharedByUserItems ? [...sharedByUserItems] : [];
    if (hasSearchFilter) {
      filteredFolders = filteredFolders.filter((folder) =>
        folder?.name?.toLowerCase().includes(filterValue.toLowerCase())
      || folder?.original_filename?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return filteredFolders;
  }, [sharedByUserItems, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => {
    return filteredItems; // The API already handles pagination, so no need to slice here
  }, [filteredItems]);

  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, [setFilterValue, setPage]);

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4 w-[96.5%]">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: 'lg:w-full sm:max-w-[44%]',
              inputWrapper: 'border-1 bg-white',
            }}
            placeholder={t('SearchByFileName')}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue('')}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            {isShareByLoading ? t('Loading') : `${t('TotalFiles')}: ${totalSharedByItems}`}
          </span>
          {/* not working yet  */}
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">
              {t('RowsPerPage')}
            </label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={sharedByRowsPerPage}
              onChange={onRowsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>
      </div>
    );
  }, [t, filterValue, onSearchChange, isShareByLoading, totalSharedByItems, sharedByRowsPerPage, onRowsPerPageChange, setFilterValue]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={sharedByPage}
          total={shareByTotalPages * sharedByRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={sharedByRowsPerPage}
          onChange={(newPage) => setPage(newPage)}
          showSizeChanger={false}
        />
        {/* <span className="text-small text-default-400">
          {selectedKeys === 'all'
            ? `${t('AllItemsSelected')}`
            : `${selectedKeys.size} ${t('of')} ${paginatedItems.length} ${t('selected')}`}
        </span> */}
      </div>
    );
  }, [sharedByPage, shareByTotalPages, sharedByRowsPerPage, selectedKeys, t, paginatedItems.length, setPage]);

  return (
    <div className="w-full p-4">
      {topContent}
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">

        {isShareByLoading ? (
          t('Loading')
        ) : (
          sortedItems.map((item) =>
            item.type === 'folder' ? (
              <SharedByMeFolderCard key={item.id} folder={item} />
            ) : (
              <SharedByMeFileCard key={item.id} file={item} />
            )
          )
        )}

      </div>
      {bottomContent}
    </div>
  );
}
