import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import { useFolderHooks } from "src/hooks/useFolderHooks";
import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';

interface AddNewFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewFolderModal: React.FC<AddNewFolderModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    folderName,
    setFolderName,
    handleCreateFolder,
    responseMessage,
    responseStatus,
    resetState,
  } = useFolderHooks();

  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();

  const handleSave = async () => {
    await handleCreateFolder();
    await refreshListFolderContents(folderCurrentPage);
    if (responseStatus === "success") {
      setFolderName("");
      onClose(); // Close the modal after success
    }
  };

  useEffect(() => {
    if (responseStatus === "success" && isOpen) {
      onClose(); // Close the modal after successful creation
    }
  }, [responseStatus, isOpen, onClose]);

  const handleClose = () => {
    resetState(); // Reset state when the modal is closed
    onClose(); // Trigger the provided onClose handler
  };

  useEffect(() => {
    if (!isOpen) {
      resetState(); // Reset state when modal is closed
    }
  }, [isOpen, resetState]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("AddNewFolder")}
        </ModalHeader>
        <ModalBody>
          {responseStatus === "error" && (
            <Alert
              message={responseMessage || t("AnErrorOccurred")}
              type="error"
              showIcon
              closable
            />
          )}
          <form className="flex flex-col gap-4">
            <Input
              type="text"
              label={t("FolderName")}
              labelPlacement="outside"
              value={folderName}
              maxLength={50} 
              required
              onChange={(e) => {
                
                const inputValue = e.target.value;
                if (inputValue.length <= 50) {
                  setFolderName(inputValue);
                }
              }}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={handleClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewFolderModal;
