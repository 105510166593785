import React, { useState } from 'react';
import NavBar from "../components/header/NarBar";
import { Tabs, Tab } from '@nextui-org/react';
import MainSidebar, { useSidebarStore } from '../components/sidebar/MainSidebar';
import SharedWithMeFolders from 'src/components/shared_files/shared_with_me/SharedWithMeFolders';
import SharedByMeFolders from 'src/components/shared_files/shared_by_me/SharedByMeFolders';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  BreadcrumbItem,
} from '@nextui-org/react';
import { useAuthStore } from 'src/store/api/useAuthStore';
import DownloadList from 'src/components/common/DownloadList';
import useFolderStore from "src/store/api/useFolderStore";

const Shared: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const isSaff = useAuthStore((state) => state.isSaff);

  // Active tab state
  const [activeKey, setActiveKey] = useState<string>("1");
  const {setActiveTab} = useSidebarStore.getState(); // Fetching latest folderId
  const {  emptyFolderStack } = useFolderStore();

  // Handler for tab change
  const handleTabChange = (key: string) => {
    emptyFolderStack();

     setActiveKey(key); // Update active tab
    if (key === "1")
      setActiveTab("SharedWithMe");
    else
      setActiveTab("SharedByMe");

  };

  
  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("SharedFiles")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("SharedFiles")}
            </div>
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          {/* Tabs */}
          <Tabs variant="underlined" aria-label="Shared Tabs"
            selectedKey={activeKey}
            onSelectionChange={(key) => handleTabChange(key as string)} // Cast to string
          >
            <Tab key="1" title={t("SharedWithMe")}>
              <SharedWithMeFolders />
            </Tab>
            {isSaff && (
              <Tab key="2" title={t("SharedByMe")}>
                <SharedByMeFolders />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Shared;