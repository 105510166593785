// src/hooks/api/useUpdateEvent.ts

import useFilesStore from "src/store/layout/useFilesStore";
 
export const useEditFile = () => {
  const updateFile = useFilesStore((state) => state.updateFile);
  const setUpdateResponseStatus = useFilesStore((state) => state.setUpdateResponseStatus);
  const updateResponseMessage = useFilesStore((state) => state.updateResponseMessage);
  const isUpdating = useFilesStore((state) => state.isUpdating);
  const updateResponseStatus = useFilesStore((state) => state.updateResponseStatus);
  const errorUpdating = useFilesStore((state) => state.errorUpdating);


  return {
    updateFile,
    setUpdateResponseStatus,
    updateResponseMessage,
    isUpdating,
    updateResponseStatus,
    errorUpdating
  };
};
 