const API_BASE_URL_DEMO = process.env.PUBLIC_URL === '/c'
  ? process.env.REACT_APP_API_BASE_URL_LIVE
  : process.env.REACT_APP_API_BASE_URL_DEMO;

export const PUBLIC_URL_DEMO = process.env.PUBLIC_URL === '/c'
  ? process.env.REACT_APP_PUBLIC_URL_LIVE
  : process.env.REACT_APP_PUBLIC_URL_DEMO;

  // export const API_BASE_URL_DEMO ='http://localhost:8080';
export const ENDPOINTS_DEMO = {
  REGISTER_USER: `${API_BASE_URL_DEMO}/users/register`,
  LOGIN_USER: `${API_BASE_URL_DEMO}/users/login`,
  TOGGLE_FILE_FAVORITE: (fileId: string) => `${API_BASE_URL_DEMO}/favorites/file/${fileId}/toggle`,
  TOGGLE_FOLDER_FAVORITE: (folderId: string) => `${API_BASE_URL_DEMO}/favorites/folder/${folderId}/toggle`,
  // CREATE_FOLDER: (directoryId: string) => `${API_BASE_URL_DEMO}/folder/directory/${directoryId}/create`,   // ===> replaced with 2.2
  DELETE_EXPIRED_FOLDERS: `${API_BASE_URL_DEMO}/folder/delete-expired`,
  LIST_ALL_FOLDERS: `${API_BASE_URL_DEMO}/folder/directory/2/list-all`,
  LIST_ALL_FOLDERS_FILES: (selectedItemId: string) => `${API_BASE_URL_DEMO}/directory/${selectedItemId}/list-folder-files`,   // copy idea
  COPY_FOLDER: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/copy`,
  RESTORE_FOLDER: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/restore/`,
  // GET_FOLDER_DETAILS: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/get-details`,
  UPDATE_FOLDER: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/update`,
  UPLOAD_FOLDER: `${API_BASE_URL_DEMO}/folder/upload`,
  LIST_ALL_DIRECTORIES: `${API_BASE_URL_DEMO}/directory/list-all`,
  LIST_TRASH_DIRECTORIES: `${API_BASE_URL_DEMO}/directory/list-trash`,
  GET_DIRECTORY_DETAILS: (directoryId: string) => `${API_BASE_URL_DEMO}/directory/${directoryId}/get-details`,
  CREATE_DIRECTORY: `${API_BASE_URL_DEMO}/directory/create`,
  UPDATE_DIRECTORY: (directoryId: string) => `${API_BASE_URL_DEMO}/directory/${directoryId}/update/`,
  DELETE_DIRECTORY: (directoryId: string) => `${API_BASE_URL_DEMO}/directory/${directoryId}/delete`,
  TRASH_DIRECTORY: (directoryId: string) => `${API_BASE_URL_DEMO}/directory/${directoryId}/trash`,
  RESTORE_DIRECTORY: (directoryId: string) => `${API_BASE_URL_DEMO}/directory/${directoryId}/restore`,
  EMPTY_TRASH_DIRECTORY: `${API_BASE_URL_DEMO}/directory/empty-trash`,
  SET_TRASH_SOFT_ALL_DIRECTORIES: `${API_BASE_URL_DEMO}/directory/set-trash-soft`,
  DELETE_ALL_EXPIRED_DIRECTORIES: `${API_BASE_URL_DEMO}/directory/delete-expired`,
  LIST_USERS: `${API_BASE_URL_DEMO}/users/list`,
  LIST_USERS_SAFF: `${API_BASE_URL_DEMO}/users/list-saff`,
  LIST_ALL_NOTIFICATIONS: `${API_BASE_URL_DEMO}/notification/list-all`,
  LIST_LAST_THREE_NOTIFICATIONS: `${API_BASE_URL_DEMO}/notification/list-last-3`,
  DELETE_ALL_NOTIFICATIONS: `${API_BASE_URL_DEMO}/notification/delete-all`,
  DELETE_NOTIFICATION: (notificationId: string) => `${API_BASE_URL_DEMO}/notification/${notificationId}/delete`,
  MARK_NOTIFICATION_READ: (notificationId: string) => `${API_BASE_URL_DEMO}/notification/${notificationId}/mark-read`,
  MARK_ALL_NOTIFICATIONS_READ: `${API_BASE_URL_DEMO}/notification/mark-all-read`,
  LIST_ALL_SHARES: (resourseType: 'folder' | 'file', resourseId: string) =>
    `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/list-all`,
  CREATE_SHARE: (resourseType: 'folder' | 'file', resourseId: string) =>
    `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/create`,
  UPDATE_SHARE: (resourseType: 'folder' | 'file', resourseId: string) =>
    `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/update`,
  DELETE_SHARE: (resourseType: 'folder' | 'file', resourseId: string) =>
    `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/revoke`,
  LIST_FAVORITES: (directoryId: number) => `${API_BASE_URL_DEMO}/favorites/directory/${directoryId}/list`,
  LIST_FAVORITES_WITHOUT_DIRECTORY: () => `${API_BASE_URL_DEMO}/favorites/list`,
  UPLOAD_FILES: (selectedItemId: string, folderId: string) =>
    `${API_BASE_URL_DEMO}/file/directory/${selectedItemId}/folder/${folderId}/upload-test`,
  UPLOAD_FILES_WITHOUT_FOLDER: (selectedItemId: string) =>
    `${API_BASE_URL_DEMO}/file/directory/${selectedItemId}/upload-without-folder`,
  CREATE_FOLDER_MODEL: `${API_BASE_URL_DEMO}/folder/directory/1/create`,
  // LIST_FILES: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/list-files`, // ===> replaced with 2.1
  TRASH_FOLDER: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/trash`,
  TRASH_FILE: (fileId: string) => `${API_BASE_URL_DEMO}/file/${fileId}/trash`,
  LIST_ALL_ROLES: () => `${API_BASE_URL_DEMO}/role/list`,
  DELETE_ROLE: (roleId: string) => `${API_BASE_URL_DEMO}/role/${roleId}/delete`,
  UPDATE_ROLE: (roleId: string) => `${API_BASE_URL_DEMO}/role/${roleId}/update`,
  CREATE_ROLE: () => `${API_BASE_URL_DEMO}/role/create`,
  LIST_SHARED_FOLDERS_BY_USER: `${API_BASE_URL_DEMO}/share/folder/list-shared-by-user`,
  LIST_SHARED_FOLDERS_With_USER: `${API_BASE_URL_DEMO}/share/folder/list-shared-with-user`,
  LIST_SHARED_FILES_BY_USER: `${API_BASE_URL_DEMO}/share/file/list-shared-by-user`,
  LIST_SHARED_FILES_With_USER: `${API_BASE_URL_DEMO}/share/file/list-shared-with-user`,
  LIST_ALL_SHARED_WITH_USER: (selectedItemId: string) => `${API_BASE_URL_DEMO}/share/list-all-shared-with-user`,
  LIST_ALL_SHARED_BY_USER: (selectedItemId: string) => `${API_BASE_URL_DEMO}/share/list-all-shared-by-user`,
  LIST_ALL_TREASH: `${API_BASE_URL_DEMO}/trash/list-all`,
  EMPTY_TREASH: `${API_BASE_URL_DEMO}/trash/empty-all`,
  LIST_ALL_NOTIFICATIONS_ITEMS: (selectedItemId: string) => `${API_BASE_URL_DEMO}/notification/list-all`,
  RESTORE_FILE: (fileId: string) => `${API_BASE_URL_DEMO}/file/${fileId}/restore`,
  EMPTY_TRASH_FILE: `${API_BASE_URL_DEMO}/file/empty-trash`,
  EMPTY_TRASH_FOLDER: `${API_BASE_URL_DEMO}/folder/empty-trash`,
  SIGN_IN: `${API_BASE_URL_DEMO}/users/login`,
  VERIFY_GOOGLE_AUTH: `${API_BASE_URL_DEMO}/users/verify`,
  // GET_FILE_DETAILS: (fileId: string) => `${API_BASE_URL_DEMO}/file/${fileId}/get-details`,
  GET_PUBLIC_SHARE_LINK: (resourseType: 'folder' | 'file', resourseId: string) => `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/get-link`,
  SAVE_SHARE: (resourseType: 'folder' | 'file', resourseId: string) => `${API_BASE_URL_DEMO}/share/${resourseType}/${resourseId}/save`,
  LIST_PUBLIC_SHARE: `${API_BASE_URL_DEMO}/public/get-details`,
  // LIST_PUBLIC_FILES: (folderId: string) => `${API_BASE_URL_DEMO}/public/folder/${folderId}/list-files`,// ===> replaced with 2.3
  LOGIN_MY_SAFF: `${API_BASE_URL_DEMO}/users/login-mysaff`,
  ASSIGN_ROLES_TO_USER: (userId: string) => `${API_BASE_URL_DEMO}/role/${userId}/assign`,
  FETCH_FILES: `${API_BASE_URL_DEMO}/file/list`,
  UPDATE_USER: (userId: string) => `${API_BASE_URL_DEMO}/users/${userId}/update`,
  GET_USER_RESOURCES: `${API_BASE_URL_DEMO}/resource/get-user-resource`,


  //second Phase ----------------------------------------------------------------------------------
  LIST_FOLDER_CONTENTS: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/list-folder-contents`, // ==> (2.1)
  CREATE_FOLDER: (directoryId: string, parentFolderId: string) => `${API_BASE_URL_DEMO}/folder/${parentFolderId}/directory/${directoryId}/create`,   // ===> (2.2)
  LIST_PUBLIC_FOLDER_CONTENTS: (folderId: string) => `${API_BASE_URL_DEMO}/public/folder/${folderId}/list-folder-contents`,// ===> replaced with 2.3
  DOWNLOAD_FOLDER: (folderId: string) => `${API_BASE_URL_DEMO}/folder/${folderId}/download`,
  UPDATE_FILE: (fileId: string) => `${API_BASE_URL_DEMO}/file/${fileId}/update`,


};
