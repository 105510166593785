import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from "@nextui-org/react";

// icons
import { VerticalDotsIcon } from "../../../assets/icons/VerticalDotsIcon";
import ViewsIcon from "../../../assets/icons/ViewsIcon";
import HeartIcon from "../../../assets/icons/Hearticon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sharedItem } from "src/types/api/Share";
import SharedFolderIcon from "../../../assets/icons/SharedFolderIcon";
import { create } from "zustand";
import { useToggleFolderFavorite } from "src/hooks/useFavoriteHooks";
import FolderDetailsModal from "src/components/modals/FolderDetailsModal";
import { useListAllDirectories } from "src/hooks/useListAllDirectories";
 import useListSharedWithFolderContents from "src/hooks/useListSharedWithFolderContents";
 import useFolderStore from 'src/store/api/useFolderStore';

interface FolderState {
  folderId: string | null;
  directoryId: string | null;
  folderPermissions: string | null;
  setFolderId: (id: string) => void;
  setDirectoryId: (id: string) => void;
  setFolderPermissions: (permissions: string) => void;
  clearFolderId: () => void;  // Method to clear folderId
}

export const useSharedWithFolderStore = create<FolderState>((set) => ({
  folderId: null,
  directoryId: null,
  folderPermissions: null,
  setFolderId: (id) => {
    set({ folderId: id });
  },
  setDirectoryId: (id) => {
    set({ directoryId: id });
  },
  setFolderPermissions: (permissions) => {
    set({ folderPermissions: permissions });
  },

  clearFolderId: () => set({ folderId: null }),  // Implementation to clear folderId

}));

interface SharedWithMeFolderCardProps {
  folder: sharedItem;
}

const SharedWithMeFolderCard: React.FC<SharedWithMeFolderCardProps> = ({
  folder,
}) => {
  const [liked, setLiked] = useState(Number(folder.is_favorite) === 1);
  const { t } = useTranslation();
  const { setFolderId, setDirectoryId, setFolderPermissions } = useSharedWithFolderStore();
  const toggleFolderFavorite = useToggleFolderFavorite();
  const { directories } = useListAllDirectories();
  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();
  const {pushFolder} = useFolderStore();

  useEffect(() => {
    setLiked(Number(folder.is_favorite) === 1);
  }, [folder.is_favorite]);

  const handleView = () => {
    onOpenFolderDetails();
  };

  const handleToggleLiked = async () => {
    try {
      setLiked((prev) => !prev); // Optimistic UI update
      await toggleFolderFavorite(folder.id || ""); // API call to toggle favorite status
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
      setLiked((prev) => !prev); // Revert UI if the API call fails
    }
  };

  // Extract year from created_at
  // const year = new Date(folder.created_at).getFullYear();

  // Find the directory with the matching directory_id
  const directory = directories?.find(
    (dir) => dir.directory_id === folder.directory_id
  );

  // Current year
  const currentYear = new Date().getFullYear();

  // Extract year from directory name
  const directoryYear = directory?.name?.match(/\d{4}/)?.[0] ?? null;
  const isCurrentYear = directoryYear === currentYear.toString();
  const { setTheSelectedFolderId } = useListSharedWithFolderContents();
 
  return (
    <Card className="lg:w-[15%] w-[20rem] hover:scale-105" key={folder.id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t("ViewDetails")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Button
          isIconOnly
          className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
          radius="full"
          variant="light"
          onPress={handleToggleLiked}
        >
          <HeartIcon
            className={liked ? "[&>path]:stroke-transparent" : ""}
            fill={liked ? "red" : "none"}
          />
        </Button>
      </CardHeader>

      <Link
        to="/sharedWithFileList"
        onClick={() => {
          setFolderId(folder.id || "");
          setDirectoryId(folder.directory_id || "");
          setFolderPermissions(folder.permissions);
          setTheSelectedFolderId(folder.id);
          pushFolder(folder.id, folder.name || "");
        }}
      >
        <CardBody className="px-3 py-0 text-small text-center text-default-400">
          <div className="flex justify-center items-center">
            <SharedFolderIcon height={70} width={70} />
          </div>
          <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
          {folder.name && folder.name.length >= 40
                  ? `${folder.name.slice(0, 40)}.....`
                  : folder.name || ""}
          </span>
          <span
            className={`pt-2 capitalize ${isCurrentYear ? "text-success" : ""}`}
          >
            {directory ? directory.name : folder.directory_id}
          </span>
        </CardBody>

        <CardFooter className="gap-3 w-full">
          <div className="flex justify-between w-full text-gray-400">
            <div className="pt-2 capitalize">
              {/* <div className="flex justify-center items-start">
                <DownloadIcon color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.download_count || 0}</span>
              </div> */}
            </div>
            <div className="pt-2 capitalize">
              <div className="flex justify-center items-start">
                <ViewsIcon
                  height="20"
                  color="rgb(156 163 175 / var(--tw-text-opacity))"
                />
                <span>{folder.view_count || 0}</span>
              </div>
            </div>
          </div>
        </CardFooter>
      </Link>

      <FolderDetailsModal
        isOpen={isFolderDetailsOpen}
        onClose={onCloseFolderDetails}
        folderDetails={folder || ""}
      />
    </Card>
  );
};

export default SharedWithMeFolderCard;
