import React, { useState, useEffect } from "react";
import NavBar from "../components/public_header/NarBar";
import PublicSidebar from "../components/public_sidebar/PublicSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import GridList from "../components/public_access_page/PublicFilesGridList";
import Loader from "src/components/loaders/Loader";
import { useNavigate } from 'react-router-dom';
import { useFetchPublicShare } from "src/hooks/useFetchPublicShare";
import useFolderStore from "src/store/api/useFolderStore";

const PublicAccessPage: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [sharedData, setSharedData] = useState<any>(null);
  const { error, publicSharedItem, fetchPublicShare } = useFetchPublicShare();
  const {  emptyFolderStack } = useFolderStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const share_id = searchParams.get('id');

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    if (share_id) {
      fetchPublicShare(share_id);
    } else {
      navigate('/404');
    }

    return () => clearTimeout(timer);
  }, [fetchPublicShare, navigate]);

  useEffect(() => {
    if (publicSharedItem) {
      emptyFolderStack();
      setSharedData([publicSharedItem]); // Directly set the fetched item
    }
  }, [publicSharedItem]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    navigate('/404');
  }

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <PublicSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("HomePage")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("HomePage")}
            </div>
          </div>

          <GridList sharedData={sharedData} />
        </div>
      </div>
    </div>
  );
};

export default PublicAccessPage;
