import useTrashStore from 'src/store/api/useTrashStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
 
const useListAllTrash = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));

  const { listAllTrashedItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, hasFetched, clearHasFetched } = useTrashStore((state) => ({
    listAllTrashedItems: state.listAllTrashedItems,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
    items: state.trashedItems,
    currentPage: state.currentPage,
    totalPages: state.totalPages,
    totalItems: state.totalItems,
    hasFetched : state.hasFetched,
    clearHasFetched: state.clearHasFetched,
  }));

  return { listAllTrashedItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, ID: selectedItemId, hasFetched, clearHasFetched };
};

export default useListAllTrash;
