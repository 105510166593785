import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuthStore } from 'src/store/api/useAuthStore';
import { useUploadFileStore } from "src/store/layout/useUploadFilesStore";
import { useDownloadStore } from 'src/store/layout/useDownloadStore'; // Import the download store
import { useTranslation } from "react-i18next";
import useFolderStore from "src/store/api/useFolderStore";
import { useFolderContentStore, useFolderContentStore as useFolderStoreId } from 'src/components/main_page/cards/FolderCard';
import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';
import useRefreshListSharedByFolderContents from "./useRefreshListSharedByFolderAllContents";
import useRefreshListSharedWithFolderContents from "./useRefreshListSharedWithFolderAllContents";
import DnD from "src/components/drag_and_drop/DND";
import { useUserResources } from "./useUserResourcesHooks";
import { useFolderHooks } from "./useFolderHooks";
import useListFolderContents from "./useListFolderContents";
import { useSharedByFolderStore } from "src/components/shared_files/cards/SharedByMeFolderCard";
import { useSharedWithFolderStore } from "src/components/shared_files/cards/SharedWithMeFolderCard";
import { useAlertStore } from "src/components/common/CustomAlert";

export const useUploadFilesHooks = () => {
  const { files, uploading, description, setDescription, addFiles, clearFiles, removeFile, uploadFiles, uploadSharedByFiles, uploadSharedWithFiles, uploadResponseStatus } = useUploadFileStore();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const token = useAuthStore(state => state.token);
  const { listAllFoldersFiles, totalItems, setTheSelectedFolderId } = useFolderStore();
  const { t } = useTranslation();
  const folderId = useFolderStoreId.getState().folderId;
  const { downloads, addDownload, updateProgress } = useDownloadStore(); // Use the download store
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorNoFreeSpaceMessage, setErrorNoFreeSpaceMessage] = useState<string>("");
  const { freeSpace } = useUserResources();
  const { setFolderName, handleUploadNewFolder } = useFolderHooks();
  const { setFolderId: setHomeFolderId, folderId: homeFolderId } = useFolderContentStore(); // Zustand store to manage selected folder id
  const { setFolderId: setSharedByFolderId, folderId: sharedByFolderId } = useSharedByFolderStore(); // Zustand store to manage selected folder id
  const { setFolderId: setSharedWithFolderId, folderId: sharedWithFolderId } = useSharedWithFolderStore();


  useEffect(() => {
    const handleClick = () => setSuccessMessage(null);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const acceptedFileTypes = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "image/vnd.adobe.photoshop": [".psd", ".psb"],
    "application/postscript": [".ai"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
    "video/mp4": [".mp4"],
    "video/x-msvideo": [".avi"],
    "video/quicktime": [".mov"],
    "video/webm": [".webm"],
    "audio/mpeg": [".mp3"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/x-indesign": [".indd"],
    "application/zip": [".zip"],
    "application/x-rar-compressed": [".rar"],
    "application/x-arj": [".arj"],
  };

  const validFileTypes: { [key: string]: string[] } = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "image/vnd.adobe.photoshop": [".psd", ".psb"],
    "application/postscript": [".ai"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
    "video/mp4": [".mp4"],
    "video/x-msvideo": [".avi"],
    "video/quicktime": [".mov"],
    "video/webm": [".webm"],
    "audio/mpeg": [".mp3"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/x-indesign": [".indd"],
    "application/zip": [".zip"],
    "application/x-rar-compressed": [".rar"],
    "application/x-arj": [".arj"],
  };


  const errorNoFreeSpaceMessageDisplay = () => {

    const message = `${t('CannotUploadMoreFile')} ${freeSpace} ${t('MB')} ${t('Remaining')}`;
    setErrorNoFreeSpaceMessage(message);
    useAlertStore.getState().showAlert(message, "error");

  };

  const onDrop = (acceptedFiles: File[]) => {

    if (freeSpace && freeSpace > 0) {
      let totalSize = 0;

      // Filter files based on accepted types
      const filteredFiles = acceptedFiles.filter((file) => {
        const fileType = file.type;
        const acceptedExtensions = validFileTypes[fileType];
        if (!acceptedExtensions) {
          console.warn(`File type not accepted: ${fileType}`); // Log warning for unsupported types
          return false;
        }
        totalSize += file.size;
        return true;
      });

      if (freeSpace && freeSpace < totalSize) {
        errorNoFreeSpaceMessageDisplay();
        clearFiles();
        return;
      }
      else {
        const newFiles = filteredFiles.map((file: any) => ({
          file,
          progress: 0,
          id: `${file.name}-${file.size}-${file.lastModified}`,
          cancelTokenSource: axios.CancelToken.source(),
          path: file.path,  // Use name if relativePath is empty
          folderId: null,
        }));


        addFiles(newFiles); // Add to the upload store

        // Add each file to the download store with initial progress of 0%
        newFiles.forEach((file) => {
          const existingDownload = downloads.find((download) => download.fileName === file.file.name);
          if (!existingDownload) {
            addDownload({ id: file.id, fileName: file.file.name, progress: 0 });
          }
        });
      }
    }
    else {
      errorNoFreeSpaceMessageDisplay();
      clearFiles();
      return;
    }

  };

  const onDropContent = (acceptedFiles: File[], folderId: string | null) => {

    if (freeSpace && freeSpace > 0) {
      let totalSize = 0;

      // Filter files based on accepted types
      const filteredFiles = acceptedFiles.filter((file) => {
        const fileType = file.type;
        const acceptedExtensions = validFileTypes[fileType];
        if (!acceptedExtensions) {
          console.warn(`File type not accepted: ${fileType}`); // Log warning for unsupported types
          return false;
        }
        totalSize += file.size;
        return true;
      });

      if (freeSpace && freeSpace < totalSize) {
        errorNoFreeSpaceMessageDisplay();
        clearFiles();
        return;
      }
      else {

        const newFiles = filteredFiles.map((file) => ({
          file,
          progress: 0,
          id: `${file.name}-${file.size}-${file.lastModified}`,
          cancelTokenSource: axios.CancelToken.source(),
          path: "ـ",
          folderId: folderId,
        }));


        addFiles(newFiles); // Add to the upload store

        // Add each file to the download store with initial progress of 0%
        newFiles.forEach((file) => {
          const existingDownload = downloads.find((download) => download.fileName === file.file.name);
          if (!existingDownload) {
            addDownload({ id: file.id, fileName: file.file.name, progress: 0 });
          }
        });

      }

    }
    else {
      errorNoFreeSpaceMessageDisplay();
      clearFiles();
      return;
    }
  };

  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refreshListSharedByFolderContents, folderCurrentPage: sharedByFolderCurrentPage } = useRefreshListSharedByFolderContents();
  const { refreshListSharedWithFolderContents, folderCurrentPage: sharedWithFolderCurrentPage } = useRefreshListSharedWithFolderContents();

  let folderList: {
    name: string;
    id: string | null;
    path: string;
  }[] = [];

  const getParentFolderId = (currentPath: string): string | null => {
    // Find the parent folder path by removing the last segment from the current path
    const parentPath = currentPath.split('/').slice(0, -1).join('/');

    // Find the parent folder in the folderList with the parentPath and level one less than currentLevel
    const parentFolder = folderList.find(
      folder => folder.path === parentPath
    );

    // Return the parentFolderId if found, otherwise null
    return parentFolder ? parentFolder.id : null;
  };

  const handleUpload = async () => {
    if (token) {
      try {
        folderList = []; // Reassign to empty array
        const orderedFiles = [];

        // Build a complete list of folder paths
        const folderPaths = new Set<string>();

        for (const file of files) {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 10;
            console.log(`Updating progress for ${file.file.name}: ${progress}%`); // Debugging log
            updateProgress(file.id, progress);

            if (progress >= 100) {
              clearInterval(interval);
            }
          }, 500);
        }


        for (const file of files) {
          const path = file.path;
          if (path && path.includes("/")) {

            // Remove leading `/` if it exists
            const sanitizedPath = path.startsWith("/") ? path.slice(1) : path;
            const pathSegments = sanitizedPath.split("/");

            // Push file info into the array, including depth for sorting
            orderedFiles.push({
              file, // Reference to the original file object
              fullPath: sanitizedPath, // Use sanitizedPath here
              directoryName: pathSegments[pathSegments.length - 2] || "", // Avoid undefined for files directly in root
              depth: pathSegments.length,
            });

            // Add all parent paths to the folderPaths set
            let currentPath = "";
            for (const segment of pathSegments.slice(0, -1)) {
              currentPath += `${segment}/`;
              folderPaths.add(currentPath.slice(0, -1)); // Remove trailing slash
            }

          }
        }

        // Create all parent folders in order of depth
        const createdDirectories = new Map<string, string>(); // Map to track created folder IDs
        const sortedFolderPaths = Array.from(folderPaths).sort((a, b) => a.split("/").length - b.split("/").length);

        for (const folderPath of sortedFolderPaths) {
          const pathSegments = folderPath.split("/");
          const directoryName = pathSegments[pathSegments.length - 1];
          const parentPath = pathSegments.slice(0, -1).join("/");

          if (!createdDirectories.has(folderPath)) {

            // if (countSlashes(parentPath) === 0)
            //   setTheSelectedFolderId(null);

            const parentFolderId = createdDirectories.get(parentPath) || null;
            const folderId = await createFolder(directoryName, folderPath, "Home");
            createdDirectories.set(folderPath, folderId || "");
          }
        }


        // if (orderedFiles.length > 0) {
        //   // Sort orderedFiles by directoryName and depth
        //   orderedFiles.sort((a, b) => {
        //     // Extract the directory path up to the last folder
        //     const parentA = a.fullPath.substring(0, a.fullPath.lastIndexOf('/'));
        //     const parentB = b.fullPath.substring(0, b.fullPath.lastIndexOf('/'));

        //     // Compare by parent directory first
        //     if (parentA < parentB) return -1;
        //     if (parentA > parentB) return 1;

        //     // If parent directories are the same, compare by depth
        //     if (a.depth !== b.depth) return a.depth - b.depth;

        //     // Finally, compare by fullPath (optional)
        //     return a.fullPath.localeCompare(b.fullPath);
        //   });


        //   // Maintain a map to track created directories
        //   const createdDirectories = new Map(); // Use a map to track folderId for each path

        //   for (const { file, fullPath } of orderedFiles) {
        //     const pathSegments = fullPath.split("/");
        //     const fileName = pathSegments.pop(); // Get the file name
        //     const parentPath = pathSegments.join("/"); // Get the correct parent directory path

        //     if (parentPath && !createdDirectories.has(parentPath)) {
        //       // Only create directories if they aren't tracked
        //       const directoryName = pathSegments[pathSegments.length - 1] || ""; // Get the immediate parent folder name
        //       const folderId = await createFolder(directoryName, parentPath, "Home"); // Create folder and get its ID
        //       createdDirectories.set(parentPath, folderId); // Track the folderId for the path
        //     }

        //     // Update file.folderId with the folderId of its parent directory
        //     if (createdDirectories.has(parentPath)) {
        //       file.folderId = createdDirectories.get(parentPath);
        //     }
        //   }
        // }


        // Process files and assign them to their parent folders
        for (const { file, fullPath } of orderedFiles) {
          const parentPath = fullPath.substring(0, fullPath.lastIndexOf("/"));

          if (createdDirectories.has(parentPath)) {
            file.folderId = createdDirectories.get(parentPath) || null; // Default to null if undefined
          }

        }

        await uploadFiles(token, description);

        // setSuccessMessage(t('UploadSuccessful'));
        if (files.length >= 0) {
          if (folderId) {
            await refreshListFolderContents(folderCurrentPage);
          } else {
            await listAllFoldersFiles(1, totalItems);
          }
        }


      } catch (error) {
        console.error("Upload failed or fetch folders failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };

  const countSlashes = (filePath: string) => {
    return (filePath.split('/').length - 1);
  };

  const createFolder = useCallback(async (directoryName: string, directoryPath: string, tab: string) => {

    let currentParentFolderId = getParentFolderId(directoryPath);

    if (!currentParentFolderId && countSlashes(directoryPath) === 0)
      if (tab === "Home" && homeFolderId)
        currentParentFolderId = homeFolderId;
      else if (tab === "SharedBy" && sharedByFolderId)
        currentParentFolderId = sharedByFolderId;
      else if (tab === "SharedWith" && sharedWithFolderId)
        currentParentFolderId = sharedWithFolderId;

    setFolderName(directoryName);
    const folderId = await handleUploadNewFolder(currentParentFolderId);
    if (folderId) {

      if (tab === "Home")
        setHomeFolderId(folderId);
      else if (tab === "SharedBy")
        setSharedByFolderId(folderId);
      else if (tab === "SharedWith")
        setSharedWithFolderId(folderId);

      folderList.push({
        name: directoryName,
        id: folderId,
        path: directoryPath,
      });

      // setTheSelectedFolderId(folderId);

      return folderId;

    }
  },
    [, handleUploadNewFolder, setHomeFolderId, setSharedByFolderId, setSharedWithFolderId, setFolderName, setTheSelectedFolderId]
  );


  const handleSharedWithUpload = async () => {
    if (token) {
      try {

        folderList = []; // Reassign to empty array
        const orderedFiles = [];

        // Build a complete list of folder paths
        const folderPaths = new Set<string>();

        for (const file of files) {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 10;
            console.log(`Updating progress for ${file.file.name}: ${progress}%`); // Debugging log
            updateProgress(file.id, progress);

            if (progress >= 100) {
              clearInterval(interval);
            }
          }, 500);
        }

        for (const file of files) {
          const path = file.path;
          if (path && path.includes("/")) {

            // Remove leading `/` if it exists
            const sanitizedPath = path.startsWith("/") ? path.slice(1) : path;
            const pathSegments = sanitizedPath.split("/");

            // Push file info into the array, including depth for sorting
            orderedFiles.push({
              file, // Reference to the original file object
              fullPath: sanitizedPath, // Use sanitizedPath here
              directoryName: pathSegments[pathSegments.length - 2] || "", // Avoid undefined for files directly in root
              depth: pathSegments.length,
            });

            // Add all parent paths to the folderPaths set
            let currentPath = "";
            for (const segment of pathSegments.slice(0, -1)) {
              currentPath += `${segment}/`;
              folderPaths.add(currentPath.slice(0, -1)); // Remove trailing slash
            }


          }
        }

        // Create all parent folders in order of depth
        const createdDirectories = new Map<string, string>(); // Map to track created folder IDs
        const sortedFolderPaths = Array.from(folderPaths).sort((a, b) => a.split("/").length - b.split("/").length);

        for (const folderPath of sortedFolderPaths) {
          const pathSegments = folderPath.split("/");
          const directoryName = pathSegments[pathSegments.length - 1];
          const parentPath = pathSegments.slice(0, -1).join("/");

          if (!createdDirectories.has(folderPath)) {

            // if (countSlashes(parentPath) === 0)
            //   setTheSelectedFolderId(null);

            const parentFolderId = createdDirectories.get(parentPath) || null;
            const folderId = await createFolder(directoryName, folderPath, "SharedWith");
            createdDirectories.set(folderPath, folderId || "");
          }
        }


        // if (orderedFiles.length > 0) {
        //   // Sort by depth (shallowest first)
        //   orderedFiles.sort((a, b) => {
        //     // Extract the directory path up to the last folder
        //     const parentA = a.fullPath.substring(0, a.fullPath.lastIndexOf('/'));
        //     const parentB = b.fullPath.substring(0, b.fullPath.lastIndexOf('/'));

        //     // Compare by parent directory first
        //     if (parentA < parentB) return -1;
        //     if (parentA > parentB) return 1;

        //     // If parent directories are the same, compare by depth
        //     if (a.depth !== b.depth) return a.depth - b.depth;

        //     // Finally, compare by fullPath (optional)
        //     return a.fullPath.localeCompare(b.fullPath);
        //   });

        //   // Maintain a map to track created directories
        //   const createdDirectories = new Map(); // Use a map to track folderId for each path

        //   for (const { file, fullPath } of orderedFiles) {
        //     const pathSegments = fullPath.split("/");
        //     const fileName = pathSegments.pop(); // Get the file name
        //     const parentPath = pathSegments.join("/"); // Get the correct parent directory path

        //     if (parentPath && !createdDirectories.has(parentPath)) {
        //       // Only create directories if they aren't tracked
        //       const directoryName = pathSegments[pathSegments.length - 1] || ""; // Get the immediate parent folder name

        //       const folderId = await createFolder(directoryName, parentPath, "SharedWith"); // Create folder and get its ID
        //       createdDirectories.set(parentPath, folderId); // Track the folderId for the path
        //     }

        //     // Update file.folderId with the folderId of its parent directory
        //     if (createdDirectories.has(parentPath)) {
        //       file.folderId = createdDirectories.get(parentPath);
        //     }
        //   }
        // }

        // Process files and assign them to their parent folders
        for (const { file, fullPath } of orderedFiles) {
          const parentPath = fullPath.substring(0, fullPath.lastIndexOf("/"));

          if (createdDirectories.has(parentPath)) {
            file.folderId = createdDirectories.get(parentPath) || null; // Default to null if undefined
          }

        }

        await uploadSharedWithFiles(token, description);
        // setSuccessMessage(t('UploadSuccessful'));


        await refreshListSharedWithFolderContents(sharedWithFolderCurrentPage);

      } catch (error) {
        console.error("Upload failed or fetch shared with files failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };

  const handleSharedByUpload = async () => {
    if (token) {
      try {
        folderList = []; // Reassign to empty array
        const orderedFiles = [];

        // Build a complete list of folder paths
        const folderPaths = new Set<string>();

        for (const file of files) {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 10;
            console.log(`Updating progress for ${file.file.name}: ${progress}%`); // Debugging log
            updateProgress(file.id, progress);

            if (progress >= 100) {
              clearInterval(interval);
            }
          }, 500);
        }

        for (const file of files) {
          const path = file.path;
          if (path && path.includes("/")) {

            // Remove leading `/` if it exists
            const sanitizedPath = path.startsWith("/") ? path.slice(1) : path;
            const pathSegments = sanitizedPath.split("/");

            // Push file info into the array, including depth for sorting
            orderedFiles.push({
              file, // Reference to the original file object
              fullPath: sanitizedPath, // Use sanitizedPath here
              directoryName: pathSegments[pathSegments.length - 2] || "", // Avoid undefined for files directly in root
              depth: pathSegments.length,
            });

            // Add all parent paths to the folderPaths set
            let currentPath = "";
            for (const segment of pathSegments.slice(0, -1)) {
              currentPath += `${segment}/`;
              folderPaths.add(currentPath.slice(0, -1)); // Remove trailing slash
            }

          }
        }

        // Create all parent folders in order of depth
        const createdDirectories = new Map<string, string>(); // Map to track created folder IDs
        const sortedFolderPaths = Array.from(folderPaths).sort((a, b) => a.split("/").length - b.split("/").length);

        for (const folderPath of sortedFolderPaths) {
          const pathSegments = folderPath.split("/");
          const directoryName = pathSegments[pathSegments.length - 1];
          const parentPath = pathSegments.slice(0, -1).join("/");

          if (!createdDirectories.has(folderPath)) {
            // if (countSlashes(parentPath) === 0)
            //   setTheSelectedFolderId(null);

            const parentFolderId = createdDirectories.get(parentPath) || null;
            const folderId = await createFolder(directoryName, folderPath, "SharedBy");
            createdDirectories.set(folderPath, folderId || "");
          }
        }

        // if (orderedFiles.length > 0) {
        //   // Sort by depth (shallowest first)
        //   orderedFiles.sort((a, b) => {
        //     // Extract the directory path up to the last folder
        //     const parentA = a.fullPath.substring(0, a.fullPath.lastIndexOf('/'));
        //     const parentB = b.fullPath.substring(0, b.fullPath.lastIndexOf('/'));

        //     // Compare by parent directory first
        //     if (parentA < parentB) return -1;
        //     if (parentA > parentB) return 1;

        //     // If parent directories are the same, compare by depth
        //     if (a.depth !== b.depth) return a.depth - b.depth;

        //     // Finally, compare by fullPath (optional)
        //     return a.fullPath.localeCompare(b.fullPath);
        //   });

        //   // Maintain a map to track created directories
        //   const createdDirectories = new Map(); // Use a map to track folderId for each path

        //   for (const { file, fullPath } of orderedFiles) {
        //     const pathSegments = fullPath.split("/");
        //     const fileName = pathSegments.pop(); // Get the file name
        //     const parentPath = pathSegments.join("/"); // Get the correct parent directory path

        //     if (parentPath && !createdDirectories.has(parentPath)) {
        //       // Only create directories if they aren't tracked
        //       const directoryName = pathSegments[pathSegments.length - 1] || ""; // Get the immediate parent folder name
        //       const folderId = await createFolder(directoryName, parentPath, "SharedBy"); // Create folder and get its ID
        //       createdDirectories.set(parentPath, folderId); // Track the folderId for the path
        //     }

        //     // Update file.folderId with the folderId of its parent directory
        //     if (createdDirectories.has(parentPath)) {
        //       file.folderId = createdDirectories.get(parentPath);
        //     }
        //   }
        // }

        // Process files and assign them to their parent folders
        for (const { file, fullPath } of orderedFiles) {
          const parentPath = fullPath.substring(0, fullPath.lastIndexOf("/"));

          if (createdDirectories.has(parentPath)) {
            file.folderId = createdDirectories.get(parentPath) || null; // Default to null if undefined
          }

        }


        await uploadSharedByFiles(token, description);
        // setSuccessMessage(t('UploadSuccessful'));

        await refreshListFolderContents(folderCurrentPage);
        // await refreshListSharedByFolderContents(sharedByFolderCurrentPage);

      } catch (error) {
        console.error("Upload failed or fetch shared by files failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };


  const handleCancelUpload = (id: string) => {
    removeFile(id);
  };

  return {
    files,
    uploading,
    description,
    setDescription,
    successMessage,
    errorMessage,
    acceptedFileTypes,
    onDrop,
    onDropContent,
    handleUpload,
    handleSharedWithUpload,
    handleSharedByUpload,
    handleCancelUpload,
    uploadResponseStatus,
    errorNoFreeSpaceMessage,
    validFileTypes
  };
};
