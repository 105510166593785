import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFolderStore from "src/store/api/useFolderStore";
import useShareStore from "src/store/api/useShareStore";
 import useRefreshTrash from "src/hooks/useRefreshTrash";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";
import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';
import useRefreshListSharedByFolderContents from "src/hooks/useRefreshListSharedByFolderAllContents";
import useRefreshListSharedWithFolderContents from "src/hooks/useRefreshListSharedWithFolderAllContents";
import useRefreshListAllSharedWithMe from "src/hooks/useRefreshListAllSharedWithMe";
import useFavoriteStore from "src/store/api/useFavoriteStore";

interface DeleteFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  folderId: string;
  folderType: string;
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({ isOpen, onClose, folderId , folderType}) => {
  const { t } = useTranslation();
  const { deleteFolder } = useFolderStore();
  const { deleteSharedFolder } = useShareStore();
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refreshListAllShareWithUser, shareWithCurrentPage, totalSharedWithItems } = useRefreshListAllSharedWithMe();
   const { fetchTrashedItems,  currentPage: TrashCurrentPage, totalItems: TrashTotalItems } = useRefreshTrash();
  const { refreshListSharedByFolderContents, folderCurrentPage: sharedByFolderCurrentPage } = useRefreshListSharedByFolderContents();
  const { refreshListSharedWithFolderContents, folderCurrentPage: sharedWithFolderCurrentPage } = useRefreshListSharedWithFolderContents();
  const { page, rowsPerPage, reftreshFetchFavoritesWithoutDirectory } = useFavoriteStore();

  const handleDelete = async () => {

    if (folderType !== "sharedWithFolder"){
      await deleteFolder(folderId);
      await refreshListFolderContents(folderCurrentPage);
      await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
      await refreshListSharedByFolderContents(sharedByFolderCurrentPage);
     }
    else  {
      await deleteSharedFolder(folderId);
      await refreshListAllShareWithUser(shareWithCurrentPage, totalSharedWithItems);
      await refreshListSharedWithFolderContents(sharedWithFolderCurrentPage);
    }

    await fetchTrashedItems(TrashCurrentPage, TrashTotalItems);
    await reftreshFetchFavoritesWithoutDirectory(page, rowsPerPage);

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classNames={{}}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("DeleteFolder")}
        </ModalHeader>
        <ModalBody>
          <form className="flex flex-col gap-4">
            <h2>
              {t('AreYouSureToDelete')}
            </h2>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleDelete}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFolderModal;