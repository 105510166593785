//src/hooks/useListFolderContents.ts
import { useEffect } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';

const useListSharedByFolderContents = () => {

  const { selectedFolderId, listSharedByFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, setTheSelectedFolderId, folderRowsPerPage, clearTheSelectedFolderId } = useFolderStore((state) => ({
    selectedFolderId: state.selectedFolderId,
    listSharedByFolderContents: state.listSharedByFolderContents,
    isFetchingFolderContent: state.isFetchingFolderContent,
    errorFetchingFolderContent: state.errorFetchingFolderContent,
    responseMessageFolderContent: state.responseMessageFolderContent,
    responseStatusFolderContent: state.responseStatusFolderContent,
    folderContents: state.folderContents,
    folderCurrentPage: state.folderCurrentPage,
    folderTotalPages: state.folderTotalPages,
    folderTotalItems: state.folderTotalItems,
    setTheSelectedFolderId: state.setTheSelectedFolderId,
    folderRowsPerPage: state.folderRowsPerPage,
    clearTheSelectedFolderId: state.clearTheSelectedFolderId,
  }));

  useEffect(() => {
     if (selectedFolderId) {
      listSharedByFolderContents(folderCurrentPage);  // Only fetch folders if selectedItemId is not null
   }
  }, [folderCurrentPage, listSharedByFolderContents, selectedFolderId]);  // Add selectedItemId as a dependency

  return { listSharedByFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, selectedFolderId, setTheSelectedFolderId, folderRowsPerPage, clearTheSelectedFolderId };
};

export default useListSharedByFolderContents;
