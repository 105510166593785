//src/hooks/useListFolderContents.ts
import { useEffect } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';

const useListFolderContents = () => {

  const { selectedFolderId, listFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, setTheSelectedFolderId, clearTheSelectedFolderId } = useFolderStore((state) => ({
    selectedFolderId: state.selectedFolderId,
    listFolderContents: state.listFolderContents,
    isFetchingFolderContent: state.isFetchingFolderContent,
    errorFetchingFolderContent: state.errorFetchingFolderContent,
    responseMessageFolderContent: state.responseMessageFolderContent,
    responseStatusFolderContent: state.responseStatusFolderContent,
    folderContents: state.folderContents,
    folderCurrentPage: state.folderCurrentPage,
    folderTotalPages: state.folderTotalPages,
    folderTotalItems: state.folderTotalItems,
    setTheSelectedFolderId: state.setTheSelectedFolderId,
    clearTheSelectedFolderId: state.clearTheSelectedFolderId,
  }));

  useEffect(() => {
     if (selectedFolderId) {
      console.log("useListFolderContents hook call" );
      listFolderContents(folderCurrentPage);  // Only fetch folders if selectedItemId is not null
   }
  }, [folderCurrentPage, listFolderContents, selectedFolderId]);  // Add selectedItemId as a dependency

  return { listFolderContents, isFetchingFolderContent, errorFetchingFolderContent, responseMessageFolderContent, responseStatusFolderContent, folderContents, folderCurrentPage, folderTotalPages, folderTotalItems, selectedFolderId, setTheSelectedFolderId, clearTheSelectedFolderId };
};

export default useListFolderContents;
