import { create } from 'zustand';
import { useAuthStore } from '../api/useAuthStore';
import { useFolderContentStore } from 'src/components/main_page/cards/FolderCard';

import { deleteFileApi, updateFile } from 'src/services/api/API';
import { useSharedWithFolderStore } from 'src/components/shared_files/cards/SharedWithMeFolderCard';
import { useSharedByFolderStore } from 'src/components/shared_files/cards/SharedByMeFolderCard';
import { usePublicFolderStore } from 'src/components/public_access_page/cards/PublicFolderCard';
import useUserResourceStore from '../api/useUserResourceStore';
import { useAlertStore } from 'src/components/common/CustomAlert';

interface StoreState {
  page: number;
  rowsPerPage: number;
  files: File[];
  totalPages: number;
  error: string | null;
  filterValue: string;
  selectedKeys: Set<string>;
  statusFilter: Set<string>;
  permissions: string | null;
  deleteResponseStatus: 'success' | 'error' | null,

  isUpdating: boolean;
  errorUpdating: string | null;
  updateResponseMessage : string | null;
  updateResponseStatus: 'success' | 'error' | null;

  setFilterValue: (value: string) => void;
  setStatusFilter: (value: Set<string>) => void;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  // fetchFiles: () => Promise<void>;
  // fetchSharedWithFiles: () => Promise<void>;
  // fetchSharedByFiles: () => Promise<void>;
  // fetchPublicSharedFiles: () => Promise<void>;
  deleteFile: (fileId: string, fileType: string) => Promise<void>;
  setSelectedKeys: (keys: Set<string>) => void;
  setDeleteResponseStatus: (status: 'success' | 'error' | null) => void;
  setUpdateResponseStatus: (status: 'success' | 'error' | null) => void;

  updateFile: (fileId: string, updatedData: any) => Promise<void>;


}

export interface ListFiles {
  files?: File[];
  totalPages?: number;
  totalFiles?: number;
  currentPage?: number;
}

export interface File {
  type?: string;
  file_id?: string;
  user_id?: number;
  folder_id?: string;
  directory_id?: string;
  file_name?: string;
  file_path?: string;
  file_size?: number;
  file_category?: string;
  is_trashed?: number;
  is_trashed_soft?: number;
  date_trashed_soft?: null | string;
  date_trashed?: null;
  created_at?: string;
  updated_at?: string;
  s3_url?: string;
  s3_key?: string;
  s3_bucket?: string;
  mime_type?: string;
  last_accessed?: null;
  checksum?: string;
  year?: number;
  month?: number;
  file_type?: string;
  original_filename?: string;
  description?: null;
  download_count?: number;
  view_count?: number;
  s3_redirect_url?: null;
  backup_s3_url?: null;
  backup_s3_key?: null;
  backup_s3_bucket?: null;
  backup_s3_redirect_url?: null;
  thumbnail_s3_key?: string | null;
  thumbnail_s3_url?: string | null;
  thumbnail_s3_redirect_url?: string | null;
  backup_thumbnail_s3_key?: string | null;
  backup_thumbnail_s3_url?: string | null;
  backup_thumbnail_s3_redirect_url?: string | null;
  is_favorite?: number;
  full_name_en?: string | '';
  full_name_ar?: string | '';
}


const useFilesStore = create<StoreState>((set, get) => ({
  files: [],
  totalPages: 1,
  filterValue: '',
  selectedKeys: new Set<string>(),
  statusFilter: new Set<string>(),
  rowsPerPage: 10,
  page: 1,
  error: null,
  permissions: null,
  deleteResponseStatus: null,

  isUpdating: false,
  errorUpdating: null,
  updateResponseMessage: null,
  updateResponseStatus: null,

  setDeleteResponseStatus: (status) => set({ deleteResponseStatus: status }),
  setUpdateResponseStatus: (status) => set({ updateResponseMessage: status }),

  setFilterValue: (value: string) => set({ filterValue: value }),
  setStatusFilter: (value: Set<string>) => set({ statusFilter: value }),
  setRowsPerPage: (value: number) => set({ rowsPerPage: value }),
  setPage: (value: number) => set({ page: value }),

  // Implement setSelectedKeys
  setSelectedKeys: (keys: Set<string>) => set({ selectedKeys: keys }),

  // fetchFiles: async () => {
  //   console.log('fetchFiles call');
  //   const token = useAuthStore.getState().token;
  //   const { page, rowsPerPage } = get();
  //   const folderId = useFolderContentStore.getState().folderId;
  //   try {

  //     const response = await fetchFilesApi(folderId || '', page, rowsPerPage, token || '');
  //     set({
  //       files: response.files || [],
  //       totalPages: response.totalPages || 1,
  //     });
  //   } catch (error) {
  //     console.error('Error fetching files:', error);
  //     set({ error: 'Error fetching files' });
  //   }
  // },

  // fetchSharedWithFiles: async () => {
  //   const token = useAuthStore.getState().token;
  //   const { page, rowsPerPage } = get();
  //   const folderId = useSharedWithFolderStore.getState().folderId;
  //   const folderPermissions= useSharedWithFolderStore.getState().folderPermissions;
  //   console.log('folderPermissions: '+folderPermissions);
  //     try {
  //     const response = await fetchFilesApi(folderId || '', page, rowsPerPage, token || '');
  //     set({
  //       files: response.files || [],
  //       totalPages: response.totalPages || 1,
  //       permissions: folderPermissions,
  //     });
  //   } catch (error) {
  //     console.error('Error fetching files:', error);
  //     set({ error: 'Error fetching files' });
  //   }
  // },

  // fetchSharedByFiles: async () => {
  //   const token = useAuthStore.getState().token;
  //   const { page, rowsPerPage } = get();
  //   const folderId = useSharedByFolderStore.getState().folderId;
  //    try {
  //     const response = await fetchFilesApi(folderId || '', page, rowsPerPage, token || '');
  //     set({
  //       files: response.files || [],
  //       totalPages: response.totalPages || 1,
  //     });
  //   } catch (error) {
  //     console.error('Error fetching files:', error);
  //     set({ error: 'Error fetching files' });
  //   }
  // },


  deleteFile: async (fileId: string, fileType: string) => {
    const token = useAuthStore.getState().token;
    try {
      await deleteFileApi(fileId, token || '');
      // Optionally, you can refetch the files after deletion
      // if (fileType ==='myFile')
      //      get().fetchFiles();
      // else if (fileType ==='sharedByFile')
      //   get().fetchSharedByFiles();
      //   else if (fileType ==='sharedWithFile')
      //       get().fetchSharedWithFiles();
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash
      get().setDeleteResponseStatus(null);

    } catch (error) {
      get().setDeleteResponseStatus('error');
      console.error('Error deleting file:', error);
      set({ error: 'Error deleting file' });
    }
    finally {

      const status = get().deleteResponseStatus; // Retrieve the latest value of uploadResponseStatus
      if (status && status === "error") {
        useAlertStore.getState().showAlert("ErrorDeletingFile", "error");
      }
    }
  },


  updateFile: async (fileId: string, updatedData: any) => {
    set({ isUpdating: true, errorUpdating: null, updateResponseMessage: '', updateResponseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await updateFile(fileId, updatedData, token);
      set({ updateResponseMessage: result.message, updateResponseStatus: 'success', isUpdating: false });
      // await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorUpdating: error.message, updateResponseMessage: error.message, updateResponseStatus: 'error', isUpdating: false });
    }
  },



  //   fetchPublicSharedFiles: async () => {
  //     const { page, rowsPerPage } = get();
  //     const folderId = usePublicFolderStore.getState().folderId;
  //     try {
  //  console.log("fetchPublicSharedFiles");
  //       const response = await fetchPublicFilesApi(folderId || '', page, rowsPerPage);
  //       set({
  //         files: response.files || [],
  //         totalPages: response.totalPages || 1,
  //       });
  //     } catch (error) {
  //       console.error('Error fetching files:', error);
  //       set({ error: 'Error fetching files' });
  //     }
  //   },
}));

export default useFilesStore;

interface DownloadStoreState {
  downloads: { id: string; name: string; progress: number }[];
  successMessage: string | null;
  errorMessage: string | null;
  addDownload: (download: { id: string; name: string; progress: number }) => void;
  removeDownload: (id: string) => void;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
}

export const useDownloadStore = create<DownloadStoreState>(set => ({
  downloads: [],
  successMessage: null,
  errorMessage: null,
  addDownload: (download) => set(state => ({
    downloads: [...state.downloads, download]
  })),
  removeDownload: (id) => set(state => ({
    downloads: state.downloads.filter(download => download.id !== id)
  })),
  setSuccessMessage: (message) => set({ successMessage: message }),
  setErrorMessage: (message) => set({ errorMessage: message }),
}));
