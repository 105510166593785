import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import MainFileIcon from "../../assets/icons/MainFileIcon";
import { Item } from "src/types/api/ListFolderFiles";
import { sharedItem } from "src/types/api/Share";

interface FileDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileDetails: Item | sharedItem ;
}

const FileDetailsModal: React.FC<FileDetailsModalProps> = ({ isOpen, onClose, fileDetails }) => {
  const { t } = useTranslation();


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-end justify-start">
            <MainFileIcon height={30} width={30} />
            {t('FileDetails')}
          </div>
        </ModalHeader>

        <ModalBody>
          {fileDetails && (
            <Table isStriped aria-label="Example static collection table">
              <TableHeader>
                <TableColumn>{t('Type')}</TableColumn>
                <TableColumn>{fileDetails.file_type}</TableColumn>
              </TableHeader>

              <TableBody>
                <TableRow key="1">
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{fileDetails.original_filename}</TableCell>
                </TableRow>

                <TableRow key="2">
                  <TableCell>{t('Size')}</TableCell>
                  <TableCell>{fileDetails.file_size} {t('MB')} </TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell>{t('Owner')}</TableCell>
                  <TableCell>{fileDetails.owner}</TableCell>
                </TableRow>

                <TableRow key="4">
                  <TableCell>{t('Description')}</TableCell>
                  <TableCell>{fileDetails.description}</TableCell>
                </TableRow>

                <TableRow key="5">
                  <TableCell>{t('SharedWith')}</TableCell>
                  <TableCell>{fileDetails.share_with_usernames}</TableCell>
                </TableRow>

                <TableRow key="6">
                  <TableCell>{t('Modified')}</TableCell>
                  <TableCell>{fileDetails.updated_at}</TableCell>
                </TableRow>

                <TableRow key="7">
                  <TableCell>{t('Created')}</TableCell>
                  <TableCell>{fileDetails.created_at}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          {/* <Button color="primary" onPress={onClose}>
            {t("Save")}
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileDetailsModal;