import React, { useEffect } from "react";
import { Alert as AntdAlert } from "antd";
import { create } from "zustand";
// src/store/AlertStore.ts
import { useTranslation } from "react-i18next";

// src/store/AlertStore.ts

interface AlertState {
  isVisible: boolean;
  messageKey: string;
  type: "success" | "info" | "warning" | "error";
  onCloseCallback?: () => void;
  showAlert: (
    messageKey: string,
    type: "success" | "info" | "warning" | "error",
    onCloseCallback?: () => void
  ) => void;
  hideAlert: () => void;
}

export const useAlertStore = create<AlertState>((set) => ({
  isVisible: false,
  messageKey: "",
  type: "info",
  onCloseCallback: undefined,
  showAlert: (messageKey, type, onCloseCallback) =>
    set({ isVisible: true, messageKey, type, onCloseCallback }),
  hideAlert: () =>
    set({ isVisible: false, onCloseCallback: undefined }), // Reset onCloseCallback when hiding the alert
}));


const CustomAlert: React.FC = () => {
  const { isVisible, messageKey, type, hideAlert, onCloseCallback } = useAlertStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        hideAlert();
        if (onCloseCallback) onCloseCallback();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, hideAlert, onCloseCallback]);

  if (!isVisible) return null;

  return (
    <AntdAlert
      message={t(messageKey)}
      type={type}
      showIcon
      closable
      className="mb-4 mt-4"
      onClose={() => {
        hideAlert();
        if (onCloseCallback) onCloseCallback();
      }}
    />
  );
};

export default CustomAlert;
