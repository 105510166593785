// src/services/api/API.ts
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ENDPOINTS_DEMO } from '../Endpoints';
import { FetchNotificationsResponse } from 'src/types/api/Notification';
import { ListFolderFiles } from '../../types/api/ListFolderFiles';
import { FetchsharedByUserResponse, FetchsharedWithUserResponse, ListAllSharesResponse } from 'src/types/api/Share';
import { FetchsTrashResponse } from 'src/types/api/Trash';
import { ListFiles } from 'src/store/layout/useFilesStore';
import { ListFavorite, ToggleFavoriteResponse } from 'src/types/api/ListFavorite';
import { Role } from 'src/types/api/ListRole';
import { ListUsers, ListUsersRegister, LoginRequest, LoginResponse, RegisterResponse } from 'src/types/api/UserList';
import { useAuthStore } from 'src/store/api/useAuthStore';
import { ResponsCreateRole, RoleList } from 'src/store/api/useRoleStore';
import { ResponsSignIn } from 'src/store/api/useSignInStore';
import { PermissionsUsers } from 'src/pages/LoginSaff';

const handleApiError = (error: AxiosError) => {
  // Access the clearToken function

  if (error.response?.status === 401) {
    const clearToken = useAuthStore.getState().clearToken;
    console.error("Unauthorized: Token is invalid or expired. Clearing token.");
    clearToken(); // Clear the token if unauthorized
    return "Unauthorized: Token is invalid or expired";
  }

  const message = (error.response?.data as { error?: string })?.error || error.message;
  console.error(message);
  return message;
};

export default handleApiError;


export const registerUser = async (data: ListUsersRegister, token: string): Promise<ListUsersRegister> => {
  try {
    console.log("registerUser called with data:", data);
    const response = await axios.post<RegisterResponse>(ENDPOINTS_DEMO.REGISTER_USER, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Pass the token as a Bearer token in the headers
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};


export const loginUser = async (data: LoginRequest): Promise<LoginResponse> => {
  try {
    console.log("loginUser called");
    const response = await axios.post<LoginResponse>(ENDPOINTS_DEMO.LOGIN_USER, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};

export const toggleFileFavorite = async (fileId: string, token: string): Promise<ToggleFavoriteResponse> => {
  try {
    console.log("toggleFileFavorite called");
    const response = await axios.post<ToggleFavoriteResponse>(ENDPOINTS_DEMO.TOGGLE_FILE_FAVORITE(fileId), {}, {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};

export const toggleFolderFavorite = async (folderId: string, token: string): Promise<ToggleFavoriteResponse> => {
  try {
    console.log("toggleFolderFavorite called");
    const response = await axios.post<ToggleFavoriteResponse>(ENDPOINTS_DEMO.TOGGLE_FOLDER_FAVORITE(folderId), {}, {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};

export const createFolder = async (name: string, directoryId: string, parentFolderId: string, token: string): Promise<any> => {
  try {
    console.log("createFolder called", directoryId);

    console.log("createFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.CREATE_FOLDER(directoryId, parentFolderId),
      { name },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error: any) {
    const message = error.response?.data?.message || 'Failed to create folder';
    throw new Error(message);
  }
};

export const deleteExpiredFolders = async (token: string) => {
  try {
    console.log("deleteExpiredFolders called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_EXPIRED_FOLDERS, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};

export const listAllFolders = async (token: string) => {
  try {
    console.log("listAllFolders called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_ALL_FOLDERS, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error as AxiosError));
  }
};

export const listAllFoldersFiles = async (token: string, selectedItemId: string, page: number, rowsPerPage: number): Promise<ListFolderFiles> => {
  try {
     console.log("listAllFoldersFiles called");
    console.log("token", token);
 
    const response = await axios.get<ListFolderFiles>(ENDPOINTS_DEMO.LIST_ALL_FOLDERS_FILES(selectedItemId), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });

    console.log('token: '+token);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch folders and files');
  }
};


export const copyFolder = async (folderId: string, params: { directory_id: string; parent_folder_id: string }, token: string) => {
  try {
    console.log("copyFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.COPY_FOLDER(folderId),
      params,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to copy folder: ${handleApiError(error as AxiosError)}`);
  }
};

export const restoreFolder = async (folderId: string, token: string) => {
  try {
    console.log("restoreFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.RESTORE_FOLDER(folderId),
      {},
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to restore folder: ${handleApiError(error as AxiosError)}`);
  }
};

// export const getFolderDetails = async (folderId: string, token: string) => {
//   try {
//     console.log("getFolderDetails called ");
//     const response = await axios.get(ENDPOINTS_DEMO.GET_FOLDER_DETAILS(folderId), {
//       headers: { 'Authorization': `Bearer ${token}` },
//     });
//     return response.data;
//   } catch (error) {
//     throw new Error(`Failed to fetch folder details: ${handleApiError(error as AxiosError)}`);
//   }
// };

export const updateFolder = async (folderId: string, updatedData: any, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(
      ENDPOINTS_DEMO.UPDATE_FOLDER(folderId),
      updatedData,
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error updating folder: ${handleApiError(error as AxiosError)}`);
  }
};


export const uploadFolder = async (data: { parent_folder_id: string; name: string; directory_id: string; files: File[] }, token: string) => {
  try {
    console.log("updateFolder called");
    const formData = new FormData();
    formData.append('parent_folder_id', data.parent_folder_id.toString());
    formData.append('name', data.name);
    formData.append('directory_id', data.directory_id.toString());
    data.files.forEach(file => formData.append('files', file));

    const response = await axios.post(ENDPOINTS_DEMO.UPLOAD_FOLDER, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to upload files: ${handleApiError(error as AxiosError)}`);
  }
};

export const downloadFolder = async (folderId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(`${ENDPOINTS_DEMO.DOWNLOAD_FOLDER(folderId)}`, {
      headers: { 'Authorization': `Bearer ${token}` },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to download file: ${handleApiError(error as AxiosError)}`);
  }
}

export const listAllDirectories = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_ALL_DIRECTORIES, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list all directories: ${handleApiError(error as AxiosError)}`);
  }
};

export const listTrashDirectories = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_TRASH_DIRECTORIES, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list all trashed directories: ${handleApiError(error as AxiosError)}`);
  }
};

export const getDirectoryDetails = async (directoryId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.GET_DIRECTORY_DETAILS(directoryId), {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch directory details: ${handleApiError(error as AxiosError)}`);
  }
};

export const createDirectory = async (year: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.CREATE_DIRECTORY,
      { year },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create directory: ${handleApiError(error as AxiosError)}`);
  }
};

export const updateDirectory = async (directoryId: string, data: any, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(
      ENDPOINTS_DEMO.UPDATE_DIRECTORY(directoryId),
      data,
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to update directory: ${handleApiError(error as AxiosError)}`);
  }
};


export const deleteDirectory = async (directoryId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_DIRECTORY(directoryId), {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    // Return the success message from the response
    return { message: response.data.message || 'Directory deleted successfully' };
  } catch (error) {
    throw new Error(`Failed to delete directory: ${handleApiError(error as AxiosError)}`);
  }
};

export const trashDirectory = async (directoryId: string, token: string): Promise<{ message: string }> => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.TRASH_DIRECTORY(directoryId),
      {},
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return { message: response.data.message || 'Directory trashed successfully' };
  } catch (error) {
    throw new Error(`Failed to trash directory: ${handleApiError(error as AxiosError)}`);
  }
};

export const restoreDirectory = async (directoryId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.RESTORE_DIRECTORY(directoryId),
      {},
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return { message: response.data.message || 'Directory restored successfully' };
  } catch (error) {
    throw new Error(`Failed to restore directory: ${handleApiError(error as AxiosError)}`);
  }
};


export const emptyTrashDirectory = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.EMPTY_TRASH_DIRECTORY, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error emptying trash: ${handleApiError(error as AxiosError)}`);
  }
};

export const setTrashSoftAllDirectories = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.SET_TRASH_SOFT_ALL_DIRECTORIES,
      {},
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return { message: response.data.message || 'Set trash soft for all directories successfully' };
  } catch (error) {
    throw new Error(`Failed to set soft trash for all directories: ${handleApiError(error as AxiosError)}`);
  }
};
export const deleteAllExpiredDirectories = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_ALL_EXPIRED_DIRECTORIES, {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    });
    return { message: response.data.message || 'Expired directories deleted successfully' };
  } catch (error) {
    throw new Error(`Failed to delete expired directories: ${handleApiError(error as AxiosError)}`);
  }
};


export const listUsersAll = async (token: string, page: number, rowsPerPage: number): Promise<ListUsers> => {
  try {
    console.log("listUsersAll called");
    const response = await axios.get<ListUsers>(ENDPOINTS_DEMO.LIST_USERS, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        // limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list users: ${handleApiError(error as AxiosError)}`);
  }
};
export const listUsersSAFFAll = async (token: string, page: number, rowsPerPage: number): Promise<ListUsers> => {
  try {
    console.log("listUsersAll called");
    const response = await axios.get<ListUsers>(ENDPOINTS_DEMO.LIST_USERS_SAFF, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        // limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list users: ${handleApiError(error as AxiosError)}`);
  }
};


export const listAllNotifications = async (token: string): Promise<FetchNotificationsResponse> => {
  try {
    console.log("updateFolder called");
    const response = await axios.get<FetchNotificationsResponse>(ENDPOINTS_DEMO.LIST_ALL_NOTIFICATIONS, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list notifications: ${handleApiError(error as AxiosError)}`);
  }
};


export const listLastThreeNotifications = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_LAST_THREE_NOTIFICATIONS, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list last three notifications: ${handleApiError(error as AxiosError)}`);
  }
};

export const deleteAllNotifications = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_ALL_NOTIFICATIONS, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to delete all notifications: ${handleApiError(error as AxiosError)}`);
  }
};

export const deleteNotification = async (notificationId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_NOTIFICATION(notificationId), {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to delete notification: ${handleApiError(error as AxiosError)}`);
  }
};

export const markNotificationAsRead = async (notificationId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(ENDPOINTS_DEMO.MARK_NOTIFICATION_READ(notificationId), {}, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to mark notification as read: ${handleApiError(error as AxiosError)}`);
  }
};

export const markAllNotificationsAsRead = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(ENDPOINTS_DEMO.MARK_ALL_NOTIFICATIONS_READ, {}, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to mark all notifications as read: ${handleApiError(error as AxiosError)}`);
  }
};


export const listAllShares = async (type: 'folder' | 'file', id: string, token: string): Promise<ListAllSharesResponse> => {
  try {
    console.log("listAllShares called");
    const response = await axios.get<ListAllSharesResponse>(ENDPOINTS_DEMO.LIST_ALL_SHARES(type, id), {
      headers: { 'Authorization': `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch all shares: ${handleApiError(error as AxiosError)}`);
  }
};



export const fetchSharedFoldersByUser = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_SHARED_FOLDERS_BY_USER, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`error fetching shared folders: ${handleApiError(error as AxiosError)}`);
  }
};

export const fetchSharedFoldersWithUser = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_SHARED_FOLDERS_With_USER, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`error fetching shared folders: ${handleApiError(error as AxiosError)}`);
  }
};

export const fetchSharedFilesByUser = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_SHARED_FILES_BY_USER, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`error fetching shared folders: ${handleApiError(error as AxiosError)}`);
  }
};

export const fetchSharedFilesWithUser = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.get(ENDPOINTS_DEMO.LIST_SHARED_FILES_With_USER, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`error fetching shared folders: ${handleApiError(error as AxiosError)}`);
  }
};


export const createShare = async (
  resourceType: 'folder' | 'file',
  resourceId: string,
  shareData: Array<{ shared_with_user_id: string; share_type: string; permissions: string; expiration_date?: string | null }>,
  token: string
) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.CREATE_SHARE(resourceType, resourceId),
      shareData,
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data; // Assuming the API returns the created share details
  } catch (error) {
    throw new Error(`Failed to create share: ${handleApiError(error as AxiosError)}`);
  }
};

export const updateShare = async (
  resourceType: 'folder' | 'file',
  resourceId: string,
  shareData: Array<{ shared_with_user_id: string; share_type: string; permissions: string; expiration_date?: string | null }>,
  token: string
) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(
      ENDPOINTS_DEMO.UPDATE_SHARE(resourceType, resourceId),
      shareData,
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data; // Assuming the API returns the updated share details
  } catch (error) {
    throw new Error(`Failed to update share: ${handleApiError(error as AxiosError)}`);
  }
};

export const saveShare = async (
  resourceType: 'folder' | 'file',
  resourceId: string,
  shareData: Array<{ shared_with_user_id?: string ; share_type: string; permissions?: string ; expiration_date?: string | null }>,
  token: string
) => {
  try {
    console.log("saveShare called");
    const response = await axios.put(
      ENDPOINTS_DEMO.SAVE_SHARE(resourceType, resourceId),
      shareData,
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data; // Assuming the API returns the updated share details
  } catch (error) {
    throw new Error(`Failed to save share: ${handleApiError(error as AxiosError)}`);
  }
};

export const deleteShare = async (resourceType: 'folder' | 'file', resourceId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(
      ENDPOINTS_DEMO.DELETE_SHARE(resourceType, resourceId),
      {
        headers: { 'Authorization': `Bearer ${token}` },
      }
    );
    return response.data; // Assuming the API returns a success message
  } catch (error) {
    throw new Error(`Failed to delete share: ${handleApiError(error as AxiosError)}`);
  }
};

export const uploadFiles = (
  file: File,
  description: string,
  token: string,
  selectedItemId: string,
  folderId: string | null,
  onUploadProgress: (progressEvent: any) => void,
  cancelToken: any
): Promise<AxiosResponse> => {
  console.log("uploadFiles called");
  const formData = new FormData();
  formData.append("file[]", file);
  formData.append("description", description);

  const endpoint = folderId
    ? ENDPOINTS_DEMO.UPLOAD_FILES(selectedItemId, folderId)
    : ENDPOINTS_DEMO.UPLOAD_FILES_WITHOUT_FOLDER(selectedItemId);

    const response = axios.post(endpoint, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken,
    onUploadProgress,
  });

  return response; // Assuming the API returns a success message

};


export const createFolderModel = async (folderName: string, token: string): Promise<void> => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      `${ENDPOINTS_DEMO.CREATE_FOLDER_MODEL}`,
      { name: folderName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error creating folder model: ${handleApiError(error as AxiosError)}`);
  }
};

export const fetchFiles = async (page: number, rowsPerPage: number, token: string) => {
  try {
    const response = await axios.get(ENDPOINTS_DEMO.FETCH_FILES, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        rowsPerPage,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching files: ${handleApiError(error as AxiosError)}`);
  }
};

// export const fetchFilesApi = async (folderId: string, page: number, rowsPerPage: number, token: string): Promise<ListFiles> => {
//   try {
//     console.log("fetchFilesApi called");
//     const response = await axios.get<ListFiles>(ENDPOINTS_DEMO.LIST_FILES(folderId), {
//       headers: { Authorization: `Bearer ${token}` },
//       params: { page, rowsPerPage }
//     });
//     // console.log("response fetchFilesApi", response);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Error fetching files: ${handleApiError(error as AxiosError)}`);
//   }
// };

// export const fetchPublicFilesApi = async (folderId: string, page: number, rowsPerPage: number): Promise<ListFiles> => {
//   try {
//     console.log("fetchPublicFilesApi called");
//     const response = await axios.get<ListFiles>(ENDPOINTS_DEMO.LIST_PUBLIC_FILES(folderId), {
//       params: { page, rowsPerPage }
//     });
//     console.log("response fetchPublicFilesApi", response);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Error fetching files: ${handleApiError(error as AxiosError)}`);
//   }
// };


export const deleteFolderApi = async (folderId: string, token: string): Promise<void> => {
  try {
    console.log("updateFolder called");
    await axios.post(ENDPOINTS_DEMO.TRASH_FOLDER(folderId), {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error(`Error deleting folder: ${handleApiError(error as AxiosError)}`);
  }
};

export const deleteFileApi = async (fileId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.TRASH_FILE(fileId),
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error deleting file: ${handleApiError(error as AxiosError)}`);
  }
};

export const listAllShareWithUser = async (token: string, selectedItemId: string, page: number, rowsPerPage: number): Promise<FetchsharedWithUserResponse> => {
  try {
    console.log("listAllShareWithUser called");
    const response = await axios.get<FetchsharedWithUserResponse>(ENDPOINTS_DEMO.LIST_ALL_SHARED_WITH_USER(selectedItemId), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch shared folders and files: ${handleApiError(error as AxiosError)}`);
  }
};

export const listAllShareByUser = async (token: string, selectedItemId: string, page: number, rowsPerPage: number): Promise<FetchsharedByUserResponse> => {
  try {
    console.log("listAllShareByUser called");
    const response = await axios.get<FetchsharedByUserResponse>(ENDPOINTS_DEMO.LIST_ALL_SHARED_BY_USER(selectedItemId), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch shared folders and files: ${handleApiError(error as AxiosError)}`);
  }
};


export const getPublicShareLink = async (resourceType: 'folder' | 'file', resourceId: string, expiration_date: string | null, token: string): Promise<string> => {
  try {
    console.log("getPublicShareLink called");

    // Prepare query parameters
    const params = new URLSearchParams();
    if (expiration_date) {
      params.append('expiration_date', expiration_date);  // Add expiration_date to query parameters
    }

    // Use axios.get with query params in the URL
    const response = await axios.get(ENDPOINTS_DEMO.GET_PUBLIC_SHARE_LINK(resourceType, resourceId), {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: params,  // Pass the query parameters
    });

    return response.data;
  } catch (error) {
    throw new Error(`Failed to create and get public share: ${handleApiError(error as AxiosError)}`);
  }
};



export const fetchFavoritesWithoutDirectory = async (token: string, page: number, perPage: number): Promise<ListFavorite> => {
  try {
    console.log("updateFolder called");
    const response = await axios.get<ListFavorite>(ENDPOINTS_DEMO.LIST_FAVORITES_WITHOUT_DIRECTORY(), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, perPage },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch favorites: ${handleApiError(error as AxiosError)}`);
  }
};
export const fetchRoles = async (token: string): Promise<RoleList> => {
  try {
    console.log("fetchRoles called");
    const response = await axios.get<RoleList>(ENDPOINTS_DEMO.LIST_ALL_ROLES(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch roles: ${handleApiError(error as AxiosError)}`);
  }
};
export const deleteRole = async (token: string, roleId: string): Promise<AxiosResponse> => {
  try {
    console.log("updateFolder called");
    const response = await axios.delete(ENDPOINTS_DEMO.DELETE_ROLE(roleId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(`Failed to delete role with ID: ${roleId}`, error);
    throw new Error(`Failed to delete role: ${handleApiError(error as AxiosError)}`);
  }
};
export const updateRole = async (token: string, roleId: string, updatedData: Partial<Role>): Promise<ResponsCreateRole> => {
  try {
    console.log("updateFolder called"); 
    const response = await axios.put<ResponsCreateRole>(
      ENDPOINTS_DEMO.UPDATE_ROLE(roleId),
      updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to update role: ${handleApiError(error as AxiosError)}`);
  }
};
export const createRole = async (token: string, roleData: Partial<Role>): Promise<ResponsCreateRole> => {
  console.log(`createRole called for roleData: `, roleData);
  try {
    const response = await axios.post<ResponsCreateRole>(ENDPOINTS_DEMO.CREATE_ROLE(), roleData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("reateRole called for roleData response.data", response.data)
    return response.data; // Return the data from the response
  } catch (error) {
    throw new Error(`Failed to create role: ${handleApiError(error as AxiosError)}`);
  }
};


export const listAllTrashedItems = async (token: string, page: number, rowsPerPage: number): Promise<FetchsTrashResponse> => {
  try {
    console.log("listAllTrashedItems called");
    const response = await axios.get<FetchsTrashResponse>(ENDPOINTS_DEMO.LIST_ALL_TREASH, {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch trashed folders and files');
  }
};


export const listAllNotificationsItems = async (token: string, selectedItemId: string, page: number, rowsPerPage: number): Promise<FetchNotificationsResponse> => {
  try {
    console.log("listAllNotificationsItems called");
    const response = await axios.get<FetchNotificationsResponse>(ENDPOINTS_DEMO.LIST_ALL_NOTIFICATIONS_ITEMS(selectedItemId), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch trashed folders and files');
  }
};

export const restoreFile = async (fileId: string, token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post(
      ENDPOINTS_DEMO.RESTORE_FILE(fileId),
      {},
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to restore file: ${handleApiError(error as AxiosError)}`);
  }
};


export const emptyTrashFile = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(ENDPOINTS_DEMO.EMPTY_TRASH_FILE, {}, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error emptying trash files: ${handleApiError(error as AxiosError)}`);
  }
};

export const emptyTrashFolder = async (token: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.put(ENDPOINTS_DEMO.EMPTY_TRASH_FOLDER, {}, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error emptying trash folders: ${handleApiError(error as AxiosError)}`);
  }
};

export const signIn = async (phoneNumber: string, password: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post<ResponsSignIn>(ENDPOINTS_DEMO.SIGN_IN, {
      id: phoneNumber,
      password,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Error signing in: ${handleApiError(error as AxiosError)}`);
  }
};

export const verifyGoogleAuth = async (otp: string, tempToken: string) => {
  try {
    console.log("updateFolder called");
    const response = await axios.post<PermissionsUsers>(ENDPOINTS_DEMO.VERIFY_GOOGLE_AUTH, {
      otp,
    }, {
      headers: {
        'Authorization': `Bearer ${tempToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error verifying Google Auth: ${handleApiError(error as AxiosError)}`);
  }
};
// export const getFileDetails = async (fileId: string, token: string) => {
//   try {
//     console.log("getFileDetails called");
//     const response = await axios.get(ENDPOINTS_DEMO.GET_FILE_DETAILS(fileId), {
//       headers: { 'Authorization': `Bearer ${token}` },
//     });
//     return response.data;
//   } catch (error) {
//     throw new Error(`Failed to fetch file details: ${handleApiError(error as AxiosError)}`);
//   }
// };

export const updateUser = async (userId: string, data: any, token: string): Promise<ResponsCreateRole> => {
  try {
    const response = await axios.put<ResponsCreateRole>(ENDPOINTS_DEMO.UPDATE_USER(userId), data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw new Error(`Failed to update user: ${handleApiError(error as AxiosError)}`);
  }
};
export const fetchPublicShare = async (shareId: string) => {
  try {
    // console.log("listPublicShare called with shareId:", shareId);
    const response = await axios.get(`${ENDPOINTS_DEMO.LIST_PUBLIC_SHARE}?id=${shareId}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch public share: ${handleApiError(error as AxiosError)}`);
  }
};


export const emptyTrash = async (token: string) => {
  try {
    console.log("emptyTrash called");
    const response = await axios.put(ENDPOINTS_DEMO.EMPTY_TREASH, {}, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error emptying trash files: ${handleApiError(error as AxiosError)}`);
  }
}; // Adjust the path according to your project structure

export const assignRolesToUser = async (token: string, userId: string, roles: string[]): Promise<ResponsCreateRole> => {
  try {
    const response = await axios.post<ResponsCreateRole>(ENDPOINTS_DEMO.ASSIGN_ROLES_TO_USER(userId), { roles }, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to assign roles: ${handleApiError(error as AxiosError)}`);
  }
};

export const loginSaffAPI = async (code: string): Promise<PermissionsUsers> => {
  try {
    const encodedCode = encodeURIComponent(code);
    const response = await axios.post<PermissionsUsers>(
      ENDPOINTS_DEMO.LOGIN_MY_SAFF,  { code: encodedCode },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to login: ${handleApiError(error as AxiosError)}`);
  }
};

export const getUserResources = async (token: string): Promise<any> => {
  try {
    console.log("getUserResources called");
    const response = await axios.get(
      ENDPOINTS_DEMO.GET_USER_RESOURCES,
      {
            headers: { 'Authorization': `Bearer ${token}` },
      }
    );
    console.log("getUserResources response", response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch user resources: ${handleApiError(error as AxiosError)}`);
  }
};


// Second Phase -------------------------------------------------------------------------------

// export const fetchFilesApi = async (folderId: string, page: number, rowsPerPage: number, token: string): Promise<ListFiles> => {
//   try {
//     console.log("fetchFilesApi called");
//     const response = await axios.get<ListFiles>(ENDPOINTS_DEMO.LIST_FILES(folderId), {
//       headers: { Authorization: `Bearer ${token}` },
//       params: { page, rowsPerPage }
//     });
//     // console.log("response fetchFilesApi", response);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Error fetching files: ${handleApiError(error as AxiosError)}`);
//   }
// };



export const listFolderContents = async (token: string, folderId: string, page: number, rowsPerPage: number): Promise<ListFolderFiles> => {
  try {
     console.log("listFolderContents API called");
    console.log("token", token);
    const response = await axios.get<ListFolderFiles>(ENDPOINTS_DEMO.LIST_FOLDER_CONTENTS(folderId), {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { page, rowsPerPage }
    });
    console.log("listFolderContents API response");

    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch folder contents: ${handleApiError(error as AxiosError)}`);
  }
};


export const listPublicFolderContents = async (folderId: string, page: number, rowsPerPage: number): Promise<ListFolderFiles> => {
  try {
     console.log("listPublicFolderContents API called");
     const response = await axios.get<ListFolderFiles>(ENDPOINTS_DEMO.LIST_PUBLIC_FOLDER_CONTENTS(folderId), {
       params: { page, rowsPerPage }
    });
    console.log("listPublicFolderContents API response: "+response.data.items);

    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch folder contents: ${handleApiError(error as AxiosError)}`);
  }
};


export const updateFile = async (fileId: string, updatedData: any, token: string) => {
  try {
    console.log("updateFile called");
    const response = await axios.put(
      ENDPOINTS_DEMO.UPDATE_FILE(fileId),
      updatedData,
      {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    console.log("response.data: ", response.data);

    return response.data;

  } catch (error) {
    throw new Error(`Error updating file: ${handleApiError(error as AxiosError)}`);
  }
};