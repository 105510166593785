// src/store/api/useTrashStore.ts
import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import axios from 'axios';
import { TrashState } from 'src/types/api/Trash';
import { emptyTrash, listAllTrashedItems, restoreFile, restoreFolder } from 'src/services/api/API';
import { useSidebarStore } from '../layout/useSidebarStore';
import useUserResourceStore from './useUserResourceStore';

const useTrashStore = create<TrashState>((set, get) => ({
  filterValue: '',
  selectedKeys: new Set([]),
  statusFilter: 'all',
  rowsPerPage: 10,
  sortDescriptor: { column: 'id', direction: 'ascending' },
  page: 1,
  totalPages: 1,
  isLoading: false,
  error: null,
  successMessage: null,
  currentPage: 1,
  totalItems: 0,
  trashedItems: null,
  lastFetchedPage: null,
  trashLastFetchedItemId: null,
  isEmptyingTrash: false,
  errorEmptyingTrash: null,
  responseMessage: null,
  responseStatus: null,
  isRestoring: false,
  errorRestoring: null,
  hasFetched: false,

  setFilterValue: (value: string) => set({ filterValue: value }),
  setSelectedKeys: (keys) => set({ selectedKeys: keys }),
  setStatusFilter: (filter) => set({ statusFilter: filter }),
  setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
  setSortDescriptor: (descriptor) => set({ sortDescriptor: descriptor }),
  setPage: (page) => set({ page: page }),

  listAllTrashedItems: async (page: number, rowsPerPage: number) => {
    const { currentPage, trashLastFetchedItemId, hasFetched } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    if (hasFetched ||  (currentPage === page && trashLastFetchedItemId === selectedItemId)) {
      console.log(`listAllTrashedItems Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }

    await get().fetchTrashedItems(page, rowsPerPage); // Refresh list

  },

  fetchTrashedItems: async (page: number, rowsPerPage: number) => {
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    set({ isLoading: true, error: null, successMessage: null,  hasFetched: true});
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await listAllTrashedItems(token, page, rowsPerPage);
      set({
        currentPage: page,
        totalPages: result.totalPages,
        totalItems: result.totalItems,
        trashedItems: result.trashedItems,
        trashLastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isLoading: false,
      });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }

  },

  emptyTrash: async () => {
    set({ isEmptyingTrash: true, errorEmptyingTrash: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await emptyTrash(token);
      set({ responseMessage: result.message, responseStatus: 'success', isEmptyingTrash: false });
      await get().fetchTrashedItems(get().currentPage, get().totalItems); // Refresh list
      
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash

    } catch (error: any) {
      set({ errorEmptyingTrash: error.message, responseMessage: error.message, responseStatus: 'error', isEmptyingTrash: false });
    }
  },

  restoreFolder: async (folderId: string) => {
    set({ isRestoring: true, errorRestoring: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await restoreFolder(folderId, token);
      set({ responseMessage: result.message, responseStatus: 'success', isRestoring: false });
      await get().fetchTrashedItems(get().currentPage, get().totalItems); // Refresh list
    
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash

    } catch (error: any) {
      set({ errorRestoring: error.message, responseMessage: error.message, responseStatus: 'error', isRestoring: false });
    }
  },

  restoreFile: async (fileId: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await restoreFile(fileId, token);
      set({ successMessage: result.message, isLoading: false });
      await get().fetchTrashedItems(get().currentPage, get().totalItems); // Refresh list
   
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash


    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
  clearHasFetched: () => set({ hasFetched: false }),


}));

export default useTrashStore;
