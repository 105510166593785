import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginSaffAPI } from 'src/services/api/API';
import { useAuthStore } from 'src/store/api/useAuthStore';

const LoginSaff: React.FC = () => {
  const navigate = useNavigate();
  const setToken = useAuthStore((state) => state.setToken);
  const setPermissions = useAuthStore((state) => state.setPermissions);

  const handleLogin = useCallback(async (code: string) => {
    try {
      const data = await loginSaffAPI(code); // Use the refactored API function

      if (data.success && data.token && data.permissions) {
        setToken(data.token);
        setPermissions(data.permissions, data.name_en || '');
        navigate('/homepage');
      } else {
        console.error('Login failed:', data);
        navigate('/500'); // Navigate to internal server error page
      }
    } catch (error) {
      console.error('An error occurred:', error);
      navigate('/500'); // Navigate to internal server error page
    }
  }, [navigate, setToken, setPermissions]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    if (code) {
      handleLogin(code);
    } else {
      navigate('/500'); // Navigate to internal server error page if no code is found
    }
  }, [handleLogin, navigate]);

  return React.createElement('div', null, 'Loading...');
};

export default LoginSaff;


// To parse this data:
//
//   import { Convert } from "./file";
//
//   const permissionsUsers = Convert.toPermissionsUsers(json);

export interface PermissionsUsers {
  success?:     boolean;
  token?:       string;
  name_en?:     string; 
  permissions?: PermissionsUser;
}

export interface PermissionsUser {
  is_saff?:                            boolean;
  create_role?:                        boolean;
  delete_role?:                        boolean;
  edit_role?:                          boolean;
  read_role?:                          boolean;
  users_saff_read?:                    boolean;
  users_saff_edit_role?:               boolean;
  create_users_outside_saff?:          boolean;
  read_users_outside_saff?:            boolean;
  edit_users_outside_saff?:            boolean;
  delete_users_outside_saff?:          boolean;
  share_delete_my_users_with_another?: boolean;
  share_read_my_users_with_another?:   boolean;
  share_edit_my_users_with_another?:   boolean;
  share_create_my_users_with_another?: boolean;
  is_supper_admin?: boolean; 
  is_administrators?: boolean;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPermissionsUsers(json: string): PermissionsUsers[] {
      return JSON.parse(json);
  }

  public static permissionsUsersToJson(value: PermissionsUsers[]): string {
      return JSON.stringify(value);
  }
}
