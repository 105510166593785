import useShareStore from "src/store/api/useShareStore";

// src/hooks/useListAllShares.ts
const useListAllShares = () => {
  const { listAllShares, isLoading, error, successMessage, nonSharedUsers,owner, insideSharedUsers, outsideSharedUsers, outsideSharedLink, expiration_date } = useShareStore((state) => ({
    listAllShares: state.listAllShares,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
    nonSharedUsers: state.nonSharedUsers,
    owner: state.owner,
    insideSharedUsers: state.insideSharedUsers,
    outsideSharedUsers: state.outsideSharedUsers,
    outsideSharedLink: state.outsideSharedLink,
    expiration_date: state.expiration_date,

   }));
// console.log("nonSharedUsers ", nonSharedUsers?.length);
// console.log("insideSharedUsers ", insideSharedUsers?.length);
// console.log("outsideSharedUsers ", outsideSharedUsers);
// console.log("owner ", owner);

  return { listAllShares, isLoading, error, successMessage, nonSharedUsers, owner, insideSharedUsers, outsideSharedUsers, outsideSharedLink, expiration_date };
};

export default useListAllShares;
