
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from '@nextui-org/react';

// icons 
import { VerticalDotsIcon } from '../../../assets/icons/VerticalDotsIcon';
import ViewsIcon from '../../../assets/icons/ViewsIcon';
import HeartIcon from '../../../assets/icons/Hearticon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteFolderModal from 'src/components/modals/DeleteFolderModal';
import EditFolderModal from 'src/components/modals/EditFolderModal';
import ShareFolderModal from 'src/components/modals/ShareFolderModal';
import { sharedItem } from 'src/types/api/Share';
import SharedFolderIcon from '../../../assets/icons/SharedFolderIcon';
import { create } from 'zustand';
import {  useToggleFolderFavorite } from 'src/hooks/useFavoriteHooks';
import FolderDetailsModal from 'src/components/modals/FolderDetailsModal';
import { useListAllDirectories } from 'src/hooks/useListAllDirectories';
import { Item } from 'src/types/api/ListFolderFiles';
import useListSharedByFolderContents from 'src/hooks/useListSharedByFolderContents';
import { useSharedByFolderStore } from './SharedByMeFolderCard';
import useFolderStore from 'src/store/api/useFolderStore';
 
// interface FolderState {
//   folderId: string | null;
//   setFolderId: (id: string) => void;
// }

// export const useSharedByFolderStore = create<FolderState>((set) => ({
//   folderId: null,
//   setFolderId: (id) => set({ folderId: id }),
// }));

interface SharedByMeFolderCardProps {
  folder: Item;
}

const SharedByMeFolderCard2: React.FC<SharedByMeFolderCardProps> = ({ folder }) => {
  const [liked, setLiked] = useState(Number(folder.is_favorite) === 1);
  const { t } = useTranslation();
  const { setFolderId } = useSharedByFolderStore(); // Zustand store to manage selected folder id
  const toggleFolderFavorite = useToggleFolderFavorite(); // Hook to toggle favorite status

  useDisclosure();
  const { isOpen: isFolderDetailsOpen, onOpen: onOpenFolderDetails, onClose: onCloseFolderDetails } = useDisclosure();
  const { isOpen: isEditFolderOpen, onOpen: onOpenEditFolder, onClose: onCloseEditFolder } = useDisclosure();
  const { isOpen: isDeleteFolderOpen, onOpen: onOpenDeleteFolder, onClose: onCloseDeleteFolder } = useDisclosure();
  const { isOpen: isShareFolderOpen, onOpen: onOpenShareFolder, onClose: onCloseShareFolder } = useDisclosure();

  const [, setSelectedFolderId] = useState<string | null>(null);
 
  useEffect(() => {
    setLiked(Number(folder.is_favorite) === 1);
  }, [folder.is_favorite]);

  const handleView = () => {
    onOpenFolderDetails();
  };

  const handleEdit = (folderId: string) => {
    setSelectedFolderId(folderId);
    onOpenEditFolder();
  };

  const handleDelete = () => {
    setFolderId(folder.folder_id || '');
    onOpenDeleteFolder();
  };

  const handleShare = () => {
    onOpenShareFolder();
  };

  const handleSave = (names: string[], roles: string[]) => {
    // Add your save logic here
  };

  const handleCopyLink = () => {
    // Add your copy link logic here
  };

  const { directories } = useListAllDirectories();
  const directory = directories?.find((dir) => dir.directory_id === folder.directory_id);

  // const handleDownload = () => {
  //   console.error('No download URL available');
  //   window.open(`https://www.google.com`, '_blank');
  // };

  const handleToggleLiked = async () => {
    try {
      setLiked((prev) => !prev); // Optimistic UI update
      await toggleFolderFavorite(folder.folder_id || ''); // API call to toggle favorite status
     } catch (error) {
      console.error('Failed to toggle favorite:', error);
      setLiked((prev) => !prev); // Revert UI if the API call fails
    }
  };

  const {  setTheSelectedFolderId} = useListSharedByFolderContents();
  const { pushFolder} = useFolderStore();

  const handleFolderClick = () => {
    const id = folder.folder_id || '';
    console.log('Folder ID:', id); // Display the folder_id in the console
    setFolderId(id); // Set the folderId state
    setTheSelectedFolderId(id);
    pushFolder(folder.folder_id|| "", folder.name || "");
  };

  // Current year
  const currentYear = new Date().getFullYear();
  
  // Extract year from directory name
  const directoryYear = directory?.name?.match(/\d{4}/)?.[0] ?? null;
  const isCurrentYear = directoryYear === currentYear.toString();

  return (
    <Card className="lg:w-[15%] w-[20rem] hover:scale-105" key={folder.folder_id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t('ViewDetails')}</DropdownItem>
            <DropdownItem onClick={() => handleEdit(folder.folder_id || '')}>{t('EditFolder')}</DropdownItem>
            {/* <DropdownItem onClick={() => handleDownload()}>{t('View')}</DropdownItem> */}
            <DropdownItem onClick={handleShare}>{t('Share')}</DropdownItem>
            <DropdownItem color="danger" onClick={handleDelete}>{t('Delete')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Button
          isIconOnly
          className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
          radius="full"
          variant="light"
          onPress={handleToggleLiked}
        >
          <HeartIcon
            className={liked ? "[&>path]:stroke-transparent" : ""}
            fill={liked ? "red" : "none"}
          />
        </Button>
      </CardHeader>

      <Link to="/sharedByFileList" onClick={handleFolderClick}>
        <CardBody className="px-3 py-0 text-small text-center text-default-400">
          <div className="flex justify-center items-center">
            <SharedFolderIcon height={65} width={65} />
          </div>
          <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
          {folder.name && folder.name.length >= 40
                  ? `${folder.name.slice(0, 40)}.....`
                  : folder.name || ""}
          </span>
          <span className={`pt-2 capitalize ${isCurrentYear ? 'text-success' : ''}`}>
            {directory ? directory.name : folder.directory_id}
          </span>
        </CardBody>

        <CardFooter className="gap-3 w-full">
          <div className="flex justify-between w-full text-gray-400">
            <div className="pt-2 capitalize">
              {/* <div className="flex justify-center items-start">
                <DownloadIcon color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.download_count || 0}</span>
              </div> */}
            </div>
            <div className="pt-2 capitalize">
              <div className="flex justify-center items-start">
                <ViewsIcon height="20" color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.view_count || 0}</span>
              </div>
            </div>
          </div>
        </CardFooter>

      </Link>

      <FolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderDetails={folder || ''} />
      <EditFolderModal folderId={folder.folder_id || ''} folderName={folder.name || ''} isOpen={isEditFolderOpen} onClose={onCloseEditFolder} />
      <DeleteFolderModal isOpen={isDeleteFolderOpen} onClose={onCloseDeleteFolder} folderId={folder.folder_id || ''} folderType="sharedByFolder" />
      <ShareFolderModal onSave={handleSave} onCopyLink={handleCopyLink} isOpen={isShareFolderOpen} onClose={onCloseShareFolder} resourceId={folder.folder_id || ''} resourceName={folder.name || ''} resourceType="folder" />
    
    </Card>
  );
};

export default SharedByMeFolderCard2;
