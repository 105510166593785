import { create } from 'zustand';

interface SidebarState {
  open: boolean;                 // State for basic open/close (legacy)
  isOpen: boolean;               // State for sidebar open/close
  layout: 'grid' | 'list';       // State to manage layout
  sidebarExpanded: boolean;      // State for sidebar expansion
  selectedItem: string;          // State for selected item
  selectedItemId: string;        // State for selected item ID
  isModalOpen: boolean;          // State for modal open/close
  isRTL: boolean;                // State for RTL (Right-to-left) support
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;        // Toggle for basic open/close (legacy)
  toggleSidebar: () => void;     // Toggle sidebar open/close
  toggleLayout: () => void;      // Toggle layout between grid and list
  toggleSidebarExpanded: () => void; // Toggle sidebar expansion
  setSelectedItem: (item: string) => void;
  setSelectedItemId: (id: string) => void;
  setIsModalOpen: (open: boolean) => void;
  toggleRTL: () => void;         // Toggle RTL support
}

export const useSidebarStore = create<SidebarState>((set) => ({
  open: false,                    // Initial state for basic open/close (legacy)
  isOpen: false,                  // Initial state for sidebar open/close
  layout: 'grid',                 // Default layout
  sidebarExpanded: localStorage.getItem("sidebar-expanded") === "true", // Load from localStorage
  selectedItem: "",               // No item selected by default
  selectedItemId: "",             // No selected item ID by default
  isModalOpen: false,             // Modal closed by default
  isRTL: document.documentElement.dir === 'rtl', // Set based on the document's dir attribute

  // Set basic open/close state (legacy)
  setOpen: (open) => set({ open }),

  // Toggle basic open/close state (legacy)
  toggleOpen: () => set((state) => ({ open: !state.open })),

  // Set sidebar open/close state
  toggleSidebar: () => set((state) => ({ isOpen: !state.isOpen })),

  // Toggle layout between 'list' and 'grid'
  toggleLayout: () => set((state) => ({
    layout: state.layout === 'list' ? 'grid' : 'list',
  })),

  // Toggle sidebar expansion and store the state in localStorage
  toggleSidebarExpanded: () => set((state) => {
    const expanded = !state.sidebarExpanded;
    localStorage.setItem("sidebar-expanded", expanded.toString());
    return { sidebarExpanded: expanded };
  }),

  // Set the selected item
  setSelectedItem: (item) => set({ selectedItem: item }),

  // Set the selected item ID
  setSelectedItemId: (id) => set({ selectedItemId: id }),

  // Set modal open/close state
  setIsModalOpen: (open) => set({ isModalOpen: open }),

  // Toggle RTL (right-to-left) support and update document direction
  toggleRTL: () => set((state) => {
    const newRTL = !state.isRTL;
    document.documentElement.dir = newRTL ? 'rtl' : 'ltr';
    return { isRTL: newRTL };
  }),
}));
