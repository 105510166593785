import { useEffect, useState } from "react";
import useUserResourceStore from "src/store/api/useUserResourceStore";


// Custom hook to fetch user resources
export const useUserResources = () => {
  const { userResources, getUserResources, isLoading, errorUserResourceState, hasFetched, showStorageAlert, clearHasFetched } = useUserResourceStore();
  const [freeSpace, setFreeSpace] = useState<number | null>(null);  // Initial state is null or 0

  const getResources = async () => {
    if (!hasFetched) 
      await getUserResources();
  };
  
  // Set the free space when userResources changes
  useEffect(() => {
    if (hasFetched && userResources) {
      const { storage_limit = 0, consumed_storage = 0, trash_storage = 0 } = userResources;

      // Calculate total used storage and free space
      const total_used_storage = consumed_storage + trash_storage;
      const free_space = storage_limit - total_used_storage;

      // Update the free space state
      setFreeSpace(free_space);
    }
  }, [userResources]);  // Re-run when userResources changes

  
  return {
    userResources,
    getResources,
    isLoading,
    errorUserResourceState,
    hasFetched,
    showStorageAlert,
    freeSpace,
    clearHasFetched
  };
};
