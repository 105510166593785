// src/pages/Files.js
import React, { useEffect, useState } from "react";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem, useDisclosure } from "@nextui-org/react";
import LayoutSwitcher from "../components/switchers/LayoutSwitcher";
import NavBar from "src/components/header/NarBar";
import { useSidebarStore } from "src/store/layout/useSidebarStore";
import SharedWithViewerGridList from "src/components/shared_files_layout/SharedWithViewerGridList";
import SharedWithViewerTableList from "src/components/shared_files_layout/SharedWithViewerTableList";
import SharedWithEditorGridList from "src/components/shared_files_layout/SharedWithEditorGridList";
import SharedWithEditorTableList from "src/components/shared_files_layout/SharedWithEditorTableList";
import DownloadList from "src/components/common/DownloadList";
import useListSharedWithFolderContents from "src/hooks/useListSharedWithFolderContents";
import useFolderStore from "src/store/api/useFolderStore";
import { useSharedWithFolderStore } from "src/components/shared_files/cards/SharedWithMeFolderCard";
import { useNavigate } from "react-router-dom";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import { Alert } from "antd";
import CustomAlert from "src/components/common/CustomAlert";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import useShareStore from "src/store/api/useShareStore";
import useFilesStore from "src/store/layout/useFilesStore";
import useListAllTrash from "src/hooks/useListAllTrash";
import EmptyTrashModal from "src/components/modals/EmptyTrashModal";
import useTrashFilesStore from "src/store/layout/useTrashFilesGridListStoreLayout";

const SharedWithFiles = () => {
  const { t } = useTranslation();
  const { layout } = useSidebarStore();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { permissions, setTheSelectedFolderId, clearTheSelectedFolderId } = useListSharedWithFolderContents();
  const { selectedFolderStack, popFolderByIndex, emptyFolderStack } = useFolderStore();
  const { setFolderId, setDirectoryId, setFolderPermissions, clearFolderId } = useSharedWithFolderStore();
  const navigate = useNavigate();
  const { userResources, showStorageAlert } = useUserResources();
  const [errorExceelStorageMessage, setErrorExceelStorageMessage] = useState<string>("");
  const { uploadResponseStatus } = useUploadFilesHooks(); // Use the custom hook
  const { deleteResponseStatus: deleteFolderResponseStatus } = useShareStore();
  const { deleteResponseStatus: deleteFileResponseStatus } = useFilesStore();
  const { isOpen: isEmptyTrashOpen, onOpen: onOpenEmptyTrashOpen, onClose: onCloseEmptyTrashOpen } = useDisclosure();
   const { listAllTrashedItems, totalItems } = useListAllTrash();
  const { rowsPerPage,page} = useTrashFilesStore();


  // Retrieve the last folder name if the stack is not empty
  const folderName =
    selectedFolderStack && selectedFolderStack.length > 0
      ? selectedFolderStack[selectedFolderStack.length - 1].folder_name
      : "";


  const handleBreadcrumbClick = (folder: any, index: number) => {
    setFolderId(folder.folder_id); // Set the folderId state
    setDirectoryId(folder.directory_id || "");
    setFolderPermissions(permissions || "viewer");
    setTheSelectedFolderId(folder.folder_id);
    popFolderByIndex(index);
    navigate("/sharedWithFileList");
  };

  const handleMainBreadcrumbClick = () => {
    clearFolderId(); // Set the folderId state
    clearTheSelectedFolderId();
    emptyFolderStack();
    navigate("/Shared");
  };

  const renderContent = () => {
    if (permissions === "editor") {
      return (
        <>
           {/* Alert Message */}
           {showStorageAlert && errorExceelStorageMessage && (
            <Alert
              className="bg-[#4b65bc2a] border-[#4b65bc2a] my-5 text-red-600 font-bold capitalize-first-letter"
              message={
                <span>
                  {errorExceelStorageMessage}{" "}
                  {totalItems > 0 && (
                    <a
                      href="#"
                      onClick={() => {
                        // Logic to open an empty modal or perform other actions
                        onOpenEmptyTrashOpen();
                      }}
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {t("EmptyTrash")}
                    </a>
                  )}
                </span>
              }
            />
          )}
         
          {uploadResponseStatus && (uploadResponseStatus === "error" || uploadResponseStatus === "success") && (
            // {uploadResponseStatus && uploadResponseStatus === "success" && (
            <CustomAlert />
          )}

          {deleteFolderResponseStatus && deleteFolderResponseStatus === "error" && (
             <CustomAlert />
          )}

          {deleteFileResponseStatus && deleteFileResponseStatus === "error" && (
             <CustomAlert />
          )}

          {layout === "grid" ? <SharedWithEditorGridList /> : <SharedWithEditorTableList />}
        </>
      );
    } else {
      return layout === "grid" ? <SharedWithViewerGridList /> : <SharedWithViewerTableList />;
    }
  };

  useEffect(() => {

    if (userResources?.can_upload ?? false)
      setErrorExceelStorageMessage(t('UsedStorage90Persent'));
    else
      setErrorExceelStorageMessage(t('ExceedAllowedStorage'));

    let folderId = useSharedWithFolderStore.getState().folderId; // Fetching latest folderId
    const subfolderId = useFolderStore.getState().selectedFolderId;

    if (folderId === null && subfolderId === null) {
      navigate("/Shared");
    }

    listAllTrashedItems(page, rowsPerPage);

  }, [navigate, userResources, deleteFolderResponseStatus, deleteFileResponseStatus, uploadResponseStatus, listAllTrashedItems]);


  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 p-4 overflow-x-hidden">
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Files")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <Breadcrumbs>
            <BreadcrumbItem>
              <button onClick={() => handleMainBreadcrumbClick()} >
                {t("SharedWithMe")}
              </button>
            </BreadcrumbItem>
            {selectedFolderStack?.map((folder, index) => (
              <BreadcrumbItem key={index}>
                <button onClick={() => handleBreadcrumbClick(folder, index)}>
                  {folder.folder_name}
                </button>
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>


          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5 flex justify-between">
            <div className="font-bold capitalize hover:text-navy-700">
              {folderName || t("Files")}
            </div>
            <LayoutSwitcher />
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          {renderContent()}

          <EmptyTrashModal
            isOpen={isEmptyTrashOpen}
            onClose={onCloseEmptyTrashOpen}
          />
          
        </div>
      </div>
    </div>
  );
};

export default SharedWithFiles;
