import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from "@nextui-org/react";
import HeartIcon from "../../../assets/icons/Hearticon";
import { VerticalDotsIcon } from "../../../assets/icons/VerticalDotsIcon";
import { useTranslation } from "react-i18next";
import { Item } from "src/types/api/ListFolderFiles";
import AiIcon from "../../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../../assets/icons/Files Types/ZipIcon";
import AddNewFolderModal from "src/components/modals/AddNewFolderModal";
import DeleteFileModal from "../../modals/DeleteFileModal";
import FileDetailsModal from "src/components/modals/FileDetailsModal";
import { useToggleFileFavorite } from "src/hooks/useFavoriteHooks";
import ShareFileModal from "src/components/modals/ShareFileModal";
import { useListAllDirectories } from "src/hooks/useListAllDirectories";
import EditFileModal from "src/components/modals/EditFileModal";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={75} width={70} />,
  docx: <DocIcon height={100} width={100} />,
  png: <PngIcon height={20} width={20} />,
  pdf: <PdfIcon height={35} width={100} />,
  txt: <TxtIcon height={75} width={70} />,
  psd: <PsdIcon height={75} width={70} />,
  jpg: <JpgIcon height={20} width={20} />,
  jpeg: <JpegIcon height={20} width={20} />,
  psb: <PsbIcon height={75} width={70} />,
  svg: <SvgIcon height={90} width={110} />,
  mp4: <Mp4Icon height={100} width={100} />,
  avi: <AviIcon height={75} width={70} />,
  mov: <MovIcon height={75} width={70} />,
  webm: <WebmIcon height={75} width={70} />,
  xlsx: <XlsxIcon height={90} width={110} />,
  csv: <CsvIcon height={75} width={70} />,
  pptx: <PptIcon height={75} width={70} />,
  indd: <InddIcon height={75} width={70} />,
  zip: <ZipIcon height={75} width={70} />,
  rar: <RarIcon height={75} width={70} />,
  arj: <ArjIcon height={75} width={70} />,
};

interface FileCardProps {
  file: Item;
}

const FileCard: React.FC<FileCardProps> = ({ file }) => {
  const [liked, setLiked] = useState(Number(file.is_favorite) === 1);
  const { t } = useTranslation();
  const toggleFileFavorite = useToggleFileFavorite();

  const fileIcon = typesIconMap[file.file_type?.toLocaleLowerCase() || ""];
  const { isOpen: isAddNewOpen, onClose: onCloseAddNew } = useDisclosure();
  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();
  const {
    isOpen: isDeleteFileOpen,
    onOpen: onOpenDeleteFile,
    onClose: onCloseDeleteFile,
  } = useDisclosure();
  const {
    isOpen: isShareFileOpen,
    onOpen: onOpenShareFile,
    onClose: onCloseShareFile,
  } = useDisclosure();

  const { isOpen: isEditFileOpen, onOpen: onOpenEditFile, onClose: onCloseEditFile } = useDisclosure();


  const { directories } = useListAllDirectories();
  const directory = directories?.find(
    (dir) => dir.directory_id === file.directory_id
  );

  useEffect(() => {
    setLiked(Number(file.is_favorite) === 1);

  }, [file.is_favorite]);

  const handleToggleLiked = async () => {
    try {
      setLiked((prev) => !prev); // Optimistic UI update
      await toggleFileFavorite(file.file_id || ""); // API call to toggle favorite status
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
      setLiked((prev) => !prev); // Revert UI if API call fails
    }
  };

  const handleView = () => onOpenFileDetails();

  const handleDelete = () => onOpenDeleteFile();

  const handleShare = () => onOpenShareFile();

  const handleSave = (_names: string[]) => {
    // Add your save logic here
  };

  const handleCopyLink = () => {
    // Add your copy link logic here
  };

  const handleDownload = (s3_redirect_url: null | string) => {
    if (!s3_redirect_url) {
      console.error("No download URL available");
      return;
    }
    window.open(s3_redirect_url, "_blank");
  };
  

  // current year
  const currentYear = new Date().getFullYear();

  // Extract year from directory name
  const directoryYear = directory?.name?.match(/\d{4}/)?.[0] ?? null;
  const isCurrentYear = directoryYear === currentYear.toString();

  const specialFileTypes = ["png", "jpg", "jpeg"];
  const isSpecialFileType = specialFileTypes.includes(
    file.file_type?.toLocaleLowerCase() || ""
  );

  const handleEdit = (file: Item ) => {
     onOpenEditFile();
  };

  return (
    <>
      {isSpecialFileType ? (
        <Card
          className="lg:w-[15%] w-[20rem] hover:scale-105"
          key={file.file_id}
        >
          <CardHeader className="justify-between">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly radius="full" size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-400" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={handleView}>
                  {t("ViewDetails")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleDownload(file.s3_redirect_url || "")}
                >
                  {t("View")}
                </DropdownItem>
                <DropdownItem onClick={() => handleEdit(file || '')}>{t('EditFile')}</DropdownItem>
                <DropdownItem onClick={handleShare}>{t("Share")}</DropdownItem>
                <DropdownItem color="danger" onClick={handleDelete}>
                  {t("Delete")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Button
              isIconOnly
              className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
              radius="full"
              variant="light"
              onPress={handleToggleLiked}
            >
              <HeartIcon
                className={liked ? "[&>path]:stroke-transparent" : ""}
                fill={liked ? "red" : "none"} // Ensuring fill turns red when liked
              />
            </Button>
          </CardHeader>
          <CardBody className="px-3 py-0 text-small text-center text-default-400">
            <div className="flex justify-center items-center">
              <div
                className="w-[200px] h-[120px] bg-cover bg-center rounded-xl"
                style={{
                  backgroundImage: `url('${file.thumbnail_s3_url}')`,
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer" // makes it clear the element is clickable
                }}
                onClick={() => handleDownload(file.s3_redirect_url || "")} // Trigger download on click
              ></div>
            </div>

            <span className="pt-2 capitalize text-gray-900 text-xs font-bold flex justify-center items-center">
              {fileIcon} 
              {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
            </span>
            <span
              className={`pt-2 capitalize ${isCurrentYear ? "text-success" : ""}`}
            >
              {directory ? directory.name : file.directory_id}
            </span>
            {/* <span className="pt-2 capitalize">
            {file.description && file.description.length >= 60
                  ? `${file.description.slice(0, 100)}.....`
                  : file.description || ""}
            </span> */}
          </CardBody>

          <CardFooter className="gap-3 w-full"></CardFooter>

          <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />
          <FileDetailsModal
            isOpen={isFileDetailsOpen}
            onClose={onCloseFileDetails}
            fileDetails={file || ""}
          />
          <DeleteFileModal
            isOpen={isDeleteFileOpen}
            onClose={onCloseDeleteFile}
            fileId={file.file_id || ""}
            fileType="myFile"
          />
          <ShareFileModal
            onSave={handleSave}
            onCopyLink={handleCopyLink}
            isOpen={isShareFileOpen}
            onClose={onCloseShareFile}
            resourceId={file.file_id || ""}
            resourceName={file.original_filename || ""}
            resourceType="file"
          />
          <EditFileModal 
          fileDetails={file}  
          isOpen={isEditFileOpen} 
          onClose={onCloseEditFile}
          fileId={file.file_id || ""}
          type = "Home"
            />

        </Card>
      ) : (
        <Card
          className="lg:w-[15%] w-[20rem] hover:scale-105"
          key={file.file_id}
        >
          <CardHeader className="justify-between">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly radius="full" size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-400" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={handleView}>
                  {t("ViewDetails")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleDownload(file.s3_redirect_url || "")}
                >
                  {t("View")}
                </DropdownItem>
                <DropdownItem onClick={() => handleEdit(file || '')}>{t('EditFile')}</DropdownItem>
                <DropdownItem onClick={handleShare}>{t("Share")}</DropdownItem>
                <DropdownItem color="danger" onClick={handleDelete}>
                  {t("Delete")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Button
              isIconOnly
              className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
              radius="full"
              variant="light"
              onPress={handleToggleLiked}
            >
              <HeartIcon
                className={liked ? "[&>path]:stroke-transparent" : ""}
                fill={liked ? "red" : "none"}
              />
            </Button>
          </CardHeader>
          <CardBody className="px-3 py-0 text-small text-center text-default-400">
            <div className="flex justify-center items-center"
             style={{cursor: "pointer" }}
            onClick={() => handleDownload(file.s3_redirect_url || "")} // Trigger download on click
            >{fileIcon}</div>
            <span className="pt-2 capitalize text-gray-900 text-sm font-bold">
            {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
            </span>
            <span
              className={`pt-2 capitalize ${isCurrentYear ? "text-success" : ""}`}
            >
              {directory ? directory.name : file.directory_id}
            </span>
            {/* <span className="pt-2 capitalize">
            {file.description && file.description.length >= 60
                  ? `${file.description.slice(0, 60)}.....`
                  : file.description || ""}
            </span> */}
           </CardBody>

           <CardFooter className="gap-3 w-full"></CardFooter>

          <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />
          <FileDetailsModal
            isOpen={isFileDetailsOpen}
            onClose={onCloseFileDetails}
            fileDetails={file || ""}
          />
          <DeleteFileModal
            isOpen={isDeleteFileOpen}
            onClose={onCloseDeleteFile}
            fileId={file.file_id || ""}
            fileType="myFile"
          />
          <ShareFileModal
            onSave={handleSave}
            onCopyLink={handleCopyLink}
            isOpen={isShareFileOpen}
            onClose={onCloseShareFile}
            resourceId={file.file_id || ""}
            resourceName={file.original_filename || ""}
            resourceType="file"
          />

          <EditFileModal 
          fileDetails={file}  
          isOpen={isEditFileOpen} 
          onClose={onCloseEditFile}
          fileId={file.file_id || ""}
          type = "Home"
            />

        </Card>
      )}
    </>
  );
};

export default FileCard;