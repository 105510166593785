// src/hooks/usePublicShareLink.ts

 import { useCallback } from 'react';
import useShareStore from 'src/store/api/useShareStore';

interface UsePublicShareLinkProps {
  resourceType: 'folder' | 'file';
  resourceId: string;
}

export const usePublicShareLink = ({ resourceType, resourceId }: UsePublicShareLinkProps) => {
  const { publicShareLink, fetchPublicShareLink } = useShareStore();

  // Use useCallback to memoize the function
  const handleFetchPublicShareLink = useCallback(async (expiration_date : string | null) => {
    await fetchPublicShareLink(resourceType, resourceId, expiration_date);
  }, [resourceType, resourceId, fetchPublicShareLink]);

   return { publicShareLink, fetchPublicShareLink: handleFetchPublicShareLink };
};
