import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
  Input,
} from '@nextui-org/react';

// icons 
import { useTranslation } from 'react-i18next';
import { Button, useDisclosure } from '@nextui-org/react';
import { PlusIcon } from '../../assets/icons/PlusIcon';
import AddNewFolderModal from '../modals/AddNewFolderModal';

// modals
import { Alert, Pagination } from 'antd';
import useListSharedByFolderContents from 'src/hooks/useListSharedByFolderContents';
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import SharedByMeFolderCard2 from '../shared_files/cards/SharedByMeFolderCard2';
import SharedByMeFileCard2 from '../shared_files/cards/SharedByMeFileCard2';
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { useUploadFilesHooks } from 'src/hooks/useUploadFilesHooks';
import { useDropzone } from 'react-dropzone';
import { useFolderHooks } from 'src/hooks/useFolderHooks';
import { useUserResources } from 'src/hooks/useUserResourcesHooks';
import { useSharedByFolderStore } from '../shared_files/cards/SharedByMeFolderCard';
import CustomAlert, { useAlertStore } from '../common/CustomAlert';

export default function SharedByGridList() {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
  } = useFilesStore();

  const {
    listSharedByFolderContents,
    isFetchingFolderContent: isShareByLoading,
    folderContents: sharedByUserItems,
    folderCurrentPage: sharedByPage,
    folderTotalPages: shareByTotalPages,
    folderTotalItems: totalSharedByItems,
    folderRowsPerPage: sharedByRowsPerPage,
    setTheSelectedFolderId,
  } = useListSharedByFolderContents();

  const [isDragging, setIsDragging] = useState(false);
  const { onDrop, onDropContent, handleSharedByUpload } = useUploadFilesHooks();
  const { setFolderName, handleUploadNewFolder } = useFolderHooks();
  const [errorNoFreeSpaceMessage, setErrorNoFreeSpaceMessage] = useState<string>("");
  // Calculate storage when userResources is available
  const { userResources, freeSpace } = useUserResources();
   const { setFolderId, folderId: parentFolderId } = useSharedByFolderStore(); // Zustand store to manage selected folder id
   
   let folderList: {
    name: string;
    id: string | null;
    path: string;
    level: number;
  }[] = [];


  const getParentFolderId = (currentPath: string, currentLevel: number): string | null => {
    // Find the parent folder path by removing the last segment from the current path
    const parentPath = currentPath.split('/').slice(0, -1).join('/');

    // Find the parent folder in the folderList with the parentPath and level one less than currentLevel
    const parentFolder = folderList.find(
      folder => folder.path === parentPath && folder.level === currentLevel - 1
    );

    // Return the parentFolderId if found, otherwise null
    return parentFolder ? parentFolder.id : null;
  };


  // useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     onDrop(acceptedFiles);
  //     handleSharedByUpload(); // Automatically start uploading the files after dropping
  //   },
  // });
  
  const errorNoFreeSpaceMessageDisplay = () => {

    const message = `${t('CannotUploadMoreFile')} ${freeSpace} ${t('MB')} ${t('Remaining')}`;
    setErrorNoFreeSpaceMessage(message);
    useAlertStore.getState().showAlert(message, "error");

  };


  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);

      const items = Array.from(event.dataTransfer.items);
      // let totalSize = 0;
      const uploadedFiles = new Map<string | null, File[]>(); // Map to store folderId and associated files
      folderList = []; // Reassign to empty array

      if (freeSpace && freeSpace > 0) {
        for (const item of items) {
          const entry = item.webkitGetAsEntry();
          if (entry) {

            if (entry.isDirectory) {

              // Process the folder to calculate size and collect files
              // const files = await sumTotalSize(entry);
              // files.forEach((file) => {
              //   totalSize += file.size;
              // });

              // Check if there is enough free space
              // if (freeSpace && freeSpace < totalSize) {
              //   errorNoFreeSpaceMessageDisplay();
              //   break;
              // }
              // else {
              // Assuming handleCreateFolder returns the new folder ID
              setFolderName(entry.name);
              const folderId = await handleUploadNewFolder(parentFolderId);
              if (folderId) {
                setFolderId(folderId); // for files
                folderList.push({
                  name: entry.name,
                  id: folderId,
                  path: entry.fullPath,
                  level: 0,
                });

                // setTheSelectedFolderId(folderId); //for folders
                // Add the files from the processed folder to the uploadedFiles map
                const filesMap = await processFolder(entry, folderId, 0, entry.fullPath); // Start at level 0 with root path
                filesMap.forEach((files, folderId) => {
                  if (uploadedFiles.has(folderId)) {
                    uploadedFiles.get(folderId)?.push(...files);
                  } else {
                    uploadedFiles.set(folderId, files);
                  }
                });
              }
              // }

            } else if (item.kind === 'file') {
              const file = item.getAsFile();
              if (file) {
                // totalSize += file.size;
                // if (freeSpace && freeSpace < totalSize) {
                //   errorNoFreeSpaceMessageDisplay();
                //   break;
                // }
                // else
                // Use default folder ID for single file
                if (uploadedFiles.has(null)) {
                  uploadedFiles.get(null)?.push(file);
                } else {
                  uploadedFiles.set(null, [file]);
                }
              }
            }
          }
        }//end loop

        if (uploadedFiles.size > 0) {
          // Log uploaded files to the console

          // if (freeSpace && freeSpace >= totalSize) {
          // Flatten the uploaded files and pass them to the onDrop function

          uploadedFiles.forEach((files, folderId) => onDropContent(files, folderId));
          handleSharedByUpload();
          // } else {
          //   errorNoFreeSpaceMessageDisplay();
          // }
        }
      }
      else
        errorNoFreeSpaceMessageDisplay();
    },
    [onDropContent, handleSharedByUpload, handleUploadNewFolder, setFolderId, setFolderName, setTheSelectedFolderId, freeSpace, errorNoFreeSpaceMessageDisplay]
  );

   // const sumTotalSize = useCallback(
  //   async (entry: any): Promise<File[]> => {
  //     const files: File[] = [];

  //     if (entry.isDirectory) {
  //       const reader = entry.createReader();

  //       const readEntries = (): Promise<any[]> => {
  //         return new Promise((resolve, reject) => {
  //           reader.readEntries(
  //             (entries: any[]) => {
  //               resolve(entries);
  //             },
  //             (error: any) => {
  //               console.error('Error reading entries:', error);
  //               reject(error);
  //             }
  //           );
  //         });
  //       };

  //       let entries = await readEntries();
  //       while (entries.length > 0) {
  //         for (const childEntry of entries) {
  //           if (childEntry.isDirectory) {
  //             const subFiles = await sumTotalSize(childEntry);
  //             files.push(...subFiles);
  //           } else {
  //             const file: File = await new Promise((resolve, reject) => {
  //               childEntry.file((file: File) => {
  //                 if (file) {
  //                   resolve(file);
  //                 } else {
  //                   reject(new Error('Failed to retrieve file.'));
  //                 }
  //               });
  //             });
  //             files.push(file);
  //           }
  //         }
  //         entries = await readEntries();
  //       }
  //     }

  //     const filteredFiles = files.filter((file) => {
  //       const fileType = file.type;
  //       const acceptedExtensions = validFileTypes[fileType];
  //       if (!acceptedExtensions) {
  //         console.warn(`File type not accepted: ${fileType}`); // Log warning for unsupported types
  //         return false;
  //       }
  //        return true;
  //     });

  //     return filteredFiles;
  //   },
  //   []
  // );


  const processFolder = useCallback(
    async (entry: any, parentFolderId: string | null, level: number, parentPath: string): Promise<Map<string | null, File[]>> => {
      const filesMap = new Map<string | null, File[]>(); // Map to store folderId and associated files

      if (entry.isDirectory) {
        const reader = entry.createReader();

        const readEntries = (): Promise<any[]> => {
          return new Promise((resolve, reject) => {
            reader.readEntries(
              (entries: any[]) => {
                resolve(entries);
              },
              (error: any) => {
                console.error('Error reading entries:', error);
                reject(error);
              }
            );
          });
        };

        let entries = await readEntries();

        while (entries.length > 0) {
          for (const childEntry of entries) {
            // console.log(childEntry);
            // console.log("fullPath", entry.fullPath);

            if (childEntry.isDirectory) {
              const currentParentFolderId = getParentFolderId(childEntry.fullPath, level + 1); // Level 0 for the root
              setFolderName(childEntry.name);
              // Assuming handleCreateFolder can take a parentFolderId to create nested folders
              const subFolderId = await handleUploadNewFolder(currentParentFolderId);
              if (subFolderId) {
                setFolderId(subFolderId);
                folderList.push({
                  name: childEntry.name,
                  id: subFolderId,
                  path: childEntry.fullPath,
                  level: level + 1,
                });

                // setTheSelectedFolderId(subFolderId);
                const subFilesMap = await processFolder(childEntry, subFolderId, level + 1, childEntry.fullPath);
                // Add subfolder files to the main map
                subFilesMap.forEach((subFiles, folderId) => {
                  filesMap.set(folderId, subFiles);
                });
              }
            } else {
              const file: File = await new Promise((resolve, reject) => {
                childEntry.file((file: File) => {
                  if (file) {
                    resolve(file);
                  } else {
                    reject(new Error('Failed to retrieve file.'));
                  }
                });
              });
              setFolderId(parentFolderId || "");
              // setTheSelectedFolderId(parentFolderId);

              // Add the file to the map with the parent folder ID
              if (filesMap.has(parentFolderId)) {
                filesMap.get(parentFolderId)?.push(file);
              } else {
                filesMap.set(parentFolderId, [file]);
              }
            }
          }
          entries = await readEntries();
        }
      }

      return filesMap;
    },
    [handleUploadNewFolder, setFolderId, setTheSelectedFolderId]
  );


  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (userResources?.can_upload ?? false)
        setIsDragging(true);
    }, [userResources]);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    let filteredFolders = sharedByUserItems ? [...sharedByUserItems] : [];
    if (hasSearchFilter) {
      filteredFolders = filteredFolders.filter((folder) =>
        folder?.name?.toLowerCase().includes(filterValue.toLowerCase())
      || folder?.original_filename?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return filteredFolders;
  }, [sharedByUserItems, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => {
    return filteredItems; // The API already handles pagination, so no need to slice here
  }, [filteredItems]);

  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );
  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
    listSharedByFolderContents(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, listSharedByFolderContents]);

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, [setFilterValue, setPage]);

  useEffect(() => {
    setPage(1);
    listSharedByFolderContents(1); // Refetch data for the first page
  }, [t, setPage, listSharedByFolderContents]);

  const { isOpen: isAddNewOpen, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4 w-[96.5%]">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: 'lg:w-full sm:max-w-[44%]',
              inputWrapper: 'border-1 bg-white',
            }}
            placeholder={t('SearchByFileName')}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue('')}
            onValueChange={onSearchChange}
          />
          {/* <div className="flex gap-3">
            <SharedByDND />
          </div> */}
          <div className="flex gap-3">
            <Button
              className="bg-success text-background"
              endContent={<PlusIcon />}
              size="sm"
              onClick={onOpenAddNew}
            >
              {t('AddNewSub')}
            </Button>
          </div>
        </div>


        {/* {errorNoFreeSpaceMessage && (
          <CustomAlert />
        )} */}

        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            {isShareByLoading ? t('Loading') : `${t('TotalFiles')}: ${totalSharedByItems}`}
          </span>
          {/* not working yet  */}
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">
              {t('RowsPerPage')}
            </label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={sharedByRowsPerPage}
              onChange={onRowsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>
      </div>
    );
  }, [t, filterValue, onSearchChange, isShareByLoading, onOpenAddNew, totalSharedByItems, sharedByRowsPerPage, onRowsPerPageChange, setFilterValue, errorNoFreeSpaceMessage]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={sharedByPage}
          total={shareByTotalPages * sharedByRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={sharedByRowsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
        {/* <span className="text-small text-default-400">
          {selectedKeys === 'all'
            ? `${t('AllItemsSelected')}`
            : `${selectedKeys.size} ${t('of')} ${paginatedItems.length} ${t('selected')}`}
        </span> */}
      </div>
    );
  }, [sharedByPage, shareByTotalPages, sharedByRowsPerPage, t, handlePageChange, paginatedItems.length, setPage]);

  return (
    <div
      className={`w-full p-4 ${isDragging ? "border-1.5 border-dashed border-success h-full bg-[#2a835f48] rounded-lg" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {topContent}

      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">

        {isShareByLoading ? (
          t('Loading')
        ) : (
          sortedItems.map((item) =>
            item.type === 'folder' ? (
              <SharedByMeFolderCard2 key={item.folder_id} folder={item} />
            ) : (
              <SharedByMeFileCard2 key={item.file_id} file={item} />
            )
          )
        )}

      </div>
      {bottomContent}
      <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />

    </div>
  );
}
