import React, { } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from '@nextui-org/react';
import { VerticalDotsIcon } from '../../../assets/icons/VerticalDotsIcon';
import MainFileIcon from '../../../assets/icons/MainFileIcon';
import ViewsIcon from '../../../assets/icons/ViewsIcon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { sharedItem } from 'src/types/api/Share';
import PublicFolderDetailsModal from 'src/components/modals/PublicFolderDetailsModal';
import useListPublicFolderContents from 'src/hooks/useListPublicFolderContents';
import useFolderStore from "src/store/api/useFolderStore";


interface FolderState {
  folderId: string | null;
  setFolderId: (id: string) => void;
  shareId: string | null;
  setShareId: (id: string) => void;
  clearFolderId: () => void;  // Method to clear folderId
}

export const usePublicFolderStore = create<FolderState>((set) => ({
  folderId: null,
  setFolderId: (id) => set({ folderId: id }),
  shareId: null,
  setShareId: (id) => set({ shareId: id }),
  clearFolderId: () => set({ folderId: null }),  // Implementation to clear folderId
}));

interface FolderCardProps {
  folder: sharedItem;
}

const PublicFolderCard: React.FC<FolderCardProps> = ({ folder }) => {
  const { t } = useTranslation();
  const { setFolderId } = usePublicFolderStore(); // Zustand store to manage selected folder id
  const { setTheSelectedPublicFolderId } = useListPublicFolderContents();
  const {selectedFolderStack, pushFolder} = useFolderStore();

  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();

  const handleView = () => onOpenFolderDetails();

  const handleFolderClick = () => {
     console.log("handleFolderClick folder: ",folder.id );
    console.log("handleFolderClick name: ",folder.name);
    const id = folder.id || '';
    setFolderId(id); // Set the folderId state
    setTheSelectedPublicFolderId(id);
    pushFolder(folder.id, folder.name || "");
    console.log("handleFolderClick selectedFolderStack: ",selectedFolderStack);
  };

  const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);

    // Check for invalid date
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string provided');
    }

    // Get the year, month, and day
    const year: number = date.getFullYear();
    const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day: string = String(date.getDate()).padStart(2, '0');

    // Get the hours and minutes
    let hours: number = date.getHours();
    const minutes: string = String(date.getMinutes()).padStart(2, '0');

    // Determine AM/PM and format hours
    const ampm: string = hours >= 12 ? 'P.M' : 'A.M';
    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // Adjust for midnight

    return `${year}-${month}-${day} ${String(hours).padStart(2, '0')}:${minutes} ${t(ampm)}`;
  };
  return (
    <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={folder.id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t('ViewDetails')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>

      </CardHeader>

      <Link to="/publicSharedFileList" onClick={handleFolderClick}>
        <CardBody className="px-3 py-0 text-small text-center text-default-400">
          <div className="flex justify-center items-center">
            <MainFileIcon height={70} width={70} />
          </div>
          <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
            {folder.name && folder.name.length >= 40
              ? `${folder.name.slice(0, 40)}.....`
              : folder.name || ""}
          </span>
          <span className="pt-2 capitalize">{folder.created_at && formatDateTime(folder.created_at)}</span>
        </CardBody>
        <CardFooter className="gap-3 w-full">
          <div className="flex justify-between w-full text-gray-400">
            <div className="pt-2 capitalize">
            </div>
            <div className="pt-2 capitalize">
              <div className="flex justify-center items-start">
                <ViewsIcon height="20" color="rgb(156 163 175 / var(--tw-text-opacity))" />
                <span>{folder.view_count || 0}</span>
              </div>
            </div>
          </div>
        </CardFooter>
      </Link>
      <PublicFolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderName={folder.name || ''} folderOwner={folder.full_name_en === '' ? folder.full_name_ar : folder.full_name_en || ''} />
    </Card>
  );
};

export default PublicFolderCard;
