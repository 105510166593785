import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Input,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// componenets

// icons
import { SearchIcon } from "../../assets/icons/SearchIcon";
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import { Pagination } from "antd";
import useListPublicFolderContents from "src/hooks/useListPublicFolderContents";
import PublicFolderCard2 from "../public_access_page/cards/PublicFolderCard2";
import PublicFileCard2 from "../public_access_page/cards/PublicFileCard2";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={75} width={70} />,
  docx: <DocIcon height={100} width={100} />,
  png: <PngIcon height={20} width={20} />,
  pdf: <PdfIcon height={40} width={90} />,
  txt: <TxtIcon height={75} width={70} />,
  psd: <PsdIcon height={75} width={70} />,
  jpg: <JpgIcon height={20} width={20} />,
  jpeg: <JpegIcon height={20} width={20} />,
  psb: <PsbIcon height={75} width={70} />,
  svg: <SvgIcon height={90} width={110} />,
  mp4: <Mp4Icon height={100} width={100} />,
  avi: <AviIcon height={75} width={70} />,
  mov: <MovIcon height={75} width={70} />,
  webm: <WebmIcon height={75} width={70} />,
  xlsx: <XlsxIcon height={90} width={110} />,
  csv: <CsvIcon height={75} width={70} />,
  pptx: <PptIcon height={75} width={70} />,
  indd: <InddIcon height={75} width={70} />,
  zip: <ZipIcon height={100} width={90} />,
  rar: <RarIcon height={75} width={70} />,
  arj: <ArjIcon height={75} width={70} />,
};

const PublicSharedGridList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
  } = useFilesStore();

  const {
    listPublicFolderContents,
    isFetchingPublicFolderContent: isLoading,
    publicFolderContents: folderContents,
    publicFolderCurrentPage: currentPage,
    publicFolderTotalPages: totalPage,
    publicFolderTotalItems: totalItems,
    publicFolderRowsPerPage: folderRowsPerPage,
  } = useListPublicFolderContents();


  const hasSearchFilter = Boolean(filterValue);


  const filteredItems = useMemo(() => {
    let filteredFolders = folderContents ? [...folderContents] : [];
    if (hasSearchFilter) {
      filteredFolders = filteredFolders.filter((folder) =>
        folder?.name?.toLowerCase().includes(filterValue.toLowerCase())
      || folder?.original_filename?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return filteredFolders;
  }, [folderContents, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => {
    return filteredItems; // The API already handles pagination, so no need to slice here
  }, [filteredItems]);

  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );
  const handlePageChange = useCallback((newPage: number) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
    listPublicFolderContents(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, listPublicFolderContents]);

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, [setFilterValue, setPage]);

  useEffect(() => {
    setPage(1);
    listPublicFolderContents(1); // Refetch data for the first page
  }, [t, setPage, listPublicFolderContents]);

  const { isOpen: isAddNewOpen, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4 w-[96.5%]">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: 'lg:w-full sm:max-w-[44%]',
              inputWrapper: 'border-1 bg-white',
            }}
            placeholder={t('SearchByFileName')}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue('')}
            onValueChange={onSearchChange}
          />
        </div>

        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            {isLoading ? t('Loading') : `${t('TotalFiles')}: ${totalItems}`}
          </span>
          {/* not working yet  */}
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">
              {t('RowsPerPage')}
            </label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={sharedByRowsPerPage}
              onChange={onRowsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>
      </div>
    );
  }, [t, filterValue, onSearchChange, isLoading, onOpenAddNew, totalItems, folderRowsPerPage, onRowsPerPageChange, setFilterValue]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={currentPage}
          total={totalPage * folderRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={folderRowsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
        {/* <span className="text-small text-default-400">
          {selectedKeys === 'all'
            ? `${t('AllItemsSelected')}`
            : `${selectedKeys.size} ${t('of')} ${paginatedItems.length} ${t('selected')}`}
        </span> */}
      </div>
    );
  }, [currentPage, totalPage, folderRowsPerPage, t, handlePageChange, paginatedItems.length, setPage]);

  return (
    <div className="w-full p-4">
      {topContent}
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">

        {isLoading ? (
          t('Loading')
        ) : (
          sortedItems.map((item) =>
            item.type === 'folder' ? (
              <PublicFolderCard2 key={item.folder_id} folder={item} />
            ) : (
              <PublicFileCard2 key={item.file_id} file={item} />
            )
          )
        )}

      </div>
      {bottomContent}

    </div>
  );
};

export default PublicSharedGridList;
