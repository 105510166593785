import { useEffect, useState } from 'react';
import useFavoriteStore from 'src/store/api/useFavoriteStore';
import { useAuthStore } from 'src/store/api/useAuthStore';
import useRefreshListAllSharedByMe from './useRefreshListAllSharedByMe';
import useRefreshListAllFolders from './useRefreshListAllFolders';
import useRefreshListAllSharedWithMe from './useRefreshListAllSharedWithMe';
import useRefreshListFolderContents from './useRefreshListFolderAllContents';
  
// Custom hook for fetching favorites
export const useFetchFavorites = () => {
  // Step 1: Destructure necessary state and actions from the store
  const { page, rowsPerPage, fetchFavoritesWithoutDirectory, lastFetchedPage } = useFavoriteStore();
  const [loading, setLoading] = useState(false);

  // Step 2: Fetch data whenever the page or rows per page changes
  useEffect(() => {
    // Ensure we only fetch if the page has changed
    if (lastFetchedPage !== page) {
      setLoading(true);
      fetchFavoritesWithoutDirectory(page, rowsPerPage).finally(() => {
        setLoading(false);
      });
    }
  }, [page, rowsPerPage, lastFetchedPage, fetchFavoritesWithoutDirectory]);

  // Step 3: Return the loading state for use in components
  return { loading };
};

// Custom hook for favorite actions
export const useFavoritesActions = () => {
  // Step 1: Destructure necessary actions and state from the store
  const {
    toggleFileFavorite,
    toggleFolderFavorite,
    setFilterValue,
    setSelectedKeys,
    setRowsPerPage,
    setPage,
    page,
    totalPages,
    selectedKeys,
    favorites,
    favorite,
    rowsPerPage
  } = useFavoriteStore();

  // Step 2: Handle changing the number of rows per page
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1); // Reset to the first page on rows per page change
  };

  // Step 3: Handle search input change
  const handleSearchChange = (value: string) => {
    setFilterValue(value || '');
    setPage(1); // Reset to the first page on search change
  };

  // Step 4: Return actions and state needed in components
  return {
    toggleFileFavorite,
    toggleFolderFavorite,
    handleRowsPerPageChange,
    handleSearchChange,
    setSelectedKeys,
    setPage,
    page,
    totalPages,
    selectedKeys,
    favorites,
    favorite,
    rowsPerPage
  };
};

// Custom hook for toggling file as favorite
export const useToggleFileFavorite = () => {
  // Step 1: Destructure the necessary action from the store
  const { toggleFileFavorite } = useFavoriteStore();
  const { token } = useAuthStore();
  const { refeshListAllFoldersFiles, currentPage, totalItems } = useRefreshListAllFolders();
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refreshListAllShareWithUser, shareWithCurrentPage, totalSharedWithItems } = useRefreshListAllSharedWithMe();
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();

  // Step 2: Return an async function to toggle the file's favorite status
  return async (fileId: string) => {
    if (!token) throw new Error('No token available');
    await toggleFileFavorite(fileId);
    await refeshListAllFoldersFiles(currentPage, totalItems);
    await refreshListFolderContents(folderCurrentPage);
    await refreshListAllShareWithUser(shareWithCurrentPage, totalSharedWithItems);
    await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
  };
};

// Custom hook for toggling folder as favorite
export const useToggleFolderFavorite = () => {
  // Step 1: Destructure the necessary action from the store
  const { toggleFolderFavorite } = useFavoriteStore();
  const { token } = useAuthStore();
  const { refeshListAllFoldersFiles, currentPage, totalItems } = useRefreshListAllFolders();
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refreshListAllShareWithUser, shareWithCurrentPage, totalSharedWithItems } = useRefreshListAllSharedWithMe();
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();

  // Step 2: Return an async function to toggle the folder's favorite status
  return async (folderId: string) => {
    if (!token) throw new Error('No token available');
    await toggleFolderFavorite(folderId);
    await refeshListAllFoldersFiles(currentPage, totalItems);
    await refreshListFolderContents(folderCurrentPage);
    await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
    await refreshListAllShareWithUser(shareWithCurrentPage, totalSharedWithItems);
  };
};