import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// Components
import FileDetailsModal from "../modals/FileDetailsModal";
import CustomAvatar from "../../utils/partials/CustomFileType";

// Icons
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import HeartIcon from "../../assets/icons/Hearticon";

// File Type Icons
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import { useToggleFileFavorite, useToggleFolderFavorite } from "src/hooks/useFavoriteHooks";
import { Pagination } from "antd";
import MainFileIcon from '../../assets/icons/MainFileIcon';
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import useListSharedWithFolderContents from "src/hooks/useListSharedWithFolderContents";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import { useDropzone } from "react-dropzone";
import { useFolderContentStore } from "../main_page/cards/FolderCard";
import { Item } from "src/types/api/ListFolderFiles";
import { Link } from "react-router-dom";
import FolderDetailsModal from "../modals/FolderDetailsModal";
import { useSharedWithFolderStore } from "../shared_files/cards/SharedWithMeFolderCard";
import useFolderStore from "src/store/api/useFolderStore";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  rar: <RarIcon height={20} width={30} />,
  arj: <ArjIcon height={20} width={30} />,
  folder: <MainFileIcon height={23} width={30} />
};

const INITIAL_VISIBLE_COLUMNS = ["Name", "file_type", "actions"];

const SharedWithViewerTableList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
    selectedKeys,
    setSelectedKeys,
    setSortDescriptor,
  } = useFilesStore();
  const [selectedFolder, setSelectedFolder] = useState<Item | null>(null);
  const [selectedFile, setSelectedFile] = useState<Item | null>(null);

  const {
    listSharedWithFolderContents,
    isFetchingFolderContent: isShareWithLoading,
    folderContents: sharedWithUserItems,
    folderCurrentPage: sharedWithPage,
    folderTotalPages: shareWithTotalPages,
    folderTotalItems: totalSharedWithItems,
    folderRowsPerPage: sharedWithRowsPerPage,
  } = useListSharedWithFolderContents();

  const {
    onDrop,
    handleUpload,
  } = useUploadFilesHooks();
  const folderItems = sharedWithUserItems || [];

  const [isDragging, setIsDragging] = useState(false);
  const { isOpen: isAddNewOpen, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();

  useDropzone({
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
      handleUpload(); // Automatically start uploading the files after dropping
    },
  });

  const {
    isOpen: isDeleteFolderOpen,
    onOpen: onOpenDeleteFolder,
    onClose: onCloseDeleteFolder,
  } = useDisclosure();

  const handleEdit = (item: any) => {
    setSelectedFolderId(item.folder_id);
    setSelectedFolderName(item.name);
    onOpenEditFolder();
  };

  const {
    isOpen: isShareFolderOpen,
    onOpen: onOpenShareFolder,
    onClose: onCloseShareFolder,
  } = useDisclosure();
 
  const toggleFavoriteFile = useToggleFileFavorite();
  const toggleFavoriteFolder = useToggleFolderFavorite();

  const {
    isOpen: isEditFolderOpen,
    onOpen: onOpenEditFolder,
    onClose: onCloseEditFolder,
  } = useDisclosure();

  const {
    isOpen: isDeleteFileOpen,
    onOpen: onOpenDeleteFile,
    onClose: onCloseDeleteFile,
  } = useDisclosure();
  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();
  const {
    isOpen: isShareFileOpen,
    onOpen: onOpenShareFile,
    onClose: onCloseShareFile,
  } = useDisclosure();

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const handleRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1); // Reset to the first page when rows per page change
    },
    [setRowsPerPage, setPage]
  );
  const handleFolderShare = () => onOpenShareFolder();

  useEffect(() => {
    setPage(1);
    listSharedWithFolderContents(1); // Refetch data for the first page
  }, [t, setPage, listSharedWithFolderContents]);


  const handleSearchChange = useCallback(
    (value?: string) => {
      setFilterValue(value || '');
    }, [setFilterValue]);


  const handleDelete = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      onOpenDeleteFolder();
    } else if (item.file_id) {
      setSelectedFileId(item.file_id);
      onOpenDeleteFile();
    }
  };

  const { setFolderId, setDirectoryId, setFolderPermissions } = useSharedWithFolderStore();
  const { setTheSelectedFolderId, } = useListSharedWithFolderContents();
  const { pushFolder } = useFolderStore();

  const handleFolderClick = (folder: any) => {
    setFolderId(folder.folder_id || "");
    setDirectoryId(folder.directory_id || "");
    setFolderPermissions("viewer");
    setTheSelectedFolderId(folder.folder_id || "");
    pushFolder(folder.folder_id || "", folder.name || "");
  };

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, "_blank");
    } else {
      // Handle cases where the file URL is not available
    }
  }, []);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedFolderName, setSelectedFolderName] = useState<string | null>(null);


  const handleShare = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      setSelectedFolderName(item.name);
      onOpenShareFolder();
    } else if (item.file_id) {
      setSelectedFileId(item.file_id);
      setSelectedFileName(item.original_filename);
      onOpenShareFile();
    }
  };


  const handleSave = useCallback((_names: string[]) => {
    // Implement the save functionality here
  }, []);

  const handleCopyLink = useCallback(() => {
    // Implement the copy link functionality here
  }, []);


  const renderTopContent = useMemo(
    () => (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper: "border-1",
            }}
            placeholder={t("SearchByFileName")}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={handleSearchChange}
          />

        </div>
        <div className="flex justify-between items-center ">
          <span className="text-default-400 text-small">
            {isShareWithLoading ? t('Loading') : `${t('TotalFiles')}: ${totalSharedWithItems}`}
          </span>
        </div>
      </div>
    ),
    [
      t,
      filterValue,
      handleSearchChange,
      totalSharedWithItems,
      rowsPerPage,
      handleRowsPerPageChange,
      setFilterValue,
    ]
  );

  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();


  const handleViewDetails = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      setSelectedFolder(item);
      setSelectedFolderName(item.name);
      onOpenFolderDetails();
    } else if (item.file_id) {
      setSelectedFile(item);
      setSelectedFileId(item.file_id);
      onOpenFileDetails();
    }
  };
  const handlePageChange = useCallback((newPage: number) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
    listSharedWithFolderContents(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, listSharedWithFolderContents]);


  const renderBottomContent = useMemo(
    () => (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={sharedWithPage}
          total={shareWithTotalPages * sharedWithRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={sharedWithRowsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    ),
    [sharedWithPage, shareWithTotalPages, sharedWithRowsPerPage, t, setPage, handlePageChange]
  );

  const renderCell = useCallback(
    (content: Item, columnKey: React.Key): React.ReactNode => {
      const cellValue = content[columnKey as keyof Item] || "N/A";

      switch (columnKey) {
        case "Name":
          const fileIcon = typesIconMap[content.file_type as keyof typeof typesIconMap] || typesIconMap[content.type as keyof typeof typesIconMap];
          return content.folder_id ? (
            <div className="flex items-start gap-2">
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => toggleFavoriteFolder(content.folder_id || '')}
              >
                <HeartIcon
                  className={content.is_favorite ? "[&>path]:stroke-transparent" : ""}
                  fill={content.is_favorite === 1 ? "red" : "none"}
                  width={20}
                  height={20}
                />
              </Button>
              {/* If the item is a folder, wrap with a Link component to navigate to folder details */}
              <Link to="/sharedWithFileList" className="flex items-center gap-2" onClick={() => handleFolderClick(content || "")}>
                {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}
                <span className="text-lg">{content.original_filename || content.name}</span>
              </Link>
            </div>
          ) : (
            // If the item is a file, just display the name and other info without navigation
            <div className="flex items-start gap-2">
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => toggleFavoriteFile(content.file_id || '')}
              >
                <HeartIcon
                  className={content.is_favorite ? "[&>path]:stroke-transparent" : ""}
                  fill={content.is_favorite === 1 ? "red" : "none"}
                  width={20}
                  height={20}
                />
              </Button>
              {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}

              <span className="text-lg"
                style={{
                  cursor: "pointer" // makes it clear the element is clickable
                }}
                onClick={() => handleDownload(content.s3_redirect_url || "")} // Trigger download on click
              >
                {content.original_filename && content.original_filename.length >= 60
                  ? `${content.original_filename.slice(0, 60)}.....`
                  : content.original_filename || content.name}
              </span>
            </div>
          );

        case "file_type":
          return (
            <Chip className="capitalize border-none gap-1 text-default-600" size="sm" variant="flat">
              {content.file_type || content.type}
            </Chip>
          );

        case "actions":
          return content.folder_id ? (
            <div className="relative flex justify-end items-center gap-2">
              <Dropdown className="bg-background border-1 border-default-200">
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  {/* <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}>
                  {t('View')}
                </DropdownItem> */}
                  <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) :
            (
              <div className="relative flex justify-end items-center gap-2">
                <Dropdown className="bg-background border-1 border-default-200">
                  <DropdownTrigger>
                    <Button isIconOnly radius="full" size="sm" variant="light">
                      <VerticalDotsIcon className="text-default-400" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                    <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}> {t('View')}</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            );

        default:
          return String(cellValue);
      }
    },
    [
      t,
      handleViewDetails,
      handleEdit,
      handleShare,
      handleDownload,
      handleDelete,
      toggleFavoriteFolder,
      toggleFavoriteFile,
      handleFolderClick,
    ]
  );


  return (
    <div className="w-full bg-white p-4 rounded-lg shadow overflow-x-auto">

      <Table
        isCompact
        className="min-w-full"
        removeWrapper
        aria-label="Example table with custom cells, pagination and sorting"
        bottomContent={renderBottomContent}
        bottomContentPlacement="outside"
        checkboxesProps={{
          classNames: {
            wrapper: "after:bg-secondary after:text-background text-background",
          },
        }}
        selectedKeys={Array.from(
          selectedKeys
        ) as Iterable<import("@react-types/shared").Key>}
        selectionMode="none"
        onSelectionChange={(keys) =>
          setSelectedKeys(new Set(Array.from(keys, String)))
        }
        topContent={renderTopContent}
        topContentPlacement="outside"
      >
        <TableHeader className="bg-white" columns={INITIAL_VISIBLE_COLUMNS}>
          {INITIAL_VISIBLE_COLUMNS.map((column) => (
            <TableColumn
              key={column}
              align={column === "actions" ? "end" : "start"}
            >
              {t(column)}
            </TableColumn>
          ))}
        </TableHeader>

        <TableBody emptyContent={t("NoFilesFound")} items={folderItems}>
          {folderItems.map((item) => (
            <TableRow key={item.file_id || item.folder_id}>
              {INITIAL_VISIBLE_COLUMNS.map((columnKey) => (
                <TableCell key={columnKey}>
                  {renderCell(item, columnKey)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

      </Table>

      {selectedFile && (
        <FileDetailsModal
          isOpen={isFileDetailsOpen}
          onClose={onCloseFileDetails}
          fileDetails={selectedFile}
        />
      )}

      {selectedFolder && (
        <FolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderDetails={selectedFolder} />
      )}

    </div>
  );
};

export default SharedWithViewerTableList;