import React, { useRef, useEffect, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import HomeIcon from "../../assets/icons/HomeIcon";
import SharedIcon from "../../assets/icons/SharedIcon";
import FavIcon from "../../assets/icons/FavIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { create } from "zustand";
import DnD from "../drag_and_drop/DND";
import YearDropdown from "./YearDropdown";
import HeaderLinks from "../header/HeaderLinks";
import { useAuthStore } from "src/store/api/useAuthStore";
import Logo from "../../assets/images/mysaff.jpeg";
import StorageProgress from "./StorageProgress";
import useFolderStore from "src/store/api/useFolderStore";

interface MainSidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SidebarState {
  sidebarOpen: boolean;
  sidebarExpanded: boolean;
  activeTab: string;
  toggleSidebar: () => void;
  setSidebarOpen: (open: boolean) => void;
  setActiveTab: (tab: string) => void;
}

export const useSidebarStore = create<SidebarState>((set) => ({
  sidebarOpen: false,
  sidebarExpanded: localStorage.getItem("sidebar-expanded") === "true",
  activeTab: "HomePage",
  toggleSidebar: () => set((state) => ({ sidebarOpen: !state.sidebarOpen })),
  setSidebarOpen: (open) => set({ sidebarOpen: open }),
  setActiveTab: (tab:string) => set({ activeTab: tab }),
}));

const MainSidebar: React.FC<MainSidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
}) => {
  const { t } = useTranslation();
  const trigger = useRef<HTMLButtonElement | null>(null);
  const sidebar = useRef<HTMLElement | null>(null);
  const isSaff = useAuthStore((state) => state.isSaff);
  const is_super_admin = useAuthStore((state) => state.permissions?.is_supper_admin);
  const is_administrators = useAuthStore((state) => state.permissions?.is_administrators);
  const { setActiveTab } = useSidebarStore();
  const {  emptyFolderStack } = useFolderStore();

  const clickHandler = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        sidebar.current &&
        trigger.current &&
        !sidebar.current.contains(target) &&
        !trigger.current.contains(target) &&
        sidebarOpen
      ) {
        setSidebarOpen(false);
      }
    },
    [sidebarOpen, setSidebarOpen]
  );

  const keyHandler = useCallback(
    (event: KeyboardEvent) => {
      if (sidebarOpen && event.key === "Escape") {
        setSidebarOpen(false);
      }
    },
    [sidebarOpen, setSidebarOpen]
  );

  const toMySAFF = () => {
    window.open("https://my.saff.sa/index", "_blank");
  };
 
  useEffect(() => {
    document.addEventListener("click", clickHandler);
    document.addEventListener("keydown", keyHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    };
  }, [clickHandler, keyHandler]);

  return (
    <aside
      ref={sidebar}
      className={`fixed top-0 z-50 flex md:h-full max-md:h-full max-lg:h-[90vh] lg:h-[90vh] max-sm:h-full w-72 max-sm:w-full 
      bg-white flex-col duration-300 ease-linear dark:bg-boxdark ${sidebarOpen ? "translate-x-0" : "-translate-x-full"} 
      ${sidebarOpen ? "right-0 left-auto" : "left-0 right-auto"
        } lg:static lg:translate-x-0 lg:z-auto`}
    >
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/">{/* Logo can be placed here */}</NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden mt-10"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col justify-between flex-1 overflow-y-auto">
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          <div>
            <ul className="mb-6 flex flex-col gap-1.5">
              <div className="mt-0 mb-6 ltr:ml-4 rtl:mr-4">
                {isSaff ? <DnD /> : ""}
                <div className="lg:hidden max-lg:hidden md:block max-md:block">
                  <div className="flex">
                    <HeaderLinks />
                  </div>
                </div>
              </div>

              {isSaff ? (
                <>
                  <li>
                    <div
                      onClick={toMySAFF}
                      className={`group cursor-pointer bg-success relative flex items-center gap-2.5 px-4 py-2 font-medium text-white duration-300 ease-in-out rounded-xl dark:hover:bg-meta-4`}
                    >
                      <div className="flex justify-center items-start gap-2">
                        <div onClick={toMySAFF} className="">
                          <img
                            className="w-auto h-5 max-sm:h-6 rounded-lg"
                            src={Logo}
                            alt="Logo"
                          />
                        </div>
                        <div>{t("BackToMySAFF")}</div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <NavLink
                      to="/homepage"
                      onClick={() => {
                        setActiveTab("HomePage");
                        emptyFolderStack();
                      }}
                       style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <HomeIcon
                            height="20px"
                            width="20px"
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("HomePage")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Shared" 
                       onClick={() => {
                        setActiveTab("SharedWithMe");
                        emptyFolderStack();
                      }}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <SharedIcon
                            width="20px"
                            height="20px"
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("SharedFiles")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/Favorite"
                       onClick={() => {
                        setActiveTab("Favorite");
                        emptyFolderStack();
                      }}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <FavIcon
                            size={20}
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("Favorite")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/Recovery"
                       onClick={() => {
                        setActiveTab("Recovery");
                        emptyFolderStack();
                      }}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <TrashIcon
                            size={20}
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("Recovery")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>
                  {(is_super_admin || is_administrators) && (
                    <li>
                      <NavLink
                        to="/Settings"
                         onClick={() => {
                          setActiveTab("Settings");
                          emptyFolderStack();
                        }}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? "#F5F8FE" : "",
                          borderRadius: isActive ? "10px" : "",
                          fontWeight: isActive ? "bold" : "",
                          color: isActive ? "#034121" : "",
                        })}
                        className={({ isActive }) =>
                          `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <div className="flex justify-center items-start gap-2">
                            <SettingsIcon
                              width={20}
                              height={20}
                              color={isActive ? "#034121" : "#808080"}
                            />
                            <div>{t("Settings")}</div>
                          </div>
                        )}
                      </NavLink>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li>
                    <NavLink
                      to="/Shared"
                      onClick={() => {
                        setActiveTab("SharedWithMe");
                        emptyFolderStack();
                      }}
                       style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <SharedIcon
                            width="20px"
                            height="20px"
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("SharedFiles")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/Favorite"
                      onClick={() => {
                        setActiveTab("Favorite");
                        emptyFolderStack();
                      }}
                       style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <FavIcon
                            size={20}
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("Favorite")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Recovery"
                      onClick={() => {
                        setActiveTab("Recovery");
                        emptyFolderStack();
                      }}
                       style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <TrashIcon
                            size={20}
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("Recovery")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/Notifications"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#F5F8FE" : "",
                        borderRadius: isActive ? "10px" : "",
                        fontWeight: isActive ? "bold" : "",
                        color: isActive ? "#034121" : "",
                      })}
                      className={({ isActive }) =>
                        `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out hover:bg-[#ECF6F2] hover:rounded-xl dark:hover:bg-meta-4 ${
                          isActive ? "bg-graydark dark:bg-meta-4" : ""
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex justify-center items-start gap-2">
                          <SettingsIcon
                            width={20}
                            height={20}
                            color={isActive ? "#034121" : "#808080"}
                          />
                          <div>{t("Notifications")}</div>
                        </div>
                      )}
                    </NavLink>
                  </li> */}
                </>
              )}
            </ul>
            
          </div>
        </nav>
        <StorageProgress />
        {isSaff ? <YearDropdown /> : ""}
  
      </div>
    </aside>
  );
};

export default MainSidebar;
