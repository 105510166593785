// src/pages/Files.js
import { useEffect, useState } from "react";
import PublicSidebar, { usePublicSidebarStore } from "../components/public_sidebar/PublicSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import LayoutSwitcher from "../components/switchers/LayoutSwitcher";
import NavBar from "../components/public_header/NarBar";
import { useSidebarStore } from "src/store/layout/useSidebarStore";
import PublicSharedGridList from "src/components/public_shared_files_layout/PublicSharedGridList";
import PublicSharedTableList from "src/components/public_shared_files_layout/PublicSharedTableList";
import { usePublicFolderStore } from "src/components/public_access_page/cards/PublicFolderCard";
import { useNavigate } from 'react-router-dom';
import useFolderStore from "src/store/api/useFolderStore";
import useListPublicFolderContents from "src/hooks/useListPublicFolderContents";


const PublicSharedFiles = () => {
  const { t } = useTranslation();
  const { layout } = useSidebarStore();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const { shareId } = usePublicSidebarStore(); // Get ID from the store
  const { selectedFolderStack, popFolderByIndex, emptyFolderStack } = useFolderStore();
  const { setFolderId, clearFolderId } = usePublicFolderStore(); // Zustand store to manage selected folder id
  const { setTheSelectedPublicFolderId, clearTheSelectedPublicFolderId } = useListPublicFolderContents();

  console.log("handleFolderClick PublicSharedFiles selectedFolderStack: ",selectedFolderStack);

  // Retrieve the last folder name if the stack is not empty
  const folderName =
    selectedFolderStack && selectedFolderStack.length > 0
      ? selectedFolderStack[selectedFolderStack.length - 1].folder_name
      : "";

  const handleBreadcrumbClick = (folder_id: string, index: number) => {
    setFolderId(folder_id); // Set the folderId state
    setTheSelectedPublicFolderId(folder_id);
    popFolderByIndex(index);
    navigate("/publicSharedFileList");
  };

  const handleMainBreadcrumbClick = () => {
    clearFolderId(); // Set the folderId state
    clearTheSelectedPublicFolderId();
    emptyFolderStack();
    navigate(`/PublicAccessPage?id=${shareId}`, { replace: true });
  };

  useEffect(() => {
    const folderId = usePublicFolderStore.getState().folderId;
    const subfolderId = useFolderStore.getState().selectedPublicFolderId;

    if (folderId === null && subfolderId === null) {
      navigate(`/PublicAccessPage?id=${shareId}`, { replace: true });
    }

  }, [navigate]);

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <PublicSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="flex-1 p-4 overflow-x-hidden">
          {/* <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Files")}</BreadcrumbItem>
          </Breadcrumbs> */}

          <Breadcrumbs>
            <BreadcrumbItem>
              <button onClick={() => handleMainBreadcrumbClick()} >
                {t("HomePage")}
              </button>
            </BreadcrumbItem>
            {selectedFolderStack?.map((folder, index) => (
              <BreadcrumbItem key={index}>
                <button onClick={() => handleBreadcrumbClick(folder.folder_id, index)}>
                  {folder.folder_name}
                </button>
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5 flex justify-between">
            <div className="font-bold capitalize hover:text-navy-700">
              {folderName || t("Files")}
            </div>
            <LayoutSwitcher />
          </div>

          {layout === "grid" ? <PublicSharedGridList /> : <div className="">
            <PublicSharedTableList />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default PublicSharedFiles;
