import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  useDisclosure,
} from "@nextui-org/react";
import AddNewFolderModal from "src/components/modals/AddNewFolderModal";
import { PlusIcon } from '../../assets/icons/PlusIcon';
import { useTranslation } from "react-i18next";
import ShareFileModal from "../modals/ShareFileModal";
import DeleteFileModal from "../modals/DeleteFileModal";
import FileDetailsModal from "../modals/FileDetailsModal";
import CustomAvatar from "../../utils/partials/CustomFileType";
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { ChevronDownIcon } from "../../assets/icons/ChevronDownIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import HeartIcon from "../../assets/icons/Hearticon";
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
// import { File } from "src/store/layout/useFilesStore";
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import { useToggleFileFavorite, useToggleFolderFavorite } from "src/hooks/useFavoriteHooks";
import { Capitalize } from "src/utils/Capitalize";
import { useDropzone } from "react-dropzone";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";
import DnD from "../drag_and_drop/DND";
import { Alert, Pagination } from "antd";
import useListFolderContents from "src/hooks/useListFolderContents";
import useTrashFilesStore from "src/store/layout/useTrashFilesGridListStoreLayout";
import { Item } from "src/types/api/ListFolderFiles";
import MainFileIcon from '../../assets/icons/MainFileIcon';
import { useFolderContentStore } from "../main_page/cards/FolderCard";
import { Link } from "react-router-dom";
import FolderDetailsModal from "../modals/FolderDetailsModal";
import EditFolderModal from 'src/components/modals/EditFolderModal';
import DeleteFolderModal from "../modals/DeleteFolderModal";
import ShareFolderModal from "../modals/ShareFolderModal";
import useListSharedByFolderContents from 'src/hooks/useListSharedByFolderContents';
import SharedByDND from "../drag_and_drop/SharedByDND";
import useFolderStore from "src/store/api/useFolderStore";
import { useSharedByFolderStore } from "../shared_files/cards/SharedByMeFolderCard";
import { useUserResources } from "src/hooks/useUserResourcesHooks";
import { useFolderHooks } from "src/hooks/useFolderHooks";
import CustomAlert, { useAlertStore } from "../common/CustomAlert";
import EditFileModal from "../modals/EditFileModal";

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  rar: <RarIcon height={20} width={30} />,
  arj: <ArjIcon height={20} width={30} />,
  folder: <MainFileIcon height={23} width={30} />
};

const INITIAL_VISIBLE_COLUMNS = ["Name", "file_type", "actions"];

const SharedByTableList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
    selectedKeys,
    setSelectedKeys,
    setSortDescriptor,
  } = useFilesStore();

  const {
    listSharedByFolderContents,
    isFetchingFolderContent: isShareByLoading,
    folderContents: sharedByUserItems,
    folderCurrentPage: sharedByPage,
    folderTotalPages: shareByTotalPages,
    folderTotalItems: totalSharedByItems,
    folderRowsPerPage: sharedByRowsPerPage,
    setTheSelectedFolderId
  } = useListSharedByFolderContents();

  const { onDrop, onDropContent, handleSharedByUpload } = useUploadFilesHooks();

  const folderItems = sharedByUserItems || [];

  const [isDragging, setIsDragging] = useState(false);
  const { isOpen: isAddNewOpen, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();
  const { setFolderName, handleUploadNewFolder } = useFolderHooks();
  const [errorNoFreeSpaceMessage, setErrorNoFreeSpaceMessage] = useState<string>("");
  // Calculate storage when userResources is available
  const { userResources, freeSpace } = useUserResources();
  const { setFolderId, folderId: parentFolderId } = useSharedByFolderStore(); // Zustand store to manage selected folder id
  const { isOpen: isEditFileOpen, onOpen: onOpenEditFile, onClose: onCloseEditFile } = useDisclosure();

  const {
    isOpen: isDeleteFolderOpen,
    onOpen: onOpenDeleteFolder,
    onClose: onCloseDeleteFolder,
  } = useDisclosure();

  const handleEdit = (item: any) => {
    if (item.folder_id) {
    setSelectedFolderId(item.folder_id);
    setSelectedFolderName(item.name);
    onOpenEditFolder();
    }
    else{
      setSelectedFile(item);
      onOpenEditFile();
    }
  };

  const {
    isOpen: isShareFolderOpen,
    onOpen: onOpenShareFolder,
    onClose: onCloseShareFolder,
  } = useDisclosure();

  let folderList: {
    name: string;
    id: string | null;
    path: string;
    level: number;
  }[] = [];


  const getParentFolderId = (currentPath: string, currentLevel: number): string | null => {
    // Find the parent folder path by removing the last segment from the current path
    const parentPath = currentPath.split('/').slice(0, -1).join('/');

    // Find the parent folder in the folderList with the parentPath and level one less than currentLevel
    const parentFolder = folderList.find(
      folder => folder.path === parentPath && folder.level === currentLevel - 1
    );

    // Return the parentFolderId if found, otherwise null
    return parentFolder ? parentFolder.id : null;
  };


  // useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     onDrop(acceptedFiles);
  //     handleSharedByUpload(); // Automatically start uploading the files after dropping
  //   },
  // });


  const errorNoFreeSpaceMessageDisplay = () => {

    const message = `${t('CannotUploadMoreFile')} ${freeSpace} ${t('MB')} ${t('Remaining')}`;
    setErrorNoFreeSpaceMessage(message);
    useAlertStore.getState().showAlert(message, "error");

  };
  

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);

      const items = Array.from(event.dataTransfer.items);
      // let totalSize = 0;
      const uploadedFiles = new Map<string | null, File[]>(); // Map to store folderId and associated files
      folderList = []; // Reassign to empty array

      if (freeSpace && freeSpace > 0) {
        for (const item of items) {
          const entry = item.webkitGetAsEntry();
          if (entry) {

            if (entry.isDirectory) {

              // Process the folder to calculate size and collect files
              // const files = await sumTotalSize(entry);
              // files.forEach((file) => {
              //   totalSize += file.size;
              // });

              // Check if there is enough free space
              // if (freeSpace && freeSpace < totalSize) {
              //   errorNoFreeSpaceMessageDisplay();
              //   break;
              // }
              // else {
              // Assuming handleCreateFolder returns the new folder ID
              setFolderName(entry.name);
              const folderId = await handleUploadNewFolder(parentFolderId);
              if (folderId) {
                setFolderId(folderId); // for files
                folderList.push({
                  name: entry.name,
                  id: folderId,
                  path: entry.fullPath,
                  level: 0,
                });

                // setTheSelectedFolderId(folderId); //for folders
                // Add the files from the processed folder to the uploadedFiles map
                const filesMap = await processFolder(entry, folderId, 0, entry.fullPath); // Start at level 0 with root path
                filesMap.forEach((files, folderId) => {
                  if (uploadedFiles.has(folderId)) {
                    uploadedFiles.get(folderId)?.push(...files);
                  } else {
                    uploadedFiles.set(folderId, files);
                  }
                });
              }
              // }

            } else if (item.kind === 'file') {
              const file = item.getAsFile();
              if (file) {
                // totalSize += file.size;
                // if (freeSpace && freeSpace < totalSize) {
                //   errorNoFreeSpaceMessageDisplay();
                //   break;
                // }
                // else
                // Use default folder ID for single file
                if (uploadedFiles.has(null)) {
                  uploadedFiles.get(null)?.push(file);
                } else {
                  uploadedFiles.set(null, [file]);
                }
              }
            }
          }
        }//end loop

        if (uploadedFiles.size > 0) {
          // Log uploaded files to the console

          // if (freeSpace && freeSpace >= totalSize) {
          // Flatten the uploaded files and pass them to the onDrop function

          uploadedFiles.forEach((files, folderId) => onDropContent(files, folderId));
          handleSharedByUpload();
          // } else {
          //   errorNoFreeSpaceMessageDisplay();
          // }
        }
      }
      else
        errorNoFreeSpaceMessageDisplay();
    },
    [onDropContent, handleSharedByUpload, handleUploadNewFolder, setFolderId, setFolderName, setTheSelectedFolderId, freeSpace, errorNoFreeSpaceMessageDisplay]
  );

   // const sumTotalSize = useCallback(
  //   async (entry: any): Promise<File[]> => {
  //     const files: File[] = [];

  //     if (entry.isDirectory) {
  //       const reader = entry.createReader();

  //       const readEntries = (): Promise<any[]> => {
  //         return new Promise((resolve, reject) => {
  //           reader.readEntries(
  //             (entries: any[]) => {
  //               resolve(entries);
  //             },
  //             (error: any) => {
  //               console.error('Error reading entries:', error);
  //               reject(error);
  //             }
  //           );
  //         });
  //       };

  //       let entries = await readEntries();
  //       while (entries.length > 0) {
  //         for (const childEntry of entries) {
  //           if (childEntry.isDirectory) {
  //             const subFiles = await sumTotalSize(childEntry);
  //             files.push(...subFiles);
  //           } else {
  //             const file: File = await new Promise((resolve, reject) => {
  //               childEntry.file((file: File) => {
  //                 if (file) {
  //                   resolve(file);
  //                 } else {
  //                   reject(new Error('Failed to retrieve file.'));
  //                 }
  //               });
  //             });
  //             files.push(file);
  //           }
  //         }
  //         entries = await readEntries();
  //       }
  //     }

  //     const filteredFiles = files.filter((file) => {
  //       const fileType = file.type;
  //       const acceptedExtensions = validFileTypes[fileType];
  //       if (!acceptedExtensions) {
  //         console.warn(`File type not accepted: ${fileType}`); // Log warning for unsupported types
  //         return false;
  //       }
  //        return true;
  //     });

  //     return filteredFiles;
  //   },
  //   []
  // );

  const processFolder = useCallback(
    async (entry: any, parentFolderId: string | null, level: number, parentPath: string): Promise<Map<string | null, File[]>> => {
      const filesMap = new Map<string | null, File[]>(); // Map to store folderId and associated files

      if (entry.isDirectory) {
        const reader = entry.createReader();

        const readEntries = (): Promise<any[]> => {
          return new Promise((resolve, reject) => {
            reader.readEntries(
              (entries: any[]) => {
                resolve(entries);
              },
              (error: any) => {
                console.error('Error reading entries:', error);
                reject(error);
              }
            );
          });
        };

        let entries = await readEntries();

        while (entries.length > 0) {
          for (const childEntry of entries) {
            // console.log(childEntry);
            // console.log("fullPath", entry.fullPath);

            if (childEntry.isDirectory) {
              const currentParentFolderId = getParentFolderId(childEntry.fullPath, level + 1); // Level 0 for the root
              setFolderName(childEntry.name);
              // Assuming handleCreateFolder can take a parentFolderId to create nested folders
              const subFolderId = await handleUploadNewFolder(currentParentFolderId);
              if (subFolderId) {
                setFolderId(subFolderId);
                folderList.push({
                  name: childEntry.name,
                  id: subFolderId,
                  path: childEntry.fullPath,
                  level: level + 1,
                });

                // setTheSelectedFolderId(subFolderId);
                const subFilesMap = await processFolder(childEntry, subFolderId, level + 1, childEntry.fullPath);
                // Add subfolder files to the main map
                subFilesMap.forEach((subFiles, folderId) => {
                  filesMap.set(folderId, subFiles);
                });
              }
            } else {
              const file: File = await new Promise((resolve, reject) => {
                childEntry.file((file: File) => {
                  if (file) {
                    resolve(file);
                  } else {
                    reject(new Error('Failed to retrieve file.'));
                  }
                });
              });
              setFolderId(parentFolderId || "");
              // setTheSelectedFolderId(parentFolderId);

              // Add the file to the map with the parent folder ID
              if (filesMap.has(parentFolderId)) {
                filesMap.get(parentFolderId)?.push(file);
              } else {
                filesMap.set(parentFolderId, [file]);
              }
            }
          }
          entries = await readEntries();
        }
      }

      return filesMap;
    },
    [handleUploadNewFolder, setFolderId, setTheSelectedFolderId]
  );

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (userResources?.can_upload ?? false)
      setIsDragging(true);
  }, [userResources]);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const toggleFavoriteFile = useToggleFileFavorite();
  const toggleFavoriteFolder = useToggleFolderFavorite();

  const {
    isOpen: isEditFolderOpen,
    onOpen: onOpenEditFolder,
    onClose: onCloseEditFolder,
  } = useDisclosure();

  const {
    isOpen: isDeleteFileOpen,
    onOpen: onOpenDeleteFile,
    onClose: onCloseDeleteFile,
  } = useDisclosure();
  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();
  const {
    isOpen: isShareFileOpen,
    onOpen: onOpenShareFile,
    onClose: onCloseShareFile,
  } = useDisclosure();

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const handleRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1); // Reset to the first page when rows per page change
    },
    [setRowsPerPage, setPage]
  );
  const handleFolderShare = () => onOpenShareFolder();

  useEffect(() => {
    setPage(1);
    listSharedByFolderContents(1); // Refetch data for the first page
  }, [t, setPage, listSharedByFolderContents]);


  const handleSearchChange = useCallback(
    (value?: string) => {
      setFilterValue(value || '');
    }, [setFilterValue]);


  const handleDelete = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      onOpenDeleteFolder();
    } else if (item.file_id) {
      setSelectedFileId(item.file_id);
      onOpenDeleteFile();
    }
  };

  const { pushFolder } = useFolderStore();

  const handleFolderClick = (folder: any) => {
    setFolderId(folder.folder_id); // Set the folderId state
    setTheSelectedFolderId(folder.folder_id);
    pushFolder(folder.id || "", folder.name || "");
  };

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, "_blank");
    } else {
      // Handle cases where the file URL is not available
    }
  }, []);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedFolderName, setSelectedFolderName] = useState<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<Item | null>(null);
  const [selectedFile, setSelectedFile] = useState<Item | null>(null);


  const handleShare = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      setSelectedFolderName(item.name);
      onOpenShareFolder();
    } else if (item.file_id) {
      setSelectedFileId(item.file_id);
      setSelectedFileName(item.original_filename);
      onOpenShareFile();
    }
  };


  const handleSave = useCallback((_names: string[]) => {
    // Implement the save functionality here
  }, []);

  const handleCopyLink = useCallback(() => {
    // Implement the copy link functionality here
  }, []);


  const renderTopContent = useMemo(
    () => (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper: "border-1",
            }}
            placeholder={t("SearchByFileName")}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={handleSearchChange}
          />
          {/* <div className="flex gap-3">
            <SharedByDND />
          </div> */}
          <div className="flex gap-3">
            <Button
              className="bg-success text-background"
              endContent={<PlusIcon />}
              size="sm"
              onClick={onOpenAddNew}
            >
              {t('AddNewSub')}
            </Button>
          </div>
        </div>

        {/* {errorNoFreeSpaceMessage && (
          <CustomAlert />
        )} */}


        <div className="flex justify-between items-center ">
          <span className="text-default-400 text-small">
            {isShareByLoading ? t('Loading') : `${t('TotalFiles')}: ${totalSharedByItems}`}
          </span>
        </div>
      </div>
    ),
    [
      t,
      filterValue,
      handleSearchChange,
      totalSharedByItems,
      rowsPerPage,
      handleRowsPerPageChange,
      setFilterValue,
      errorNoFreeSpaceMessage
    ]
  );

  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();


  const handleViewDetails = (item: any) => {
    if (item.folder_id) {
      setSelectedFolderId(item.folder_id);
      setSelectedFolder(item);
      setSelectedFolderName(item.name);
      onOpenFolderDetails();
    } else if (item.file_id) {
      setSelectedFile(item);
      setSelectedFileId(item.file_id);
      onOpenFileDetails();
    }
  };
  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
    listSharedByFolderContents(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, listSharedByFolderContents]);


  const renderBottomContent = useMemo(
    () => (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
          current={sharedByPage}
          total={shareByTotalPages * sharedByRowsPerPage} // Ant Design uses total items instead of total pages
          pageSize={sharedByRowsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    ),
    [sharedByPage, shareByTotalPages, sharedByRowsPerPage, t, setPage, handlePageChange]
  );

  const renderCell = useCallback(
    (content: Item, columnKey: React.Key): React.ReactNode => {
      const cellValue = content[columnKey as keyof Item] || "N/A";

      switch (columnKey) {
        case "Name":
          const fileIcon = typesIconMap[content.file_type as keyof typeof typesIconMap] || typesIconMap[content.type as keyof typeof typesIconMap];
          return content.folder_id ? (
            <div className="flex items-start gap-2">
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => toggleFavoriteFolder(content.folder_id || '')}
              >
                <HeartIcon
                  className={content.is_favorite ? "[&>path]:stroke-transparent" : ""}
                  fill={content.is_favorite === 1 ? "red" : "none"}
                  width={20}
                  height={20}
                />
              </Button>
              {/* If the item is a folder, wrap with a Link component to navigate to folder details */}
              <Link to="/sharedByFileList" className="flex items-center gap-2" onClick={() => handleFolderClick(content || "")}>
                {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}
                <span className="text-lg">{content.original_filename || content.name}</span>
              </Link>
            </div>
          ) : (
            // If the item is a file, just display the name and other info without navigation
            <div className="flex items-start gap-2">
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => toggleFavoriteFile(content.file_id || '')}
              >
                <HeartIcon
                  className={content.is_favorite ? "[&>path]:stroke-transparent" : ""}
                  fill={content.is_favorite === 1 ? "red" : "none"}
                  width={20}
                  height={20}
                />
              </Button>
              {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}

              <span className="text-lg"
                style={{
                  cursor: "pointer" // makes it clear the element is clickable
                }}
                onClick={() => handleDownload(content.s3_redirect_url || "")} // Trigger download on click
              >
                {content.original_filename && content.original_filename.length >= 60
                  ? `${content.original_filename.slice(0, 60)}.....`
                  : content.original_filename || content.name}
              </span>
            </div>
          );

        case "file_type":
          return (
            <Chip className="capitalize border-none gap-1 text-default-600" size="sm" variant="flat">
              {content.file_type || content.type}
            </Chip>
          );

        case "actions":
          return content.folder_id ? (
            <div className="relative flex justify-end items-center gap-2">
              <Dropdown className="bg-background border-1 border-default-200">
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  {/* <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}>
                  {t('View')}
                </DropdownItem> */}
                  <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                  <DropdownItem onClick={() => handleEdit(content)}>{t('EditFolder')}</DropdownItem>
                  <DropdownItem onClick={() => handleShare(content)}>{t("Share")}</DropdownItem>
                  <DropdownItem color="danger" onClick={() => handleDelete(content)}>{t("Delete")}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) :
            (
              <div className="relative flex justify-end items-center gap-2">
                <Dropdown className="bg-background border-1 border-default-200">
                  <DropdownTrigger>
                    <Button isIconOnly radius="full" size="sm" variant="light">
                      <VerticalDotsIcon className="text-default-400" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleViewDetails(content)}>{t('ViewDetails')}</DropdownItem>
                    <DropdownItem onClick={() => handleDownload(content.s3_redirect_url || '')}> {t('View')}</DropdownItem>
                    <DropdownItem onClick={() => handleEdit(content)}>{t('EditFile')}</DropdownItem>
                    <DropdownItem onClick={() => handleShare(content)}>{t("Share")}</DropdownItem>
                    <DropdownItem color="danger" onClick={() => handleDelete(content)}>{t("Delete")}</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            );

        default:
          return String(cellValue);
      }
    },
    [
      t,
      handleViewDetails,
      handleEdit,
      handleShare,
      handleDownload,
      handleDelete,
      toggleFavoriteFolder,
      toggleFavoriteFile,
      handleFolderClick,
    ]
  );


  return (
    <div
      className={` ${isDragging
        ? "border-2 border-dashed border-success rounded-lg"
        : ""
        }`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div
        className={`w-full bg-white p-4 rounded-lg shadow overflow-x-auto `}
      >
        <Table
          isCompact
          className="min-w-full"
          removeWrapper
          aria-label="Example table with custom cells, pagination and sorting"
          bottomContent={renderBottomContent}
          bottomContentPlacement="outside"
          checkboxesProps={{
            classNames: {
              wrapper: "after:bg-secondary after:text-background text-background",
            },
          }}
          selectedKeys={Array.from(
            selectedKeys
          ) as Iterable<import("@react-types/shared").Key>}
          selectionMode="none"
          onSelectionChange={(keys) =>
            setSelectedKeys(new Set(Array.from(keys, String)))
          }
          topContent={renderTopContent}
          topContentPlacement="outside"
        >
          <TableHeader className="bg-white" columns={INITIAL_VISIBLE_COLUMNS}>
            {INITIAL_VISIBLE_COLUMNS.map((column) => (
              <TableColumn
                key={column}
                align={column === "actions" ? "end" : "start"}
              >
                {t(column)}
              </TableColumn>
            ))}
          </TableHeader>

          <TableBody emptyContent={t("NoFilesFound")} items={folderItems}>
            {folderItems.map((item) => (
              <TableRow key={item.file_id || item.folder_id}>
                {INITIAL_VISIBLE_COLUMNS.map((columnKey) => (
                  <TableCell key={columnKey}>
                    {renderCell(item, columnKey)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>

        </Table>

        {selectedFileId && (
          <DeleteFileModal
            isOpen={isDeleteFileOpen}
            onClose={onCloseDeleteFile}
            fileId={selectedFileId}
            fileType="myFile"
          />
        )}

        {selectedFile && (
          <FileDetailsModal
            isOpen={isFileDetailsOpen}
            onClose={onCloseFileDetails}
            fileDetails={selectedFile}
          />
        )}

      </div>

      {selectedFile && (<EditFileModal
        isOpen={isEditFileOpen}
        onClose={onCloseEditFile}
        fileDetails={selectedFile}
        fileId={selectedFile.file_id || ""}
        type = "sharedBy"
      />
      )}

      {selectedFile && (<FileDetailsModal
        isOpen={isFileDetailsOpen}
        onClose={onCloseFileDetails}
        fileDetails={selectedFile}
      />
      )}
      {selectedFileId && (<ShareFileModal
        onSave={handleSave}
        onCopyLink={handleCopyLink}
        isOpen={isShareFileOpen}
        onClose={onCloseShareFile}
        resourceId={selectedFileId || ''}
        resourceName={selectedFileName || ''}
        resourceType="file"
      />
      )}
      <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />


      {selectedFolder && (
        <FolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderDetails={selectedFolder} />
      )}

      {selectedFolderId && selectedFolderName && (<EditFolderModal folderId={selectedFolderId || ''} folderName={selectedFolderName || ''} isOpen={isEditFolderOpen} onClose={onCloseEditFolder} />)}
      {selectedFolderId && (<DeleteFolderModal isOpen={isDeleteFolderOpen} onClose={onCloseDeleteFolder} folderId={selectedFolderId || ''} folderType="myFolder" />)}
      {selectedFolderId && selectedFolderName && (<ShareFolderModal onSave={handleSave} onCopyLink={handleCopyLink} isOpen={isShareFolderOpen} onClose={onCloseShareFolder} resourceId={selectedFolderId || ''} resourceName={selectedFolderName || ''} resourceType="folder" />)}

    </div>
  );
};
export default SharedByTableList;
